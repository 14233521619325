import React from 'react';
// import { useDashboardStore } from '../../../../../store/dashboard.state';
// import { graphTypes } from '../../../../../utils/dashboard';
import BarChartMerge from './Merge';
// import BarChartCompare from './Compare';

const DashboardBarChartWeekly = () => {
  // const { graphType }: any = useDashboardStore();

  return <BarChartMerge />;
  // return graphType === graphTypes[0] ? <BarChartMerge /> : <BarChartCompare />;
};

export default DashboardBarChartWeekly;
