import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { useDashboardStore } from '../../../../../store/dashboard.state';
import DashboardStackedBarChart from '../../StackedBarChart';

const BarChartMerge = () => {
  const { t } = useTranslation();
  const { mergeSales }: any = useDashboardStore();

  const dataSets = mergeSales[0]?.totalMonth
    ? mergeSales.map((sale: any) => {
        if (Array.isArray(sale?.totalMonth)) {
          return sale.totalMonth;
        }
      })
    : [];

  const data = {
    labels: mergeSales.length > 0 ? mergeSales[0].totalMonthLabel : [t('CALENDAR.LOADING')],
    data: dataSets,
  };

  const chartConfig = {
    width:
      data.labels?.length >= 10
        ? Dimensions.get('window').width * (data.labels?.length / 10)
        : Dimensions.get('window').width / 1.15,
    spacingInner: 0.9,
    xContentInset: 27,
    rContentInset: 12.5,
    marginLeft: -10,
    marginRight: 4,
    linePos: 11.5,
  };

  return <DashboardStackedBarChart data={data} chartConfig={chartConfig} />;
};

export default memo(BarChartMerge);
