import React from 'react';
import ListItemContainer from '../../ui/ListItemContainer';
import ListItem from '../../ui/ListItem';
import ListItemDivider from '../../ui/ListItemDivider';

type CompanyAddressProps = {
    values: any;
}

const CompanyDetails = ({ values }: CompanyAddressProps) => {
    return(
        <ListItemContainer>
            <ListItem label={'AUTH.EMAIL'} value={values.email} />
            <ListItemDivider />
            <ListItem label={'AUTH.PHONE'} value={values.phone} />
        </ListItemContainer>
    );
}

export default CompanyDetails;