import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

type State = {
  allOrders: any;
};

const initialState: State = {
  allOrders: [],
};

export const useStatsStore = create(
  persist(
    (set) => ({
      ...initialState,
      setAllOrders: (data: any) => set({ allOrders: data }),

      resetStats: () => {
        set(initialState);
      },
    }),
    {
      name: 'stathubs-stats-storage', // Storage Mobile App Name
      getStorage: () => AsyncStorage,
    }
  )
);
