import React, { memo, useState } from 'react';
import { StyleSheet, Text, View, Dimensions } from 'react-native';
import Colors from '../../../constants/Colors';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createSpaceValidation } from '../../../components/form/Validations';
import { TextInput } from '../../../components/form/TextInput';
import { useNavigation } from '@react-navigation/native';
import api from '../../../config/api';
import { useSpaceStore } from '../../../store/spaces.state';
import { useTranslation } from 'react-i18next';
import LabelPickerSelect from '../../../components/form/LabelPickerSelect';
import TextInputValidation from '../../../components/form/TextInputValidation';
import Button from '../../../components/ui/action/Button';
import Toast from 'react-native-toast-message';

type FormValues = {
  space_name: string;
};

const CreateSpaceScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { spacePerUser, addSpaces, setDefaultSpace }: any = useSpaceStore();

  const { ...methods } = useForm({ resolver: yupResolver(createSpaceValidation), mode: 'all' });
  const [values, setValues] = useState({
    loading: false,
    hasError: '',
  });

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    const { space_name } = data;
    if (!space_name) return;

    setValues({ ...values, loading: true, hasError: '' });

    try {
      const response = await api('/space/create', 'POST', { name: space_name });

      if (response?.response) {
        spacePerUser.push(response?.response);
        addSpaces(spacePerUser);

        // SET default space
        setDefaultSpace({
          id: response?.response?.id || '',
          name: response?.response?.name || '',
          code: response?.response?.code || '',
        });
      }

      setValues({ ...values, loading: false, hasError: '' });

      if (response?.success) {
        navigation.navigate('Dashboard');
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'The space name is existed',
        visibilityTime: 5000,
      });
      setValues({ ...values, loading: false, hasError: error?.errors ?? 'Soemthing went wrong!' });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <View>
          <LabelPickerSelect required>{t('AUTH.SPACE_NAME')}</LabelPickerSelect>

          <TextInput
            name="space_name"
            placeholder={t('AUTH.ENTER_YOUR_SPACE_NAME')}
            autoCapitalize="none"
            placeholderTextColor={Colors.light.placeholder}
            style={styles.inputStyle}
          />

          {methods?.formState?.errors?.space_name && (
            <TextInputValidation>{t(methods?.formState?.errors?.space_name?.message)}</TextInputValidation>
          )}
        </View>
      </FormProvider>

      <View style={styles.bottom}>
        <Button
          loading={values.loading}
          disabled={values.loading}
          onPress={methods.handleSubmit(onSubmit)}
          label={'AUTH.CREATE_SPACE'}
          bg={'dark'}
        />
      </View>
    </>
  );
};
export default memo(CreateSpaceScreen);

const styles = StyleSheet.create({
  keyboardAvoidStyleContainer: {
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.background,
  },
  main: {
    width: '100%',
  },
  content: {
    paddingTop: 32,
    paddingBottom: 30,
  },
  title: {
    fontSize: 20,
    width: Dimensions.get('window').width / 1.4,
    fontWeight: '600',
  },
  subTitle: {
    fontSize: 14,
    paddingTop: 12,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  bottomFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 24,
  },
  bottomFooterText: {
    fontSize: 14,
  },
  labelBottom: {
    paddingHorizontal: 3,
    fontSize: 14,
    fontWeight: '600',
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingTop: 6,
  },
});
