import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Modalize } from 'react-native-modalize';
import { NavigationRouteContext, useIsFocused } from '@react-navigation/native';
import Colors from '../../../constants/Colors';
import { StyleSheet, Platform, View, Text, TouchableOpacity, FlatList, Dimensions } from 'react-native';
import Header from '../../../components/ui/Header';
import ShopItem from '../../../components/shops/ShopsItem';
import Loading from '../../../components/Loading';
import hasNotch from '../../../utils/isNotch';
import { AddShopBottomSheetModal } from '../../../components/bottom-sheet-modal/shops/AddShopBottomSheetModal';
import { ScanBottomSheetModal } from '../../../components/bottom-sheet-modal/shops/ScanBottomSheetModal';
import api from '../../../config/api';
import { useSpaceStore } from '../../../store/spaces.state';
import * as Haptics from 'expo-haptics';
import { useTranslation } from 'react-i18next';
import { useShop } from '../../../hooks/useShop';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addShopsValidation } from '../../../components/form/Validations';
import { useShopStore } from '../../../store/shops.state';
import { AntDesign } from '@expo/vector-icons';
import { generateColorHsl } from '../../../utils/generateRandomColors';
import { useDashboardStore } from '../../../store/dashboard.state';
import { ShopSystemBottomSheetModal } from '../../../components/bottom-sheet-modal/shops/ShopSystemBottomSheetModal';

type FormValues = {
  name: string;
  space_id: string;
  platform_name: string;
  platform_type: number;
  client_key: string;
  secret_key: string;
  shop_url: string;
  status: string;
  currency_iso_code: string;
  color: string;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    borderRadius: 8,
    backgroundColor: Colors.light.background,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  padding: 16
  },
  addNewShop: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Platform.OS === 'android' ? 22 : hasNotch() ? 22 : 44,
    marginBottom: 4,
    backgroundColor: Colors.light.grayBtn,
    padding: 6,
    borderRadius: 4,
  },
  addNewShopText: {
    fontSize: 14,
    fontWeight: '600',
  },
  emptyContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 70,
  },
  title: {
    fontSize: 18,
    paddingBottom: 6,
    fontWeight: '600',
    color: Colors.light.blueCharcoal,
  },
  description: {
    textAlign: 'center',
    maxWidth: Dimensions.get('window').width / 1.2,
    lineHeight: 20,
    fontSize: 14,
    paddingBottom: 40,
    color: Colors.light.blueCharcoal,
  },
});

const ShopsScreen = () => {
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const { integrateShop } = useShop();
  const { setAllShops }: any = useShopStore();
  const { defaultSpace }: any = useSpaceStore();
  const bottomSheetRef = useRef<Modalize>(null);
  const scanBottomSheetRef = useRef<Modalize>(null);
  const shopSystemBottomSheetRef = useRef<Modalize>(null);
  const { noShopAddedModal, noShopAddedModalGeneral, shopType, setShopType }: any = useDashboardStore();
  const [onInitializeShop, setInitializeShop] = useState<boolean>(false);
  const [selectedShop, setSelectedShop] = useState({ value: 0 });
  const [values, setValues] = useState({
    loading: true,
    loadingCreate: false,
    isRefreshing: false,
    errors: '',
    shops: [],
  });
  let randomColor = ' ';
  const { ...methods } = useForm({
    defaultValues: {
      status: 1,
      space_id: defaultSpace?.id,
      color: generateColorHsl(defaultSpace.name),
    },
    resolver: yupResolver(addShopsValidation),
    mode: 'all',
  });

  const handlePresentModalPress = useCallback(() => {
    methods.reset();
    bottomSheetRef.current?.open();
  }, []);

  const handleScanModalPress = useCallback(() => {
    bottomSheetRef.current?.close();
    scanBottomSheetRef.current?.open();
  }, []);

  const handleShopSystemModalPress = useCallback(() => {
    bottomSheetRef.current?.close();
    shopSystemBottomSheetRef.current?.open();
  }, []);

  const getPlatformName = (platform_type: any): string => {
    switch (platform_type) {
      case '0':
        return 'Shopware 6';
      case '1':
        return 'Shopware 5';
      case '2':
        return 'Shopify';
      case '3':
        return 'Woocomerce';
      case '4':
        return 'Magento';
      case '5':
        return 'Oxid';
      case '6':
        return 'Ebay';
      case '7':
        return 'Amazon';
      default:
        return '';
    }
  };

  const submitScanData = (
    client_key: string,
    secret_key: string,
    shop_url: string,
    platform_type: number,
    platform_name: string
  ) => {
    setShopType({ value: platform_type, name: getPlatformName(platform_type) });
    const data = {
      platform_name: platform_name,
      name: '',
      space_id: defaultSpace?.id,
      client_key: client_key,
      secret_key: secret_key,
      shop_url: shop_url,
      platform_type: selectedShop?.value || platform_type,
      status: 1,
      color: generateColorHsl(defaultSpace?.id),
    };
    methods.reset(data);
  };

  const handleBackScanModalPress = useCallback(() => {
    scanBottomSheetRef.current?.close();
    bottomSheetRef.current?.open();
    methods.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackShopSystemModalPress = useCallback(() => {
    shopSystemBottomSheetRef.current?.close();
    bottomSheetRef.current?.open();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllShops = async () => {
    try {
      const response = await api('/shops/space/' + defaultSpace.id, 'GET', null);
      if (response) {
        setAllShops(response?.data || []);
        setValues({ ...values, shops: response?.data || [], loading: false, isRefreshing: false });
      }
    } catch (error) {
      setValues({ ...values, loading: false, isRefreshing: false, errors: 'Something went wrong', shops: [] });
    }
  };

  const onCreateShop = async (data: FormValues) => {
    if (!data) return;
    data.platform_type = shopType.value;
    data.space_id = defaultSpace.id; // Made sure the space id is set from space store
    setInitializeShop(true);
    randomColor = generateColorHsl(data.name);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);

    await integrateShop(data);
    await getAllShops();
    methods.reset(); // This will reset all fields to their default values
    setInitializeShop(false);
    bottomSheetRef.current?.close();
    resetObject(data);
  };

  function resetObject(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = '';
      }
    }
  }

  const onActiveDisableShop = async (id: string, status: boolean) => {
    if (!id) return;
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/shop/' + id, 'PUT', { status: status });
      await getAllShops();
    } catch (error) {
      getAllShops();
    }
  };

  const renderItem = ({ item }: any) => (
    <ShopItem
      id={item?.id}
      name={item?.name}
      status={item?.status === 1}
      platformType={item?.platform_type}
      getAllShops={getAllShops}
      onActiveDisableShop={onActiveDisableShop}
    />
  );

  const onRefresh = () => {
    setValues({ ...values, isRefreshing: true });
    getAllShops();
  };

  useEffect(() => {
    if (isFocused) {
      getAllShops();
      if (!noShopAddedModal === noShopAddedModalGeneral) {
        handlePresentModalPress();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <>
      <AddShopBottomSheetModal
        bottomSheetRef={bottomSheetRef}
        loadingCreate={onInitializeShop}
        methods={methods}
        onSubmit={onCreateShop}
        onOpenScanModal={handleScanModalPress}
        onOpenShopSystemModal={handleShopSystemModalPress}
        scanButton={true}
      />

      <ScanBottomSheetModal
        bottomSheetRef={scanBottomSheetRef}
        goBackBtn={handleBackScanModalPress}
        submitData={submitScanData}
      />

      <ShopSystemBottomSheetModal
        bottomSheetRef={shopSystemBottomSheetRef}
        goBackBtn={handleBackShopSystemModalPress}
      />

      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <Header screenName={t('AUTH.ALL_SHOPS')} />
          <TouchableOpacity onPress={handlePresentModalPress} style={styles.addNewShop}>
            <AntDesign name="plus" size={20} />
            <Text style={styles.addNewShopText}> {t('AUTH.NEW_SHOP')} </Text>
          </TouchableOpacity>
        </View>
        {!values?.shops?.length && (
          <View style={styles.emptyContainer}>
            <Text style={styles.title}>{t('AUTH.NO_SHOP_ADD')}</Text>
            <Text style={styles.description}>{t('AUTH.IN_ORDER_TO_SEE_STATISTIC_PLEASE')}</Text>
          </View>
        )}

        {values?.loading ? (
          <Loading />
        ) : (
          <FlatList
          showsVerticalScrollIndicator={false}
            data={values?.shops}
            renderItem={renderItem}
            keyExtractor={(item, index): any => index}
            refreshing={values.isRefreshing}
            onRefresh={onRefresh}
          />
        )}
      </View>
    </>
  );
};
export default memo(ShopsScreen);
