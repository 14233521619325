import { useEffect, useRef, useState } from 'react';
import React, { Alert, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { UserInviteBottomSheetModal } from '../../bottom-sheet-modal/users/UserInviteBottomSheetModal';
import Colors from '../../../constants/Colors';
import { yupResolver } from '@hookform/resolvers/yup';
import { accountValidation } from '../../form/Validations';
import { TextInput } from '../../form/TextInput';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Feather } from '@expo/vector-icons';
import { useAuthStore } from '../../../store/auth.state';
import api from '../../../config/api';
import { useNavigation } from '@react-navigation/native';
import hasNotch from '../../../utils/isNotch';
import { useSpaceStore } from '../../../store/spaces.state';
import Loading from '../../Loading';
import * as Haptics from 'expo-haptics';
import Toast from 'react-native-toast-message';
import { Modalize } from 'react-native-modalize';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../ui/PageTitle';
import MenuItem from '../../ui/action/MenuItem';
import TextInputValidation from '../../form/TextInputValidation';
import VerifyUserIdentityModal from '../../bottom-sheet-modal/VerifyUserIdentityModal';

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
};

export const EditAccount = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const bottomSheetUserRef = useRef<Modalize>(null);
  const bottomSheetUserVerificationRef = useRef<Modalize>(null);

  const { userData, logoutUser }: any = useAuthStore();
  const { resetSpaces }: any = useSpaceStore();

  const currentEmail = userData?.user?.email;

  const userId = userData?.user?.id;

  const { ...methods } = useForm({
    resolver: yupResolver(accountValidation),
    mode: 'all',
    defaultValues: { email: currentEmail },
  });

  const isDisabled = methods?.formState?.isDirty ? false : true;

  const onGoBack = () => {
    navigation.goBack();
  };

  const [values, setValues] = useState({
    edit: false,
    loading: true,
    hasError: '',
  });

  const onLogout = () => {
    logoutUser();
    resetSpaces();
    navigation.reset({ routes: [{ name: 'Auth' }] });
  };

  const deleteUser = async () => {
    setValues({ ...values, loading: true });
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      const response = await api('/user/' + userId, 'DELETE', null);
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.USER_SETTINGS')}`,
        text2: `${t('AUTH.YOUR_ACCOUNT_HAS_BEEN_DELETED')}`,
      });
      if (response) {
        onLogout();
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.USER_SETTINGS')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
    if (!data) return;
    setValues({ ...values, loading: true });
    const body = {
      first_name: data?.first_name,
      last_name: data?.last_name,
    };
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/user/' + userId, 'PUT', body);
      setValues({ ...values, loading: false });
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.USER_SETTINGS')}`,
        text2: `${t('AUTH.USER_WAS_UPDATED_SUCCESSFULL')}`,
      });
      navigation.goBack();
    } catch (error) {
      setValues({ ...values, loading: false });
      navigation.goBack();
    }
  };

  const getCurrentUser = async () => {
    if (!userId) return;
    try {
      const response = await api('/user/' + userId, 'GET', null);
      if (response) {
        methods.reset(response?.data);
        // methods.setValue('first_name', response?.data?.first_name);
        // methods.setValue('last_name', response?.data?.last_name);
      }
      setValues({ ...values, loading: false });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.USER_SETTINGS')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
      setValues({ ...values, loading: false });
    }
  };

  useEffect(() => {
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <KeyboardAwareScrollView
        style={styles.keyboardAvoidStyleContainer}
        scrollEnabled={true}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps={'handled'}
      >
        <View style={styles.container}>
          {values.loading && <Loading />}
          <UserInviteBottomSheetModal bottomSheetRef={bottomSheetUserRef} shops={[]} />
          <View style={styles.headerSettings}>
            <TouchableOpacity style={styles.goBack} onPress={onGoBack}>
              <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
              <Text style={styles.goBackText}>{t('AUTH.ACCOUNT_SETTINGS')}</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={methods.handleSubmit(onSubmit)} disabled={isDisabled}>
              <Text style={[styles.editText, isDisabled && styles.editTextDisabled]}>{t('AUTH.SAVE')}</Text>
            </TouchableOpacity>
          </View>

          <PageTitle title={'AUTH.PERSONAL_INFORMATIONS'} />

          {!!values.hasError && (
            <View>
              <Text style={styles.errorText}>{values?.hasError}</Text>
            </View>
          )}

          <FormProvider {...methods}>
            <TextInput
              name="first_name"
              label={'AUTH.FIRST_NAME'}
              placeholder={t('AUTH.ENTER_YOUR_FIRST_NAME')}
              placeholderTextColor={Colors.light.placeholder}
              style={[styles.inputStyle]}
              required
            />

            {methods.formState.errors.first_name && (
              <TextInputValidation>{t(methods?.formState?.errors?.first_name?.message)}</TextInputValidation>
            )}

            <TextInput
              name="last_name"
              label={'AUTH.LAST_NAME'}
              placeholder={t('AUTH.ENTER_YOUR_LAST_NAME')}
              placeholderTextColor={Colors.light.placeholder}
              style={[styles.inputStyle]}
              required
            />
            {methods.formState.errors.last_name && (
              <TextInputValidation>{t(methods?.formState?.errors?.last_name?.message)}</TextInputValidation>
            )}

            <TextInput
              name="email"
              label={'AUTH.EMAIL'}
              placeholder={t('AUTH.ENTER_YOUR_EMAIL')}
              keyboardType="email-address"
              editable={false}
              autoCapitalize="none"
              placeholderTextColor={Colors.light.placeholder}
              style={styles.inputStyleEdit}
              required
            />

            {methods.formState.errors.email && (
              <TextInputValidation>{t(methods?.formState?.errors?.email?.message)}</TextInputValidation>
            )}
          </FormProvider>

          <MenuItem
            label={'AUTH.DELETE_MY_ACCOUNT'}
            onPress={() => bottomSheetUserVerificationRef.current?.open()}
            color={'red'}
          />
        </View>
      </KeyboardAwareScrollView>

      <VerifyUserIdentityModal
        bottomSheetRef={bottomSheetUserVerificationRef}
        callback={deleteUser}
        title={'Delete account'}
        text={
          'Deleting your account means parting ways with personalized perks, exclusive access, and customized preferences. No more tailored recommendations or the convenience of a familiar space. Deleting is like saying goodbye to a digital extension of yourself. Farewell to the unique privileges and seamless experience you’ve enjoyed. Are you sure you’re ready to let go of all that awesomeness?'
        }
        subTitle={'Are you sure you’re ready to let go of all that awesomeness?'}
        actionText={'Delete My Account'}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.background,
  },
  chevronButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 16,
    width: '100%',
  },
  errorText: {
    textAlign: 'center',
    color: Colors.light.red,
  },
  accountStyle: {
    paddingTop: 20,
    fontWeight: '500',
    paddingBottom: 20,
    fontSize: 18,
  },
  informationStyle: {
    paddingTop: 40,
    fontWeight: '500',
  },
  inputStyleEdit: {
    backgroundColor: Colors.light.grayDark,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 30,
  },
  main: {
    width: '100%',
  },
  buttonText: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: Colors.light.red,
    fontSize: 14,
    fontWeight: '500',
  },
  button: {
    backgroundColor: Colors.light.gray,
    width: '100%',
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  goForward: {
    flexDirection: 'row',
    marginBottom: 50,
    alignItems: 'center',
  },
  secondStyle: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editTextDisabled: {
    color: Colors.light.editTextDisabled,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerSettings: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
});
