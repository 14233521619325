import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Colors from '../../../constants/Colors';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import { useTranslation } from 'react-i18next';
import api from '../../../config/api';
import { Feather, FontAwesome } from '@expo/vector-icons';

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 48,
    paddingHorizontal: 16,
  },
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  name: {
    fontWeight: '500',
    flex: 1,
  },
  image: {
    marginRight: 12,
  },
  circle: {
    // backgroundColor: Colors.light.blueRibbon,
  },
});

interface IShopSystemBottomSheetModal {
  bottomSheetRef: any;
  goBackBtn: () => void;
  onOpenModal?: () => void;
  page: any;
  setPage: any;
}

export const SelectPageBottomSheetModal = ({
  bottomSheetRef,
  goBackBtn,
  page,
  setPage,
}: IShopSystemBottomSheetModal) => {
  const { t } = useTranslation();
  const [pages, setPages] = useState([]);

  const backBtn = () => {
    goBackBtn();
  };

  const getPages = async () => {
    const pages = await api('/user/list/pages', 'GET', null);
    setPages(pages.data);
  };

  useEffect(() => {
    getPages();
  }, []);

  const onChangePage = async (page: any) => {
    setPage(page);
    goBackBtn();
  };

  const renderContent = () => {
    return (
      <View style={styles.container}>
        {pages.map((item) => {
          return (
            <TouchableOpacity style={styles.touchable} onPress={() => onChangePage(item)}>
              <Text style={styles.name}>{item.name}</Text>
              <View style={styles.circle}>
                {item.name === page.name ? (
                  <FontAwesome name="dot-circle-o" size={20} color={Colors.light.blueRibbon} />
                ) : (
                  <Feather name="circle" size={20} color={Colors.light.silver} />
                )}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.SELECT_PAGE')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
