import * as yup from 'yup';

export const signInValidation = yup
  .object({
    email: yup.string().email('VALIDATIONS.PLEASE_ADD_VALID_EMAIL').required('VALIDATIONS.EMAIL_REQUIRED'),
    password: yup.string().required('VALIDATIONS.REQUIRED_PASSWORD'),
  })
  .required();

export const accountValidation = yup
  .object({
    first_name: yup
      .string()
      .min(1, 'VALIDATIONS.FIRST_NAME_MIN')
      .max(30, 'VALIDATIONS.FIRST_NAME_MAX')
      .required('VALIDATIONS.REQUIRED_FIRSTNAME'),
    last_name: yup
      .string()
      .min(1, 'VALIDATIONS.LAST_NAME_MIN')
      .max(30, 'VALIDATIONS.LAST_NAME_MAX')
      .required('VALIDATIONS.REQUIRED_LASTNAME'),
    email: yup.string().email('VALIDATIONS.PLEASE_ADD_VALID_EMAIL'),
  })
  .required();

export const signUpValidation = yup
  .object({
    language: yup.string(),
    first_name: yup
      .string()
      .min(1, 'VALIDATIONS.FIRST_NAME_MIN')
      .max(30, 'VALIDATIONS.FIRST_NAME_MAX')
      .required('VALIDATIONS.REQUIRED_FIRSTNAME'),
    last_name: yup
      .string()
      .min(1, 'VALIDATIONS.LAST_NAME_MIN')
      .max(30, 'VALIDATIONS.LAST_NAME_MAX')
      .required('VALIDATIONS.REQUIRED_LASTNAME'),
    email: yup.string().email('VALIDATIONS.PLEASE_ADD_VALID_EMAIL').required('VALIDATIONS.EMAIL_REQUIRED'),
    password: yup
      .string()
      .min(6, 'VALIDATIONS.PASSWORD_MIN')
      .max(15, 'VALIDATIONS.PASSWORD_MAX')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{6,15}$/,
        'Password must contain at least one capital letter, one number, and one special character.'
      )
      .required('VALIDATIONS.REQUIRED_PASSWORD'),
    password_confirmation: yup
      .string()
      .min(6, 'VALIDATIONS.PASSWORD_MIN')
      .max(15, 'VALIDATIONS.PASSWORD_MAX')
      .required('VALIDATIONS.REQUIRED_PASSWORD_CONFIRMATION')
      .oneOf([yup.ref('password'), null], 'VALIDATIONS.YOUR_PASSWORD_DO_NOT'),
    terms_conditions: yup.boolean().oneOf([true], 'You must agree with terms and conditions'),
  })
  .required();

export const createSpaceValidation = yup.object({
  space_name: yup
    .string()
    .min(3, 'VALIDATIONS.SPACE_SHOULD_MIN')
    .max(30, 'VALIDATIONS.SPACE_SHOULD_MAX')
    .required('VALIDATIONS.REQUIRED_SPACE_NAME'),
});

export const joinSpaceValidation = yup.object({
  space_code: yup
    .string()
    .min(6, 'VALIDATIONS.SPACE_SHOULD_MIN')
    .max(6, 'VALIDATIONS.SPACE_SHOULD_MAX')
    .required('VALIDATIONS.REQUIRED_SPACE_CODE'),
});

export const addShopsValidation = yup.object({
  name: yup
    .string()
    .min(3, 'VALIDATIONS.SHOP_NAME_MIN')
    .max(15, 'VALIDATIONS.SHOP_NAME_MAX')
    .required('VALIDATIONS.SHOP_NAME_IS_REQUIRED'),
  platform_name: yup
    .string()
    .max(15, 'VALIDATIONS.PLATFORM_NAME_MAX')
    .required('VALIDATIONS.PLATFORM_NAME_IS_REQUIRED'),
  client_key: yup.string().required('VALIDATIONS.REQUIRED_CLIENT_KEY'),
  secret_key: yup.string(),
  shop_url: yup
    .string()
    // .matches(
    //   /^(https:\/\/)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   'VALIDATIONS.REQUIRED_SHOP_URL_HTTPS'
    // )
    .matches(/^https:\/\//, 'VALIDATIONS.REQUIRED_SHOP_URL_HTTPS')
    .required('VALIDATIONS.REQUIRED_SHOP_URL'),
});

export const feedbackValidations = yup.object({
  // page_id: yup.string().required('VALIDATIONS.REQUIRED_SELECT_PAGE'),
  specific_field: yup.string().required('VALIDATIONS.REQUIRED_SPECIFIC_FIELD'),
  description: yup.string().required('VALIDATIONS.REQUIRED_DESCRIPTION'),
});

export const inviteUserValidation = yup.object({
  // shop_ids: yup.string().required('VALIDATIONS.PLEASE_SELECT_ONE_SHOP'),
  role_id: yup.string().required('VALIDATIONS.PLEASE_SELECT_ONE_ROLE'),
  email: yup.string().email('VALIDATIONS.PLEASE_ADD_VALID_EMAIL').required('VALIDATIONS.EMAIL_REQUIRED'),
});

export const resetPasswordValidation = yup.object({
  email: yup.string().email('VALIDATIONS.PLEASE_ADD_VALID_EMAIL').required('VALIDATIONS.EMAIL_REQUIRED'),
});

export const resetPasswordFieldsValidation = yup.object({
  new_password: yup
    .string()
    .min(6, 'VALIDATIONS.PASSWORD_MIN')
    .max(15, 'VALIDATIONS.PASSWORD_MAX')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{6,15}$/,
      'Password must contain at least one capital letter, one number, and one special character.'
    )
    .required('VALIDATIONS.REQUIRED_NEW_PASSWORD'),

  confirm_password: yup
    .string()
    .min(6, 'VALIDATIONS.PASSWORD_MIN')
    .max(15, 'VALIDATIONS.PASSWORD_MAX')
    .oneOf([yup.ref('new_password'), null], 'VALIDATIONS.YOUR_PASSWORD_DO_NOT')
    .required('VALIDATIONS.REQUIRED_PASSWORD_CONFIRMATION'),
});

export const updatePasswordValidation = yup.object({
  current_password: yup.string().required('VALIDATIONS.REQUIRED_PASSWORD'),
  new_password: yup
    .string()
    .min(6, 'VALIDATIONS.PASSWORD_MIN')
    .max(15, 'VALIDATIONS.PASSWORD_MAX')
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{6,15}$/,
      'Password must contain at least one capital letter, one number, and one special character.'
    )
    .required('VALIDATIONS.REQUIRED_NEW_PASSWORD'),
  confirm_password: yup
    .string()
    .min(6, 'VALIDATIONS.PASSWORD_MIN')
    .max(15, 'VALIDATIONS.PASSWORD_MAX')
    .oneOf([yup.ref('new_password'), null], 'VALIDATIONS.YOUR_PASSWORD_DO_NOT')
    .required('VALIDATIONS.REQUIRED_PASSWORD_CONFIRMATION'),
});

export const companyNameIndustry = yup.object({
  name: yup.string().max(30, 'VALIDATIONS.COMPANY_NAME_MAX').required('VALIDATIONS.PLEASE_ENTER_COMPANY_NAME'),
  industry_id: yup.string().required('VALIDATIONS.PLEASE_SELECT_INDUSTRY'),
});
export const companyAddress = yup.object({
  street: yup.string().max(30, 'VALIDATIONS.STREET_NUMBER_MAX').required('VALIDATIONS.STREET_REQUIRED'),
  country: yup.string().required('VALIDATIONS.PLEASE_SELECT_COUNT'),
  zip_code: yup.string().max(15, 'VALIDATIONS.ZIP_MAX').required('VALIDATIONS.ZIP_CODE_REQUIRED'),
  city: yup.string().max(30, 'VALIDATIONS.CITY_MAX').required('VALIDATIONS.CITY_REQUIRED'),
});
export const contactDetails = yup.object({
  email: yup.string().email('VALIDATIONS.PLEASE_ADD_VALID_EMAIL'),
  phone: yup.string().optional(),
});

export const spaceInformationValidation = yup.object({
  space_name: yup.string().required('VALIDATIONS.REQUIRED_SPACE_NAME'),
  space_code: yup.string().required('VALIDATIONS.REQUIRED_SPACE_CODE'),
});
