import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import Colors from '../../constants/Colors';
import { useConfigStore } from '../../store/config.state';
import { useDashboardStore } from '../../store/dashboard.state';
import { formatCurrency, formatValueToFix } from '../../utils/formatCurrency';

const StatBlocks = () => {
  const { turnover, averageBasket, cro, totalVisitors, totalOrders, salesByShop }: any = useDashboardStore();
  const { currentLanguage }: any = useConfigStore();
  const { activeShop }: any = useDashboardStore();
  const sumOfAverage = salesByShop.reduce((sum, item) => sum + item.avgSales, 0);

  const { t } = useTranslation();

  return (
    <View>
      <View style={styles.ordersBlocks} > 
      {totalOrders?.number !== null && (
            <View style={styles.orderItem}>
              <Text style={styles.blockItemTitle}>{t('AUTH.TOTAL_ORDERS')}</Text>
              <Text style={styles.blockItemResults}>{formatValueToFix(totalOrders?.number, currentLanguage)}</Text>
              {totalOrders?.isPozitive !== null && (
                <Text style={[styles.blockItemPercentage, totalOrders?.isPozitive ? styles.positive : styles.negative]}>
                  {totalOrders?.isPozitive ? (
                    <MaterialCommunityIcons name="arrow-up-thin" size={12} color={Colors.light.green} />
                  ) : (
                    <MaterialCommunityIcons name="arrow-down-thin" size={12} color={Colors.light.bittersweet} />
                  )}
                  {/* {formatValueToFix(12)}% */}
                </Text>
              )}
            </View>
          )}

          {averageBasket?.number !== null && (
            <View style={styles.orderItem}>
              <Text style={styles.blockItemTitle}>{t('AUTH.AVERAGE_BASKET')}</Text>
              <Text style={styles.blockItemResults}>{formatCurrency(sumOfAverage, activeShop.shopCurrency, currentLanguage)}</Text>
              {averageBasket?.isPozitive !== null && (
                <Text
                  style={[styles.blockItemPercentage, averageBasket?.isPozitive ? styles.positive : styles.negative]}
                >
                  {averageBasket?.isPozitive ? (
                    <MaterialCommunityIcons name="arrow-up-thin" size={12} color={Colors.light.green} />
                  ) : (
                    <MaterialCommunityIcons name="arrow-down-thin" size={12} color={Colors.light.bittersweet} />
                  )}
                  {/* {formatValueToFix(12)}% */}
                </Text>
              )}
            </View>
          )}
      </View>
      <ScrollView horizontal>
        <View style={styles.blocks}>
          

          {cro?.number !== null && (
            <View style={styles.blockItem}>
              <Text style={styles.blockItemTitle}>{t('CRO')}</Text>
              <Text style={styles.blockItemResults}>{totalVisitors?.number}%</Text>
              {cro?.isPozitive !== null && (
                <Text style={[styles.blockItemPercentage, cro?.isPozitive ? styles.positive : styles.negative]}>
                  {cro?.isPozitive ? (
                    <MaterialCommunityIcons name="arrow-up-thin" size={12} color={Colors.light.green} />
                  ) : (
                    <MaterialCommunityIcons name="arrow-down-thin" size={12} color={Colors.light.bittersweet} />
                  )}
                  {/* {formatValueToFix(12)}% */}
                </Text>
              )}
            </View>
          )}

          {totalVisitors?.number !== null && (
            <View style={styles.blockItem}>
              <Text style={styles.blockItemTitle}>{t('AUTH.TOTAL_VISITORS')}</Text>
              <Text style={styles.blockItemResults}>{totalVisitors?.number}%</Text>
              {totalVisitors?.isPozitive !== null && (
                <Text
                  style={[styles.blockItemPercentage, totalVisitors?.isPozitive ? styles.positive : styles.negative]}
                >
                  {totalVisitors?.isPozitive ? (
                    <MaterialCommunityIcons name="arrow-up-thin" size={12} color={Colors.light.green} />
                  ) : (
                    <MaterialCommunityIcons name="arrow-down-thin" size={12} color={Colors.light.bittersweet} />
                  )}
                  {/* {formatValueToFix(-12)}% */}
                </Text>
              )}
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default StatBlocks;

const styles = StyleSheet.create({
  ordersBlocks:{
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    gap: 10,

  },
  orderItem:{
    paddingHorizontal: 16,
    backgroundColor: Colors.light.white,
    borderRadius: 12,
    padding: 10,
    flex: 1,
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 1,
    shadowRadius: 6,
    shadowColor: Colors.light.shadowColor,
    elevation: 2,
    
  }, 
  blocks: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 0,
    gap: 8,

  },
  blockItem: {
    paddingHorizontal: 16,
    backgroundColor: Colors.light.catskillWhite,
    borderRadius: 12,
    padding: 10,
  },
  blockItemTitle: {
    fontSize: 10,
    lineHeight: 12,
    marginBottom: 6,
    fontWeight: '600',
 
    color : Colors.light.grayLight
  },
  blockItemResults: {
    fontSize: 14,
    lineHeight: 16,
    // marginBottom: 6,
    fontWeight: '600',
  },
  blockItemPercentage: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 'bold',
  },
  positive: {
    color: Colors.light.green,
  },
  negative: {
    color: Colors.light.bittersweet,
  },
});
