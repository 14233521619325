import React, { useState } from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { Feather } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';
import hasNotch from '../../../utils/isNotch';
import PageTitle from '../../ui/PageTitle';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TextInput } from '../../form/TextInput';
import TextInputValidation from '../../form/TextInputValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { spaceInformationValidation } from '../../form/Validations';
import { useSpaceStore } from '../../../store/spaces.state';
import * as Haptics from 'expo-haptics';
import api from '../../../config/api';
import Toast from 'react-native-toast-message';

type FormValues = {
    space_name: string;
    space_code: string;
};

const EditSpaceInformation = () => {
    const [ loading, setLoading ] = useState<boolean>(false);

    const { defaultSpace, setDefaultSpace }: any = useSpaceStore();
    
    const navigation = useNavigation();
    const { t } = useTranslation();

    const { ...methods } = useForm({
        resolver: yupResolver(spaceInformationValidation),
        mode: 'all',
        defaultValues: {
            space_name: defaultSpace?.name,
            space_code: defaultSpace?.code,
        },
    });

    const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
        if (!data) return;
        setLoading(true);

        const body = {
            space_name: data?.space_name,
        };

        try {
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
          const response = await api('/space/update/' + defaultSpace?.id, 'POST', body);

            if(response.success) {
                setDefaultSpace({ 
                    id: defaultSpace?.id, 
                    name: data?.space_name, 
                    code: defaultSpace?.code 
                });

                Toast.show({
                    type: 'success',
                    text1: `${t('AUTH.SPACE_DETAILS')}`,
                    text2: `${t('AUTH.SPACE_UPDATED_SUCCESSFULL')}`,
                });

                navigation.goBack();
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            navigation.goBack();
        }
      };
    
    return(
        <KeyboardAwareScrollView
            style={styles.keyboardAvoidStyleContainer}
            scrollEnabled={true}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps={'handled'}
        >
            <View style={styles.container}>
                <View style={styles.headerNavigation}>
                    <TouchableOpacity style={styles.goBack} onPress={() => navigation.goBack()}>
                        <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
                        <Text style={styles.goBackText}>{t('AUTH.SPACE_DETAILS')}</Text>
                    </TouchableOpacity>

                    <TouchableOpacity disabled={loading} style={styles.goBack} onPress={methods.handleSubmit(onSubmit)}>
                        <Text style={styles.goBackText}>{t('AUTH.SAVE')}</Text>
                    </TouchableOpacity>
                </View>

                <View>
                    <PageTitle 
                        title={'AUTH.SPACE_INFORMATION'}
                        description={'AUTH.SPACE_EDIT_DETAILS'}
                    />
                </View>

                <FormProvider {...methods}>
                    <TextInput
                        name="space_name"
                        label={'AUTH.SPACE_NAME'}
                        placeholder={t('AUTH.ENTER_YOUR_SPACE_NAME')}
                        placeholderTextColor={Colors.light.placeholder}
                        style={[styles.inputStyle]}
                        required
                        disabled={loading}
                    />

                    {methods.formState.errors.space_name && (
                        <TextInputValidation>{t(methods?.formState?.errors?.space_name?.message)}</TextInputValidation>
                    )}

                    <TextInput
                        name="space_code"
                        label={'AUTH.SPACE_CODE'}
                        editable={false}
                        style={[styles.inputStyleDisable]}
                        required
                    />

                    {methods.formState.errors.space_code && (
                        <TextInputValidation>{t(methods?.formState?.errors?.space_code?.message)}</TextInputValidation>
                    )}
                </FormProvider>
            </View>
        </KeyboardAwareScrollView>
    );
}

export default EditSpaceInformation;


const styles = StyleSheet.create({
    keyboardAvoidStyleContainer: {
        backgroundColor: Colors.light.background,
        flexGrow: 1,
    },
    container: {
        flex: 1,
        padding: 24,
        justifyContent: 'space-between',
        backgroundColor: Colors.light.background,
    },
    headerNavigation: {
        paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
        paddingVertical: 12,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Colors.light.background,
    },
    goBack: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: -6,
    },
    goBackText: {
        fontSize: 16,
        color: Colors.light.blueRibbon,
        fontWeight: '500',
    },
    inputStyleDisable: {
        backgroundColor: Colors.light.grayDark,
        borderWidth: 1,
        borderColor: Colors.light.border,
        borderRadius: 8,
        paddingHorizontal: 16,
        height: 54,
      },
    inputStyle: {
        backgroundColor: Colors.light.background,
        borderWidth: 1,
        borderColor: Colors.light.border,
        borderRadius: 8,
        paddingHorizontal: 16,
        height: 54,
    },
});