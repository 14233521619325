import React, { useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import Header from '../../ui/Header';
import Colors from '../../../constants/Colors';
import { Modalize } from 'react-native-modalize';
import { PrivacyBottomSheetModal } from '../../bottom-sheet-modal/about/PrivacyBottomSheetModal';
import { TermsBottomSheetModal } from '../../bottom-sheet-modal/about/TermsBottomSheetModal';
import { AboutBottomSheetModal } from '../../bottom-sheet-modal/about/AboutBottomSheetModal';
import PageTitle from '../../ui/PageTitle';
import MenuItem from '../../ui/action/MenuItem';
import ListItemContainer from '../../ui/ListItemContainer';
import ListItemDivider from '../../ui/ListItemDivider';

export const UserAbout = () => {
  const bottomSheetPrivacyRef = useRef<Modalize>(null);
  const bottomSheetTermsRef = useRef<Modalize>(null);
  const bottomSheetAboutRef = useRef<Modalize>(null);

  return (
    <View style={styles.container}>
      <Header screenName={`Main menu`} values="null" setValues="null" />
      
      <PrivacyBottomSheetModal bottomSheetRef={bottomSheetPrivacyRef} />
      <TermsBottomSheetModal bottomSheetRef={bottomSheetTermsRef} />
      <AboutBottomSheetModal bottomSheetRef={bottomSheetAboutRef} />

      <PageTitle title={'AUTH.ABOUT'} description={'AUTH.READ_PRIVACY_POLICY'} />

      <ListItemContainer>
        <MenuItem label={'AUTH.PRIVACY_POLICY'} onPress={() => bottomSheetPrivacyRef?.current?.open()} />
        <ListItemDivider />
        <MenuItem label={'AUTH.TERMS_OF_USE'} onPress={() => bottomSheetTermsRef?.current?.open()} />
        <ListItemDivider />
        <MenuItem label={'AUTH.ABOUT_THE_APP'} onPress={() => bottomSheetAboutRef?.current?.open()} />
      </ListItemContainer>
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: Colors.light.background,
  },
});