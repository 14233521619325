import React, { memo } from 'react';
import { Dimensions } from 'react-native';
import { useDashboardStore } from '../../../../../store/dashboard.state';
import DashboardStackedBarChart from '../../StackedBarChart';

const BarChartMerge = () => {
  const { mergeSales }: any = useDashboardStore();

  const dataSets = mergeSales[0]?.totalHour
    ? mergeSales.map((sale: any) => {
        if (Array.isArray(sale?.totalHour)) {
          return sale.totalHour;
        }
      })
    : [];

  const data = {
    labels: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ],
    data: dataSets,
  };

  const chartConfig = {
    width:
      data.labels?.length >= 10
        ? Dimensions.get('window').width * (data.labels?.length / 10)
        : Dimensions.get('window').width / 1.15,
    marginLeft: 5,
    marginRight: 0,
    barLContentInset: 0,
    barRContentInset: 10,
    rContentInset: 22.5,
    linePos: 6,
  };

  return <DashboardStackedBarChart data={data} chartConfig={chartConfig} />;
};

export default memo(BarChartMerge);
