import React, { useState } from 'react';
import RNPickerSelect from 'react-native-picker-select';
import Colors from '../../../constants/Colors';
import { StyleSheet, Text, View } from 'react-native';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { inviteUserValidation } from '../../form/Validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '../../form/TextInput';
import { Feather } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { useTranslation } from 'react-i18next';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import LabelPickerSelect from '../../form/LabelPickerSelect';
import TextInputValidation from '../../form/TextInputValidation';
import Button from '../../ui/action/Button';
import { useSpaceStore } from '../../../store/spaces.state';
import api from '../../../config/api';
import Toast from 'react-native-toast-message';

type EditFormValues = {
  shop_id: string;
  role_id: string;
  email: string;
};

interface IUserInviteBottomSheetModal {
  bottomSheetRef: any;
  shops: any;
  roles: any;
  id: string;
  email: string;
  userShops: any;
  userRole: string;
}

export const EditUserInviteBottomSheetModal = ({
  bottomSheetRef,
  shops,
  roles,
  id,
  email,
  userShops,
  userRole,
}: IUserInviteBottomSheetModal) => {
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { ...methods } = useForm({
    resolver: yupResolver(inviteUserValidation),
    mode: 'all',
    defaultValues: { 
      email, 
      role_id: userRole ? userRole : null, 
      shop_id: userShops && userShops[0]?.id  ? userShops[0]?.id : null
    },
  });

  const { defaultSpace }: any = useSpaceStore();

  const assignShop = async (email: string, shop_id: string) => {
    const body = {
      email,
      shop_id
    }

    try {
      await api(`/space/assignShop/${defaultSpace?.id}`, 'POST', body);
      return true;
    } catch (error) {
      return false;
    }
  }

  const changeRole = async(user_id: string, user_role: string) => {
    const body = {
      user_id,
      new_role: user_role,
    }

    try {
      await api(`/space/changeRole/${defaultSpace?.id}`, 'POST', body);
      return true;
    } catch (error) {
      return false;
    }
  }

  const onSubmit = async (data: EditFormValues) => {
    setLoading(true);
    try {
      const isShopAssigned = await assignShop(email, data?.shop_id);
      const hasRoleChanged = await changeRole(id, data?.role_id);
      if(isShopAssigned && hasRoleChanged) {
        Toast.show({
          type: 'success',
          text1: 'Edit user',
          text2: 'Chaged saved successfully'
        })
        bottomSheetRef?.current?.close();
      } else {
        Toast.show({
          type: 'error',
          text1: 'Edit user',
          text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`
        })
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Edit user',
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`
      })
    } finally {
      setLoading(false);
    }
  }

  const backBtn = () => {
    bottomSheetRef.current?.close();
  };

  const renderContent = () => (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={false}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.contentContainer}>
        <Text style={styles.description}>{t('AUTH.INVITE_YOUR_TEAM_MEMBER_TO')}</Text>
        <FormProvider {...methods}>
          {shops && shops.length > 0 ? (
            <>
              <LabelPickerSelect required>{t('AUTH.SELECT_SHOP')}</LabelPickerSelect>
              <View style={{ marginBottom: 16 }}>
                <Controller
                  control={methods.control}
                  name="shop_id"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <RNPickerSelect
                      placeholder={{ label: `${t('AUTH.SELECT_SHOP')}` }}
                      onValueChange={onChange}
                      items={shops.map((item: any) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      style={pickerSelectStyles}
                      value={value}
                    />
                  )}
                />
                <Feather name="chevron-down" size={20} style={styles.arrowSelect} />
                {methods?.formState?.errors?.shop_id && (
                  <TextInputValidation>{t(methods?.formState?.errors?.shop_id?.message)}</TextInputValidation>
                )}
              </View>
            </>
          ) : (
            <Text>
              {t('AUTH.CURRENTLY')} {shops?.length} {t('AUTH.CURRENTLY_SHOPS_AVAILABLE')}{' '}
            </Text>
          )}

          <>
            <LabelPickerSelect required>{t('AUTH.SELECT_ROLE')}</LabelPickerSelect>
            <View style={{ marginBottom: 16 }}>
              <Controller
                control={methods.control}
                name="role_id"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <RNPickerSelect
                    placeholder={{ label: `${t('AUTH.SELECT_ROLE')}` }}
                    onValueChange={onChange}
                    items={roles.map((item: any) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                    style={pickerSelectStyles}
                    value={value}
                  />
                )}
              />
              <Feather name="chevron-down" size={20} style={styles.arrowSelect} />
              {methods?.formState?.errors?.role_id && (
                <TextInputValidation>{t(methods?.formState?.errors?.role_id?.message)}</TextInputValidation>
              )}
            </View>
          </>

          <TextInput
            name="email"
            label={t('AUTH.EMAIL')}
            placeholder={t('AUTH.ENTER_USER_EMAIL')}
            keyboardType="email-address"
            editable={false}
            autoCapitalize="none"
            placeholderTextColor={Colors.light.placeholder}
            style={[styles.inputStyle, { backgroundColor: Colors.light.grayDark }]}
            required
          />

          {methods?.formState?.errors?.email && (
            <TextInputValidation>{t(methods?.formState?.errors?.email?.message)}</TextInputValidation>
          )}
        </FormProvider>
      </View>

      <View style={styles.bottom}>
        <Button 
          loading={loading}
          disabled={!shops?.length}
          label={'AUTH.SAVE_CHANGES'}
          onPress={methods.handleSubmit(onSubmit)}
          bg={"dark"}
        />
      </View>
    </KeyboardAwareScrollView>
  );

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.EDIT_USER')}`, backBtn, `${t('AUTH.CANCEL')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};


const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: Colors.light.background,
    paddingTop: 24,
    paddingHorizontal: 16,
  },

  keyboardAvoidStyleContainer: {
    marginBottom: 24,
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  description: {
    fontSize: 14,
    marginBottom: 24,
  },
  labelPickerSelect: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  disabledBtn: {
    backgroundColor: Colors.light.midGray,
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },

  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
});