import Colors from '../../../constants/Colors';
import React, { memo, useState } from 'react';
import { StyleSheet, View, Text, FlatList } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useDashboardStore } from '../../../store/dashboard.state';
import { formatCurrency } from '../../../utils/formatCurrency';
import { generateColorHsl } from '../../../utils/generateRandomColors';

type IColorTotal = {
  colorShop: any;
};
export const TotalSales = ({ colorShop }: IColorTotal) => {
  const { salesByShop }: any = useDashboardStore();
  const { activeShop }: any = useDashboardStore();

  const { t } = useTranslation();

  const renderItem = ({ item }: any) => {
    const shopColorObject = colorShop && colorShop.find((shop: any) => shop.shopName === item.name);
    const shopColor = shopColorObject ? shopColorObject.color : generateColorHsl(item.name);

    return (
      <View style={styles.tabView}>
        <View style={styles.headerTabView}>
          <View style={styles.headerLeft}>
            <View style={[styles.shopNameDot, { backgroundColor: shopColor }]}></View>
            <Text style={styles.headerTabShopItem}>{item?.name}</Text>
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.headerTabShopItem}>{formatCurrency(item?.totSales, activeShop.shopCurrency)}</Text>
            <Text style={styles.headerTabShopItem}>{item?.totOrders}</Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <View>
      <View style={styles.headerTabView}>
        <View style={styles.headerLeft}>
          <Text style={styles.headerTabViewTitle}>{t('AUTH.SHOP_NAME')}</Text>
        </View>
        <View style={styles.headerRight}>
          <Text style={styles.headerTabViewTitle}>{t('AUTH.TOTAL_TURNOVER')}</Text>
          <Text style={styles.headerTabViewTitle}>{t('AUTH.ORDER')}</Text>
        </View>
      </View>

      <FlatList data={salesByShop} renderItem={renderItem} keyExtractor={(item: any) => item?.id} />
    </View>
  );
};

const styles = StyleSheet.create({
  headerTabView: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    flexWrap: 'wrap',
  },
  headerLeft: {
    width: '49%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRight: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '49%',
  },
  headerTabViewTitle: {
    fontSize: 10,
    color: Colors.light.hitGray,
    fontWeight: '500',
  },
  tabView: {
    borderBottomColor: Colors.light.athensGray,
    borderBottomWidth: 1,
    paddingVertical: 16,
    borderRadius: 4,
  },
  headerTabShopItem: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.light.shark,
  },
  shopNameDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginRight: 5,
  },
});
