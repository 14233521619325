import { Portal } from '@gorhom/portal';
import React, { useState, memo } from 'react';
import { StyleSheet, Dimensions, View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Colors from '../../../constants/Colors';
import { joinSpaceValidation } from '../../../components/form/Validations';
import ColorPicker from 'react-native-wheel-color-picker';
import api from '../../../config/api';
import Toast from 'react-native-toast-message';
import { useDashboard } from '../../../hooks/useDashboard';

interface IColorPicker {
  bottomSheetRef: any;
  onColorChange: (color: string) => void;
  shopitem: any;
}

const ColorBottomSheetModal = ({ shopitem, bottomSheetRef, onColorChange }: IColorPicker) => {
  const [color, setColor] = useState('');
  const { t } = useTranslation();
  const { ...methods } = useForm({ resolver: yupResolver(joinSpaceValidation), mode: 'all' });
  const { fetchDashboard }: any = useDashboard();

  const backBtn = async () => {
    bottomSheetRef.current?.close();
    const shopUpdate = async (shopID: string) => {
      const response = await api('/shop/update/' + shopID, 'POST', {
        color: color,
      });
      await fetchDashboard();
      Toast.show({
        type: 'success',
        text1: 'Color updated successfully',
        visibilityTime: 1000,
      });
      return response;
    };
    shopUpdate(shopitem.shop.id);
  };

  const renderContent = () => {
    return (
      <View style={styles.container}>
        <View style={styles.main}>
          <View style={{ marginBottom: 16 }}>
            <View>
              <View style={styles.sectionContainer}>
                <ColorPicker
                  color={color}
                  onColorChange={(color) => {
                    setColor(color);
                    onColorChange(color);
                  }}
                  thumbSize={30}
                  sliderSize={30}
                  noSnap={true}
                  row={false}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.COLOR')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

export default memo(ColorBottomSheetModal);

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 48,
    paddingHorizontal: 16,
  },
  main: {
    paddingTop: 24,
    paddingHorizontal: 16,
  },
  keyboardAvoidStyleContainer: {
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  sectionContainer: {
    paddingHorizontal: 24,
  },
  inputStyle1: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ECECEC',
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderTopStartRadius: 8,
    borderBottomStartRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: Dimensions.get('window').width / 1.7,
  },
  inputWithDomain: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputStyleDomain: {
    backgroundColor: Colors.light.mystic,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderBottomEndRadius: 8,
    borderTopEndRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: 140,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    marginBottom: 60,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  root: { flex: 1, padding: 20 },
  title: { fontSize: 14, fontWeight: '500' },
  codeFieldRoot: { marginTop: 10 },
  inputStyleJoin: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    width: Dimensions.get('window').width / 1.133,
    paddingHorizontal: 16,
    height: 54,
    borderRadius: 8,
  },
  cell: {
    width: 48,
    height: 55,
    lineHeight: 20,
    fontSize: 18,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#00000030',
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
});
const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
});
