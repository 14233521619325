import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Text, View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import { useForm, FormProvider, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyNameIndustry } from '../../form/Validations';
import { TextInput } from '../../form/TextInput';
import Colors from '../../../constants/Colors';
import { useAuthStore } from '../../../store/auth.state';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Feather } from '@expo/vector-icons';
import { StackScreenProps } from '@react-navigation/stack';
import api from '../../../config/api';
import * as Haptics from 'expo-haptics';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../ui/PageTitle';
import TextInputValidation from '../../form/TextInputValidation';
import LabelPickerSelect from '../../form/LabelPickerSelect';
import { Modalize } from 'react-native-modalize';
import { SelectIndustryBottomSheetModal } from './SelectIndustryBottomSheetModal';
import { useDashboardStore } from '../../../store/dashboard.state';

type FormValues = {
  company_name: string;
  industry_id: string;
};

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
});

export const EditCompanyInformations: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const { userData }: any = useAuthStore();
  const userId = userData?.user?.id;
  const userEmail = userData?.user?.email;
  const { t } = useTranslation();
  const { selectedIndustry }: any = useDashboardStore();

  const { ...methods } = useForm({
    resolver: yupResolver(companyNameIndustry),
    mode: 'all',
    defaultValues: { name: '', industry_id: '' },
    // defaultValues: { email: userEmail },
  });

  const isDisabled = methods?.formState?.isDirty ? false : true;

  const [values, setValues] = useState({
    loading: true,
    errors: '',
    edit: false,
    company: [],
    industries: [],
  });

  const createCompany = async (data: any) => {
    if (!data) return;
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/company/create', 'POST', data);
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.COMPANY_WAS_CREATED_SUCCESSFULL')}`,
      });
      navigation.navigate('Company', { isRefreshing: true });
    } catch (error: any) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: error.message,
      });
    }
  };

  const updateCompany = async (data: any) => {
    if (!data) return;
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/company/update', 'POST', data);
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.COMPANY_UPDATED_SUCCESSFULL')}`,
      });
      navigation.navigate('Company', { isRefreshing: true });
    } catch (error: any) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: error.message,
      });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (!userId) return;
    // data.industry_id = selectedIndustry.value;
    setValues({ ...values, loading: true });
    if (!values.company) {
      await createCompany(data);
    } else {
      await updateCompany(data);
    }
    setValues({ ...values, loading: false });
  };

  const getUserDetails = async (id: string) => {
    if (!id) return;
    try {
      const response = await api('/user/' + id, 'GET', null);
      if (response) {
        methods.reset(response?.data?.company);
        if (response?.data?.company === null) {
          setValues({
            ...values,
            company: response?.data?.company,
            loading: false,
            industries: response?.data?.industries,
          });
        } else {
          setValues({
            ...values,
            company: response?.data?.company,
            loading: false,
            industries: response?.data?.company?.industries,
          });
        }
      }
    } catch (error) {
      setValues({ ...values, loading: false });
    }
  };

  const transformedData = values.industries.map((item: any) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    getUserDetails(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    methods.setValue<'industry_id'>('industry_id', selectedIndustry.value);
  }, [selectedIndustry, methods.setValue]);

  const industryBottomSheetRef = useRef<Modalize>(null);

  const handleIndustryModalPress = useCallback(() => {
    industryBottomSheetRef.current?.open();
  }, []);

  const handleBackIndustryModalPress = useCallback(() => {
    industryBottomSheetRef.current?.close();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.headerSettings}>
        <TouchableOpacity style={styles.goBack} onPress={() => navigation.goBack()}>
          <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
          <Text style={styles.goBackText}>Company Details</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={methods.handleSubmit(onSubmit)} disabled={isDisabled}>
          <Text style={[styles.editText, isDisabled && styles.editTextDisabled]}>{t('AUTH.SAVE')}</Text>
        </TouchableOpacity>
      </View>
      <KeyboardAwareScrollView
        style={styles.keyboardAvoidStyleContainer}
        scrollEnabled={true}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps={'handled'}
      >
        <PageTitle title={'AUTH.COMPANY_INFORMATIONS'} />

        <FormProvider {...methods}>
          <TextInput
            name="name"
            label={'AUTH.COMPANY_NAME'}
            placeholder={t('AUTH.COMPANY_NAME')}
            placeholderTextColor={Colors.light.placeholder}
            style={[styles.inputStyle]}
            required
          />

          {methods.formState.errors.name && (
            <TextInputValidation>{t(methods?.formState?.errors?.name?.message)}</TextInputValidation>
          )}
          <View>
            <LabelPickerSelect required>{t('AUTH.INDUSTRY')}</LabelPickerSelect>
            <View>
              <Controller
                control={methods.control}
                name="industry_id"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <View style={styles.viewPicker}>
                    <TouchableOpacity
                      onPress={handleIndustryModalPress}
                      style={[
                        styles.inputStyle,
                        {
                          display: 'flex',
                          justifyContent: 'center',
                        },
                      ]}
                    >
                      <Text>{selectedIndustry.label}</Text>
                      <Feather name="chevron-down" size={20} style={styles.arrowSelect} />
                    </TouchableOpacity>

                    <TextInput
                      name="industry_id"
                      // label={t('AUTH.SHOP_NAME')}
                      style={[styles.inputStyle, { display: 'none' }]}
                      required
                      editable={false}
                      defaultValue={selectedIndustry.value}
                      onChangeText={onChange}
                    />
                  </View>
                )}
              />

              <View style={styles.industryMessage}>
                {methods?.formState?.errors?.industry_id && (
                  <TextInputValidation>{t(methods?.formState?.errors?.industry_id?.message)}</TextInputValidation>
                )}
              </View>
            </View>
          </View>
        </FormProvider>

        <SelectIndustryBottomSheetModal
          bottomSheetRef={industryBottomSheetRef}
          goBackBtn={handleBackIndustryModalPress}
          transformedData={transformedData}
        />
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 60,
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  tableBorder: {
    borderWidth: 1,
    borderColor: 'red',
  },
  main: {
    paddingTop: 30,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 14,
    paddingTop: 16,
    color: Colors.light.textLight,
    lineHeight: 18,
    width: Dimensions.get('window').width / 1.2,
  },
  inputStyle: {
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputStyleDisabled: {
    backgroundColor: Colors.light.disabled,
  },
  shortTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginTop: 24,
  },
  industryMessage: {
    paddingTop: 15,
  },
  cityAndZip: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  city: {
    width: '65%',
  },
  zip: {
    width: '32%',
    marginRight: '3%',
  },
  headerSettings: {
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  companyText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    alignItems: 'center',
  },
  cancel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cancelText: {
    fontSize: 16,
    color: Colors.light.red,
    fontWeight: '500',
  },
  editText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editTextDisabled: {
    color: Colors.light.editTextDisabled,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  informationContainer: {
    display: 'flex',
    paddingTop: 40,
    paddingBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  companyStyle: {
    color: 'black',
    fontSize: 12,
    fontWeight: '600',
  },
  editStyle: {
    color: 'blue',
    fontSize: 15,
    fontWeight: '300',
  },
  containerInformation: {
    width: '100%',
    paddingHorizontal: 10,
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: Colors.light.gray,
  },
  secondText: {
    fontSize: 14,
    color: Colors.light.textLight,
    textAlign: 'center',
    alignItems: 'center',
  },
  textSecond: {
    fontSize: 14,
    color: Colors.light.textLight,
    textAlign: 'center',
    alignItems: 'center',
  },
  textField: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  textFieldSecond: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  industryText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    alignItems: 'center',
  },
  viewPicker: {
    marginBottom: 16,
  },
  labelStyle: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
});
