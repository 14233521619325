import React, { useState, useRef } from 'react';
import { SafeAreaView, Text, StyleSheet, View } from 'react-native';
import ColorBottomSheetModal from '../components/settings/color/ColorBottomSheetModal';
import { Modalize } from 'react-native-modalize';
import { TouchableOpacity } from 'react-native';

type IColorValue = {
  shopitem: any;
};

export const ColorPickerSelect = ({ shopitem }: IColorValue) => {
  const colorSheetRef = useRef<Modalize>(null);
  const shopcolor = shopitem?.shop?.color;
  const [value, setValue] = useState<string>(shopcolor);

  const onOpenColor = async () => {
    colorSheetRef.current?.open();
  };

  const handleColorChange = (color: string) => {
    setValue(color);
  };

  return (
    <SafeAreaView>
      <TouchableOpacity onPress={onOpenColor}>
        <View style={styles.inputStyle}>
          <View style={styles.iconText}>
            <View style={[styles.avatar, { backgroundColor: value }]}></View>
            <Text style={[styles.itemText]}>{value}</Text>
          </View>
          <ColorBottomSheetModal shopitem={shopitem} bottomSheetRef={colorSheetRef} onColorChange={handleColorChange} />
        </View>
      </TouchableOpacity>
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  sectionContainer: {
    marginTop: 70,
    paddingHorizontal: 24,
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuIcon: {
    backgroundColor: '#F0F0F5',
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginRight: 12,
  },
  itemText: {
    fontSize: 16,
    fontWeight: '500',
  },
  inputStyle: {
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#ECECEC',
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  avatarText: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
});
