import React, { memo, useRef, useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import { useDashboardStore } from '../../store/dashboard.state';
import { intervals , graphViews} from '../../utils/dashboard';
import { useConfigStore } from '../../store/config.state';
import { Modalize } from 'react-native-modalize';
import IntervalTimepickerModal from '../bottom-sheet-modal/intervalTimepicker/intervalTimepickerModal';
import { Calendar } from '../../assets/images/index';


const Intervals = () => {
  const [selectedTab, setSelectedTab] = useState('1h');
  const { setDateInterval, graphInterval, setGraphInterval, graphView, setGraphView }: any = useDashboardStore();
  const { currentLanguage }: any = useConfigStore();
  // Variables to disable graph filters
  const [disableHour, setDisableHour] = useState(false);
  const [disableDays, setDisableDays] = useState(false);
  const [disableWeeks, setDisableWeeks] = useState(false);
  const [disableMonths, setDisableMonths] = useState(false);

  const timepickerSheetRef = useRef<Modalize>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setDateInterval({ startDate: new Date(), endDate: new Date() });
  }, []);

  const dataFormat = (date: any) => {
    if (date) {
      const year = new Date(date).getFullYear();
      const month = String(new Date(date).getMonth() + 1).padStart(2, '0');
      const day = String(new Date(date).getDate()).padStart(2, '0');

      switch (currentLanguage) {
        case 'de':
          return `${day}.${month}.${year}`;
        default:
          return `${day}/${month}/${year}`;
      }
    }
  };

  return (
    <>
      <View style={styles.main}>
        <View style={styles.filter}>
          <View style={[styles.filter, styles.filterhdwm]}>
            <TouchableOpacity
              key={intervals[0]}
              style={[styles.filterItem, graphInterval === intervals[0] && styles.filterItemActive, disableHour && { opacity: 0.3 }]}
              onPress={() => setGraphInterval(intervals[0])}
              disabled={disableHour && true}
            >
              <Text style={[styles.filterItemText, graphInterval === intervals[0] && styles.filterItemTextActive]}>
                {t('CALENDAR.HOURS')}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              key={intervals[1]}
              style={[ styles.filterItem, graphInterval === intervals[1] && styles.filterItemActive, disableDays && { opacity: 0.3 }]}
              onPress={() => setGraphInterval(intervals[1])}
              disabled={disableDays && true}
              
            >
              <Text style={[styles.filterItemText, graphInterval === intervals[1] && styles.filterItemTextActive]}>
                {t('CALENDAR.DAYS')}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              key={intervals[2]}
              style={[styles.filterItem, graphInterval === intervals[2] && styles.filterItemActive, disableWeeks && { opacity: 0.3 }]}
              onPress={() => setGraphInterval(intervals[2])}
              disabled={disableWeeks && true}
            >
              <Text style={[styles.filterItemText, graphInterval === intervals[2] && styles.filterItemTextActive]}>
                {t('CALENDAR.WEEKS')}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              key={intervals[3]}
              style={[styles.filterItem, graphInterval === intervals[3] && styles.filterItemActive, disableMonths && { opacity: 0.3 }]}
              onPress={() => setGraphInterval(intervals[3])}
              disabled={disableMonths && true}
            >
              <Text style={[styles.filterItemText, graphInterval === intervals[3] && styles.filterItemTextActive]}>
                {t('CALENDAR.MONTHS_LABEL')}
              </Text>
            </TouchableOpacity>
        </View>
        </View>

        <View style={styles.filter}>
          <View style={styles.filter}>
            {graphViews?.map((item: string) => (
              <TouchableOpacity
                key={item}
                style={[styles.chart, graphView === item && styles.chartActive]}
                onPress={() => setGraphView(item)}
              >
                <Text style={styles.filterItemText}>
                  {item === 'line' ? (
                    <Feather
                      name="trending-up"
                      size={14}
                      color={graphView === item ? Colors.light.shark : Colors.light.hitGray}
                    />
                  ) : (
                    <Feather
                      name="bar-chart-2"
                      size={16}
                      color={graphView === item ? Colors.light.shark : Colors.light.hitGray}
                    />
                  )}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>

        <TouchableOpacity style={styles.selectDate} onPress={() => timepickerSheetRef?.current?.open()}>
          <Text style={styles.selectDateText}>
          {t('AUTH.SELECT_INTERVAL')}
          </Text>
          <Calendar />
          {/* <Feather name="calendar" size={16} color={Colors.light.shark} />           */}
        </TouchableOpacity>
      </View>
      <IntervalTimepickerModal
        bottomSheetRef={timepickerSheetRef}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setDisableHour={setDisableHour}
        setDisableDays={setDisableDays}
        setDisableWeeks={setDisableWeeks}
        setDisableMonths={setDisableMonths}
      />
    </>
  );
};

export default memo(Intervals);

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  filter: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.light.white,
    padding: 2,
    borderRadius: 12,
  },
  filterhdwm: {
    borderRadius: 12,
    height: 31,
    paddingHorizontal:4
  },
  filterItem: {
    marginRight: 4,
    width: 36,
    height: 28,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterItemActive: {
    backgroundColor: Colors.light.background,
    borderRadius: 6,
  },
  filterItemText: {
    fontSize: 13,
    padding: 6,
    color: Colors.light.hitGray,
    textTransform: 'uppercase'
  },
  filterItemTextActive: {
    fontWeight: 'bold',
    color: Colors.light.shark,
  },
  disableFilterItem: {
    color: Colors.light.background
  },
  chart: {
    borderRadius: 6,
    marginRight: 4,
    width: 36,
    height: 28,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chartActive: {
    backgroundColor: Colors.light.athensGray,
  },
  selectDate: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.light.white,
    padding: 8,
    borderRadius: 12,
    height:34,
  },
  selectDateText: {
    paddingRight: 8,
    fontSize: 13,
    fontWeight: '600'
  },
});
