import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

type State = {
  allShops: any[];
  activeShops: any[];
};

const initialState: State = {
  allShops: [],
  activeShops: [],
};

export const useShopStore = create(
  persist(
    (set) => ({
      ...initialState,
      setAllShops: (data: any) => {
        if (data && data.length) {
          const getActiveShops = data.filter((i: any) => i?.status === 1);
          set({ allShops: data, activeShops: getActiveShops });
        } else {
          set({ allShops: [], activeShops: [] });
        }
      },

      resetShops: () => {
        set(initialState);
      },
    }),
    {
      name: 'stathubs-shops-storage', // Storage Mobile App Name
      getStorage: () => AsyncStorage,
    }
  )
);
