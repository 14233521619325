import React from 'react';
import Colors from '../../constants/Colors';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { StathubsLogo } from '../../assets/images';
import { TrashLogo } from '../../assets/images/index';

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 24,
    paddingHorizontal: 24,
    borderBottomWidth: 1,
    borderBottomColor: Colors.light.athensGray,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 18,
    paddingLeft: 24,
    color: Colors.light.blueCharcoal,
  },
  closeBottomSheet: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.light.blueRibbon,
  },
  clearIntervalBottomSheet: {
    fontSize: 14,
    fontWeight: '600',
  },
  updateBtn: {
    backgroundColor: Colors.light.text,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 6,
  },
  updateIntervalBottomSheet: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.light.background,
  },
});

export const HeaderBottomSheet = (title: string, bottomSheetRef: () => void, rightText: string) => (
  <View style={styles.header}>
    <StathubsLogo width={18} height={24} />
    <Text style={styles.headerTitle}>{title || ''}</Text>
    <TouchableOpacity onPress={bottomSheetRef}>
      <Text style={styles.closeBottomSheet}>{rightText}</Text>
    </TouchableOpacity>
  </View>
);

export const HeaderEditShopBottomSheet = (
  title: string,
  bottomSheetRef: () => void,
  rightText: string,
  bottomSheetRefClear: () => void,
  leftText: string
) => (
  <View style={styles.header}>
    <TouchableOpacity onPress={bottomSheetRefClear}>
      <Text style={styles.closeBottomSheet}>{leftText}</Text>
    </TouchableOpacity>
    <Text style={styles.headerTitle}>{title || ''}</Text>
    <TouchableOpacity onPress={bottomSheetRef}>
      <Text style={styles.closeBottomSheet}>{rightText}</Text>
    </TouchableOpacity>
  </View>
);

export const HeaderBottomIntervalSheet = (
  title: string,
  bottomSheetRef: () => void,
  rightText: string,
  bottomSheetRefClear: () => void,
  leftText: string,
  leftIcon: boolean
) => (
  <View style={styles.header}>
    <TouchableOpacity onPress={bottomSheetRefClear}>
      {!leftIcon ? <Text style={styles.clearIntervalBottomSheet}>{leftText}</Text> : <TrashLogo />}
    </TouchableOpacity>
    <Text style={styles.headerTitle}>{title || ''}</Text>
    <TouchableOpacity onPress={bottomSheetRef} style={styles.updateBtn}>
      <Text style={styles.updateIntervalBottomSheet}>{rightText}</Text>
    </TouchableOpacity>
  </View>
);
