import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';
import { usei18n } from './usei18n';
import { registerForPushNotificationsAsync } from '../utils/registerForPushNotificationAsync';

export default function useCachedResources() {
  const { initializei18n } = usei18n();
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();
        //Initialize i18 passed
        await initializei18n();
        //Push notification
        await registerForPushNotificationsAsync();
        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoadingComplete;
}
