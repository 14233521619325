import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// Screens
import AuthStack from './authStack';
import UserStack from './userStack';
import { useAuthStore } from '../store/auth.state';

const Stack = createStackNavigator();

export default function RootStack() {
  const { isAuthenticated }: any = useAuthStore();
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName={isAuthenticated ? 'Main' : 'Auth'}>
        <Stack.Screen name="Auth" component={AuthStack} options={{ headerShown: false }} />
        <Stack.Screen name="Main" component={UserStack} options={{ headerShown: false }} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
