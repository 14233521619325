/* eslint-disable react/jsx-handler-names */
import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import {
  View,
  TextInput as RNTextInput, 
  Text, 
  StyleSheet, 
  TextInputProps as RNTextInputProps 
} from 'react-native';
import Colors from '../../constants/Colors';
import { useTranslation } from 'react-i18next';

interface TextInputProps extends RNTextInputProps, UseControllerProps {
  label: string;
  defaultValue?: string;
  required?: boolean;
}

export const ControlledInput = (props: TextInputProps) => {
  const { name, label, rules, defaultValue, ...inputProps } = props;
  const { field } = useController({ name, rules, defaultValue });

  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      {label && <Text style={styles.label}>{t(label)}{props.required && <Text style={styles.asterisk}>*</Text>}</Text>}
      <RNTextInput
        style={styles.input}
        onChangeText={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        {...inputProps}
      />
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
  },
  label: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  asterisk: {
    color: Colors.light.hitGray,
    fontSize: 14,
    lineHeight: 16,
    fontWeight: '500',
  },
  input: {
    backgroundColor: Colors.light.background,
    borderBottomWidth: 0,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 56,
  },
});