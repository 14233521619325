import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface ISpaceDefault {
  id: string;
  name: string;
  code: string;
}

type State = {
  spacePerUser: any;
  defaultSpace: ISpaceDefault;
};

const initialState: State = {
  spacePerUser: [],
  defaultSpace: { id: '', name: '', code: '' },
};

export const useSpaceStore = create(
  persist(
    (set) => ({
      ...initialState,
      addSpaces: (data: Object) => set({ spacePerUser: data }),
      setDefaultSpace: (data: ISpaceDefault) => set({ defaultSpace: data }),

      resetSpaces: () => {
        set(initialState);
      },
    }),
    {
      name: 'stathubs-spaces-storage', // Storage Mobile App Name
      getStorage: () => AsyncStorage,
    }
  )
);
