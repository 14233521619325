import React from 'react';
import Colors from '../../../constants/Colors';
import { StyleSheet, View, Text, Dimensions, TouchableOpacity } from 'react-native';
import { FormProvider, useForm } from 'react-hook-form';
import { createSpaceValidation } from '../../../components/form/Validations';
import { TextInput } from '../../../components/form/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { useTranslation } from 'react-i18next';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import LabelPickerSelect from '../../form/LabelPickerSelect';
import Button from '../../ui/action/Button';
import TextInputValidation from '../../form/TextInputValidation';

type FormValues = {
  name: string;
  platform_name: string;
  client_key: string;
};

interface IAddShopBottomSheetModal {
  bottomSheetRef: any;
  onSubmit?: (values: FormValues) => void;
}

export const AddSpaceBottomSheetModal = ({ bottomSheetRef, onSubmit }: IAddShopBottomSheetModal) => {
  const { t } = useTranslation();
  const { ...methods } = useForm({
    resolver: yupResolver(createSpaceValidation),
    mode: 'all',
  });

  const backBtn = () => {
    bottomSheetRef.current?.close();
  };

  const renderContent = () => (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={false}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.main}>
        <FormProvider {...methods}>
          <View>
            <LabelPickerSelect required>{t('AUTH.SPACE_NAME')}</LabelPickerSelect>
            <View style={styles.inputWithDomain}>
              <TextInput
                name="space_name"
                placeholder={t('AUTH.ENTER_YOUR_SPACE_NAME')}
                autoCapitalize="none"
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
              />
              <View style={styles.inputStyleDomain}>
                <Text>.stathubs.com</Text>
              </View>
            </View>
            <View>
              {methods?.formState?.errors?.space_name && (
                <TextInputValidation>{t(methods?.formState?.errors?.space_name?.message)}</TextInputValidation>
              )}
            </View>
          </View>
        </FormProvider>
      </View>
    </KeyboardAwareScrollView>
  );

  const renderFooter = () => (
    <View style={styles.bottom}>
      <Button 
        label={'AUTH.CREATE_SPACE'} 
        onPress={methods.handleSubmit(onSubmit)} 
        bg={"dark"}
      />
    </View>
  );

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.ADD_NEW_SPACE')}`, backBtn, `${t('AUTH.CANCEL')}`)}
        FooterComponent={renderFooter()}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};


const styles = StyleSheet.create({
  main: {
    paddingTop: 24,
    paddingHorizontal: 16,
  },
  keyboardAvoidStyleContainer: {
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  inputWithDomain: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderTopStartRadius: 8,
    borderBottomStartRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: Dimensions.get('window').width / 1.7,
  },
  inputStyleDomain: {
    backgroundColor: Colors.light.mystic,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderBottomEndRadius: 8,
    borderTopEndRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: 140,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    marginBottom: 60,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
});