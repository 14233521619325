import React, { memo } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { TextInput } from './TextInput';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';

const PasswordTextInput = ({ ...props }: any) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <View style={styles.inputContainer}>
      <TextInput {...props} secureTextEntry={!showPassword} />
      <TouchableOpacity style={styles.btn} onPress={() => setShowPassword((prev) => !prev)}>
        <Ionicons style={styles.icon} name={showPassword ? 'eye-off' : 'eye'} size={24} color="black" />
      </TouchableOpacity>
    </View>
  );
};

export default memo(PasswordTextInput);

const styles = StyleSheet.create({
  inputContainer: {
    // alignItems: 'center',
  },
  btn: {
    position: 'absolute',
    right: 16,
    top: '50%',
    transform: [{ translateY: -9 }],
  },
  icon: {
    width: 25,
    height: 22,
    color: Colors.light.hitGray,
  },
});
