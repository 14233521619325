import React, { memo, useState } from 'react';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Colors from '../../../constants/Colors';
import Header from '../../../components/ui/Header';
import { StackScreenProps } from '@react-navigation/stack';
import JoinSpaceScreen from './JoinSpaceScreen';
import CreateSpaceScreen from './CreateSpaceScreen';
import { useTranslation } from 'react-i18next';

const SpaceScreen: React.FC<StackScreenProps<any>> = () => {
  const [values, setValues] = useState({
    isJoinHere: false,
  });

  const { t } = useTranslation();

  const onSwapView = () => {
    setValues({ ...values, isJoinHere: !values.isJoinHere });
  };

  return (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={true}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.container}>
        <Header screenName="Spaces" />
        <View style={styles.main}>
          <View style={styles.content}>
            <Text style={styles.title}>{t('AUTH.PLEASE_ENTER_NAME_OF_YOUR_SPACE')}.</Text>
            {/* <Text style={styles.subTitle}>This will be the name of your space</Text> */}
          </View>

          {!values?.isJoinHere ? <CreateSpaceScreen /> : <JoinSpaceScreen />}

          {values.isJoinHere ? (
            <View style={styles.bottomFooter}>
              <Text style={styles.bottomFooterText}>{t('AUTH.DONT_HAVE_SPACE')}</Text>
              <TouchableOpacity onPress={onSwapView}>
                <Text style={styles.labelBottom}>{t('AUTH.CREATE_HERE')}</Text>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.bottomFooter}>
              <Text style={styles.bottomFooterText}>{t('AUTH.GOT_INVITATION')}</Text>
              <TouchableOpacity onPress={onSwapView}>
                <Text style={styles.labelBottom}>{t('AUTH.JOIN_HERE')}</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
export default memo(SpaceScreen);

const styles = StyleSheet.create({
  keyboardAvoidStyleContainer: {
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.background,
  },
  main: {
    width: '100%',
  },
  content: {
    paddingTop: 32,
    paddingBottom: 30,
  },
  title: {
    fontSize: 24,
    lineHeight: 28,
    fontWeight: '400',
    width: Dimensions.get('window').width / 1.6,
  },
  subTitle: {
    fontSize: 14,
    paddingTop: 12,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  bottomFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 24,
  },
  bottomFooterText: {
    fontSize: 14,
  },
  labelBottom: {
    paddingHorizontal: 3,
    fontSize: 14,
    fontWeight: '600',
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingTop: 6,
  },
});
