import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { useDashboardStore } from '../../../../../store/dashboard.state';
import DashboardStackedBarChart from '../../StackedBarChart';

const DashboardBarChartMonthly = () => {
  const { t } = useTranslation();
  const { mergeSales }: any = useDashboardStore();

  const dataSets = mergeSales[0]?.totalWeek
    ? mergeSales.map((sale: any) => {
        if (Array.isArray(sale?.totalWeek)) {
          return sale.totalWeek;
        }
      })
    : [];

  const data = {
    labels: mergeSales.length > 0 ? mergeSales[0].totalWeekLabel : [t('CALENDAR.LOADING')],
    data: dataSets,
  };

  const chartConfig = {
    width:
      data.labels?.length >= 10
        ? Dimensions.get('window').width * (data.labels?.length / 10)
        : Dimensions.get('window').width / 1.15,
    spacingInner: 0.5,
    marginLeft: 10,
    marginRight: 0,
    linePos: 11,
  };

  return <DashboardStackedBarChart data={data} chartConfig={chartConfig} />;
};

export default memo(DashboardBarChartMonthly);
