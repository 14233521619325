import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import Colors from '../../constants/Colors';
import Header from '../../components/ui/Header';
import { resetPasswordFieldsValidation } from '../../components/form/Validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTranslation } from 'react-i18next';
import PasswordTextInput from '../../components/form/PasswordTextInput';
import TextInputValidation from '../../components/form/TextInputValidation';
import LanguageSwitcher from '../../components/ui/LanguageSwitcher';

type FormValues = {
  new_password: string;
  confirm_password: string;
};

const UpdatePassword: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    loading: false,
    hasError: '',
  });
  const { ...methods } = useForm({
    resolver: yupResolver(resetPasswordFieldsValidation),
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FormValues> = async () => {
    setValues({ ...values });
    navigation.navigate('SignIn');
  };

  return (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={true}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <Header screenName="Auth" />
          <LanguageSwitcher />
        </View>

        <View style={styles.main}>
          <View style={styles.content}>
            <Text style={styles.title}>{t('AUTH.UPDATE_YOUR_PASSWORD')}</Text>
            <Text style={styles.description}>{t('AUTH.ENTER_NEW_PASSWORD_PLEASE')}</Text>
          </View>

          <View style={styles.boxContainer}>
            {!!values.hasError && (
              <View>
                <Text style={styles.errorText}>{values?.hasError}</Text>
              </View>
            )}

            <FormProvider {...methods}>
              <PasswordTextInput
                name="new_password"
                label={t('AUTH.NEW_PASSWORD')}
                placeholder={t('AUTH.NEW_PASSWORD')}
                autoCapitalize="none"
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
                required
              />
              {methods?.formState?.errors?.new_password && (
                <TextInputValidation>{t(methods?.formState?.errors?.new_password?.message)}</TextInputValidation>
              )}

              <PasswordTextInput
                name="confirm_password"
                label={t('AUTH.PASSWORD_CONFIRMATION')}
                placeholder={t('AUTH.PASSWORD_CONFIRMATION')}
                autoCapitalize="none"
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
                required
              />
              {methods?.formState?.errors?.confirm_password && (
                <TextInputValidation>{t(methods?.formState?.errors?.confirm_password?.message)}</TextInputValidation>
              )}
            </FormProvider>

            <View style={styles.bottom}>
              <TouchableOpacity
                disabled={values.loading}
                onPress={methods.handleSubmit(onSubmit)}
                style={[styles.button, values.loading && styles.onActiveButton]}
              >
                <Text style={styles.buttonText}>{t('AUTH.UPDATE_PASSWORD')}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

export default UpdatePassword;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: Colors.light.background,
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    width: '100%',
  },
  content: {
    paddingVertical: 32,
  },
  title: {
    fontSize: 24,
    lineHeight: 28,
    fontWeight: '400',
    width: Dimensions.get('window').width / 1.5,
  },
  description: {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: '400',
    marginTop: 12,
    width: Dimensions.get('window').width / 1.3,
  },
  errorText: {
    textAlign: 'center',
    color: Colors.light.red,
  },
  boxContainer: {
    paddingTop: 14,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
});