import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Platform } from 'react-native';
import Colors from '../../../constants/Colors';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import hasNotch from '../../../utils/isNotch';
import { useNavigation } from '@react-navigation/native';
import { useAuthStore } from '../../../store/auth.state';
import AccountPersonalInformation from './AccountPersonalInformation';
import AccountSecurity from './AccountSecurity';

export const AccountSettings = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const { userData }: any = useAuthStore();

  const onGoBack = () => {
    navigation.goBack();
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerSettings}>
        <TouchableOpacity style={styles.goBack} onPress={onGoBack}>
          <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
          <Text style={styles.goBackText}>{t('AUTH.MAIN_MENU')}</Text>
        </TouchableOpacity>
      </View>

      <View>
        <View style={styles.details}>
          <View>
            <Text style={styles.title}>{t('AUTH.ACCOUNT_SETTINGS')}</Text>
            <Text style={styles.description}>{t('AUTH.PERSONALIZE_YOUR_ACCOUNT')}</Text>
          </View>
          <View style={styles.avatar}>
            <Text style={styles.avatarText}>
              {userData?.user?.first_name?.toUpperCase().slice(0, 1)}
              {userData?.user?.last_name?.toUpperCase().slice(0, 1)}
            </Text>
          </View>
        </View>
      </View>

      <AccountPersonalInformation />

      <AccountSecurity />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: Colors.light.background,
  },
  headerSettings: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
    marginBottom: 8,
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  details: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: Colors.light.riptide,
    borderRadius: 8,
    marginLeft: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarText: {
    fontSize: 30,
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 19,
    marginBottom: 4,
  },
  description: {
    fontSize: 14,
    color: Colors.light.textLight,
  },
});
