import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import DashboardLineChartDaily from './charts/daily/LineChart';
import DashboardLineChartWeekly from './charts/weekly/LineChart';
import DashboardLineChartMonthly from './charts/monthly/LineChart';
import DashboardLineChartYearly from './charts/yearly/LineChart';
import DashboardBarChartDaily from './charts/daily/BarChart';
import DashboardBarChartWeekly from './charts/weekly/BarChart';
import DashboardBarChartMonthly from './charts/monthly/BarChart';
import DashboardBarChartYearly from './charts/yearly/BarChart';
import { useDashboardStore } from '../../store/dashboard.state';
import { graphViews, intervals } from '../../utils/dashboard';
import { useConfigStore } from '../../store/config.state';
import { formatCurrency } from '../../utils/formatCurrency';
import Colors from '../../constants/Colors';
import { t } from 'i18next';

export const Chart = () => {
  const { graphInterval, graphView, dateInterval, turnover }: any = useDashboardStore();
  const { currentLanguage }: any = useConfigStore();
  const { activeShop }: any = useDashboardStore();

  const dataFormat = (date: any) => {
    if (date) {
      const year = new Date(date).getFullYear();
      const month = String(new Date(date).getMonth() + 1).padStart(2, '0');
      const day = String(new Date(date).getDate()).padStart(2, '0');

      switch (currentLanguage) {
        case 'de':
          return `${day}.${month}.${year}`;
        default:
          return `${day}/${month}/${year}`;
      }
    }
  };

  let dashboardLine;
  let dashboardBar;

  switch (graphInterval) {
    case intervals[0]:
      dashboardLine = <DashboardLineChartDaily />;
      dashboardBar = <DashboardBarChartDaily />;
      break;
    case intervals[1]:
      dashboardLine = <DashboardLineChartWeekly />;
      dashboardBar = <DashboardBarChartWeekly />;
      break;
    case intervals[2]:
      dashboardLine = <DashboardLineChartMonthly />;
      dashboardBar = <DashboardBarChartMonthly />;
      break;
    case intervals[3]:
      dashboardLine = <DashboardLineChartYearly />;
      dashboardBar = <DashboardBarChartYearly />;
      break;
    default:
      dashboardLine;
      dashboardBar;
      break;
  }

  return (
    <View style={styles.container}>
      <View style={styles.chartHeader}>
        <Text style={styles.chartHeaderText}>{t('AUTH.TOTAL_TURNOVER')}</Text>
        <Text style={styles.chartHeaderText}>
          {!dateInterval.startDate
            ? t('AUTH.SELECT_INTERVAL')
            : dataFormat(dateInterval.startDate) + ' - ' + dataFormat(dateInterval.endDate)}
        </Text>
      </View>
      <Text style={styles.total}>{formatCurrency(turnover?.number, activeShop.shopCurrency, currentLanguage)}</Text>
      {graphView === graphViews[0] ? dashboardLine : dashboardBar}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    padding: 12,
  },
  chartHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chartHeaderText: {
    fontSize: 10,
    fontWeight: '600',
    marginBottom: 4,
    color: Colors.light.grayLight,
  },
  total: {
    fontSize: 24,
    fontWeight: '600',
    marginBottom: 12,
  },
});
