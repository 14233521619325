import { Portal } from '@gorhom/portal';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, FlatList, StyleSheet, Switch, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Modalize } from 'react-native-modalize';
import Toast from 'react-native-toast-message';
import api from '../../../config/api';
import Colors from '../../../constants/Colors';
import { useDashboard } from '../../../hooks/useDashboard';
import { ColorPickerSelect } from '../../../utils/ColorPicker';
import { HeaderEditShopBottomSheet } from '../../ui/HeaderBottomSheet';

interface IShopDetailsBottomSheetModal {
  bottomSheetRef: any;
  id: string;
  name: string;
  status: any;
  getAllShops: () => void;
  onActiveDisableShop: (id: string, status: boolean) => void;
  orderStatusShops: any;
  paymentStatusShops: any;
  shopDetails: any;
  configureShopActives: (ids: string, status: boolean) => void;
  paymentStatusFunction: (ids: string, status: boolean) => void;
}

export const ShopDetailsBottomSheetModal = ({
  bottomSheetRef,
  id,
  getAllShops,
  orderStatusShops,
  paymentStatusShops,
  shopDetails,
  configureShopActives,
  paymentStatusFunction,
}: IShopDetailsBottomSheetModal) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { fetchDashboard }: any = useDashboard();
  const [newShopName, setNewShopName] = useState(shopDetails?.shop?.name);
  const [newShopURL, setNewShopURL] = useState(shopDetails?.shop?.shop_url);
  // const [newShopPlatform, setNewShopPlatform] = useState(shopDetails?.shop?.platform_type);
  // const [newShopClientKey, setNewShopClientKey] = useState(shopDetails?.shop?.client_key);
  const [newShopSecretKey, setNewShopSecretKey] = useState(shopDetails?.shop?.secret_key);
  const [shopSystem, setShopSystem] = useState('');

  useEffect(() => {
    setNewShopName(shopDetails?.shop?.name);
    setNewShopURL(shopDetails?.shop?.shop_url);
    // setNewShopPlatform(shopDetails?.shop?.platform_type);
    // setNewShopClientKey(shopDetails?.shop?.client_key);
    setNewShopSecretKey(shopDetails?.shop?.secret_key);

    switch (shopDetails?.shop?.platform_type) {
      case 0:
        setShopSystem('Shopware 6');
        break;
      case 1:
        setShopSystem('Shopware 5');
        break;
      case 2:
        setShopSystem('Shopify');
        break;
      case 3:
        setShopSystem('WooCommerce');
        break;
      case 4:
        setShopSystem('Magento');
        break;
      case 5:
        setShopSystem('Oxid');
        break;
      case 6:
        setShopSystem('Ebay');
        break;
      case 7:
        setShopSystem('Amazon');
        break;
    }
  }, [shopDetails]);

  const backBtn = () => {
    const body = {
      name: newShopName,
      // platform_type: newShopPlatform,
      // client_key: newShopClientKey,
      secret_key: newShopSecretKey,
      shop_url: newShopURL,
    };

    updateShop(body);
    bottomSheetRef.current?.close();
  };

  const closeBtn = () => {
    bottomSheetRef.current?.close();
  };

  const deleteShop = async (shopId: string) => {
    Alert.alert(`${t('AUTH.DELETE')} ${shopDetails?.shop?.name}`, `${t('AUTH.ARE_YOU_SURE_DELETE')} ${t('USER')}`, [
      {
        text: 'Delete',
        onPress: async () => {
          try {
            await api('/shop/delete/' + shopId, 'DELETE');
            getAllShops();
            navigation.navigate('Shops');
            fetchDashboard();

            bottomSheetRef?.current?.close();
            Toast.show({
              type: 'success',
              text1: `${t('AUTH.SHOP_DETAILS')}`,
              text2: `${t('AUTH.YOUR_SHOP_HAS_BEEN_DELETED')}`,
            });
          } catch (error) {
            bottomSheetRef?.current?.close();
            Toast.show({
              type: 'error',
              text1: `${t('AUTH.SHOP_DETAILS')}`,
              text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
            });
          }
        },
        style: 'destructive',
      },
      {
        text: 'Cancel',
      },
    ]);
  };

  const renderItem = ({ item }: any) => (
    <>
      <View style={styles.viewOrderStatus}>
        <View style={styles.viewOrders}>
          <View>
            <Text style={styles.statusText}>{item?.order_status?.toUpperCase().replace(/_/g, ' ')}</Text>
          </View>
          <Switch
            trackColor={{ false: Colors.light.trackColorFalse, true: Colors.light.blueRibbon }}
            thumbColor={item?.status ? Colors.light.background : Colors.light.background}
            ios_backgroundColor={Colors.light.athensGray}
            value={item?.status === 1}
            onValueChange={(value: any) => configureShopActives(item?.id, value)}
            style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
          />
        </View>
      </View>
    </>
  );

  const renderItemPayment = ({ item }: any) => (
    <>
      <View style={styles.viewOrderStatus}>
        <View style={styles.viewOrders}>
          <View>
            <Text style={styles.statusText}>{item?.payment_status?.toUpperCase().replace(/_/g, ' ')}</Text>
          </View>
          <Switch
            trackColor={{ false: Colors.light.trackColorFalse, true: Colors.light.blueRibbon }}
            thumbColor={item?.status ? Colors.light.background : Colors.light.background}
            ios_backgroundColor={Colors.light.athensGray}
            value={item?.status === 1}
            onValueChange={(value: any) => paymentStatusFunction(item?.id, value)}
            style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
          />
        </View>
      </View>
    </>
  );

  const updateShop = async (data: any) => {
    try {
      await api('/shop/update/' + id, 'POST', data);
      Toast.show({
        type: 'success',
        text1: 'Shop name updated successfully',
        visibilityTime: 1000,
      });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SHOP_DETAILS')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    }
  };

  const renderContent = () => (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={true}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.body}>
        <Text style={styles.label}>{t('AUTH.SHOP_NAME')}</Text>
        <TextInput
          placeholder={t('AUTH.FIRST_NAME')}
          defaultValue={shopDetails?.shop?.name}
          placeholderTextColor={Colors.light.placeholder}
          style={styles.inputStyle}
          onChangeText={(text) => setNewShopName(text)}
        />
        <Text style={styles.label}>{t('AUTH.CURRENCY')}</Text>
        <TextInput
          editable={false}
          defaultValue={shopDetails?.shop?.currency_iso_code ? shopDetails.shop.currency_iso_code : 'EUR'}
          placeholderTextColor={Colors.light.placeholder}
          style={styles.inputStyle}
        />

        <Text style={styles.label}>{t('AUTH.SELECT_COLOR')}</Text>
        <ColorPickerSelect shopitem={shopDetails} />

        <Text style={styles.label}>{t('AUTH.SHOP_URL')}</Text>
        <TextInput
          placeholder={t('AUTH.SHOP_URL')}
          defaultValue={shopDetails?.shop?.shop_url}
          placeholderTextColor={Colors.light.placeholder}
          style={styles.inputStyle}
          onChangeText={(text) => setNewShopURL(text)}
          // editable={false}
        />

        <Text style={styles.label}>{t('AUTH.SHOP_SYS')}</Text>
        <TextInput
          placeholder={t('AUTH.SHOP_SYS')}
          defaultValue={shopSystem}
          placeholderTextColor={Colors.light.placeholder}
          style={[styles.inputStyle, styles.inputStyleDisabled]}
          // onChangeText={(text) => setNewShopPlatform(text)}
          editable={false}
        />

        <Text style={styles.label}>{t('AUTH.CLIENT_KEY')}</Text>
        <TextInput
          placeholder={t('AUTH.CLIENT_KEY')}
          defaultValue={shopDetails?.shop?.client_key}
          // secureTextEntry
          placeholderTextColor={Colors.light.placeholder}
          style={[styles.inputStyle, styles.inputStyleDisabled]}
          // onChangeText={(text) => setNewShopClientKey(text)}
          editable={false}
        />

        <Text style={styles.label}>{t('AUTH.SECRET_KEY')}</Text>
        <TextInput
          placeholder={t('AUTH.SECRET_KEY')}
          defaultValue={shopDetails?.shop?.secret_key}
          // secureTextEntry
          placeholderTextColor={Colors.light.placeholder}
          style={styles.inputStyle}
          onChangeText={(text) => setNewShopSecretKey(text)}
          // editable={false}
        />

        <Text style={styles.label1}>{t('AUTH.ORDER_STATUS')}</Text>
        <FlatList data={orderStatusShops} renderItem={renderItem} keyExtractor={(item, index): any => index} />

        <Text style={styles.label1}>{t('AUTH.PAYMENT_STATUS')}</Text>
        <FlatList data={paymentStatusShops} renderItem={renderItemPayment} keyExtractor={(item, index): any => index} />

        <View style={styles.bottom}>
          <TouchableOpacity style={styles.delete} onPress={() => deleteShop(id)}>
            <Text style={styles.deleteText}>{t('AUTH.DELETE_SHOP')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight={false}
        HeaderComponent={HeaderEditShopBottomSheet(
          `${t('AUTH.CONFIGURE_SHOP')}`,
          backBtn,
          `${t('AUTH.SAVE')} `,
          closeBtn,
          `${t('AUTH.CLOSE')} `
        )}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.white,
    flexGrow: 1,
  },
  body: {
    padding: 24,
    backgroundColor: 'white',
  },
  label: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
    marginTop: 8,
  },
  label1: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '600',
    marginTop: 8,
  },
  rightSide: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputStyle: {
    backgroundColor: Colors.light.white,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusText: {
    fontWeight: '500',
    textDecorationLine: 'none',
  },
  inputesOrder: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 100,
    height: 54,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputStyleDisabled: {
    backgroundColor: Colors.light.disabled,
  },
  orderStatus: {
    marginTop: 4,
  },
  orderStatusTitle: {
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    paddingBottom: 8,
  },
  orderItem: {
    backgroundColor: Colors.light.gray,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 14,
    paddingHorizontal: 16,
    marginBottom: 6,
  },
  orderItemTitle: {
    fontSize: 16,
    fontWeight: '500',
  },
  bottom: {
    backgroundColor: Colors.light.gray,
    borderRadius: 8,
    marginVertical: 50,
  },
  delete: {
    padding: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  deleteText: {
    fontSize: 16,
    color: Colors.light.bittersweet,
    fontWeight: '500',
  },
  viewOrderStatus: {
    backgroundColor: '#F7F7F8',
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    justifyContent: 'center',
    marginBottom: 5,
  },
  viewOrders: { flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' },
});
