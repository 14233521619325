import React, { memo } from 'react';
import Intervals from './Intervals';
import ChartNavs from './ChartNavs';
import { View, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';

const ChartActions = () => {
  return (
    <>
      <View style={styles.container}>
        <ChartNavs />
      </View>
      <Intervals />
    </>
  );
};

export default memo(ChartActions);

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    paddingBottom: 12,
  },
});
