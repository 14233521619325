import React, { useState } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { useController } from 'react-hook-form';

const Checkbox = ({ control, name }: any) => {
    const [checked, setChecked] = useState(false);

    const { field: { value, onChange }, } = useController({
        control,
        defaultValue: false,
        name,
    });

    const handleToggle = () => {
        onChange(!value)
        setChecked(!checked)
    }

    return(
        <Pressable
            style={[styles.checkboxBase, checked && styles.checkboxChecked]}
            onPress={handleToggle}>
            {checked && <Ionicons name="checkmark" size={14} color="white" />}
        </Pressable>
    );
}

export default Checkbox;


const styles = StyleSheet.create({
    checkboxBase: {
        marginRight: 8,
        width: 18,
        height: 18,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderWidth: 1,
        borderColor: Colors.light.hitGray,
        backgroundColor: 'transparent',
    },
    checkboxChecked: {
        backgroundColor: Colors.light.blueRibbon,
        borderColor: Colors.light.blueRibbon,
    },
});