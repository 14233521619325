import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Colors from '../../../constants/Colors';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import { useTranslation } from 'react-i18next';
import { useDashboardStore } from '../../../store/dashboard.state';
import { Feather, FontAwesome } from '@expo/vector-icons';

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 48,
    paddingHorizontal: 16,
  },
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  name: {
    fontWeight: '500',
    flex: 1,
  },
  image: {
    marginRight: 12,
  },
  checkbox: {
    padding: 6,
  },
});

type Shops = {
  id: string;
};

interface IShopSystemBottomSheetModal {
  bottomSheetRef: any;
  goBackBtn: () => void;
  onOpenModal?: () => void;
  shops: any;
  methods: any;
  setParentShops: any;
}

export const SelectShopInviteBottomSheetModal = ({
  bottomSheetRef,
  goBackBtn,
  shops,
  methods,
  setParentShops,
}: IShopSystemBottomSheetModal) => {
  const { t } = useTranslation();
  const [selectedShops, setSelectedShops] = useState<Shops[]>([]);

  useEffect(() => {
    methods.setValue(
      'shop_ids',
      selectedShops.map((shop) => shop.id)
    );
  }, [selectedShops]);

  const toggleShopSelection = (shop: any) => {
    const index = selectedShops.findIndex((selectedShop) => selectedShop.id === shop.id);
    if (index !== -1) {
      setSelectedShops((prevSelectedShops) => prevSelectedShops.filter((selectedShop) => selectedShop.id !== shop.id));
    } else {
      setSelectedShops((prevSelectedShops) => [...prevSelectedShops, shop]);
    }
  };

  const backBtn = () => {
    setParentShops(selectedShops);
    goBackBtn();
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.SELECT_SHOP')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        <View style={styles.container}>
          {shops.map((shop) => (
            <TouchableOpacity key={shop.id} style={styles.touchable} onPress={() => toggleShopSelection(shop)}>
              <Text style={styles.name}>{shop.name}</Text>
              <View style={styles.checkbox}>
                {selectedShops.some((selectedShop) => selectedShop.id === shop.id) ? (
                  <FontAwesome name="check-square-o" size={20} color={Colors.light.blueRibbon} />
                ) : (
                  <Feather name="square" size={20} color={Colors.light.silver} />
                )}
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </Modalize>
    </Portal>
  );
};
