import React, { memo } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';
import { useTranslation } from 'react-i18next';

type ListItemProps = {
    label: string;
    value: string;
}

const ListItem = ({ label, value }: ListItemProps) => {
    const { t } = useTranslation();
    
    return(
        <View style={styles.container}>
            <View style={{ flex: 1 }}>
                <Text style={styles.label}>{t(label)}</Text>
            </View>

            <View>
                <Text style={styles.value}>{value}</Text>
            </View>
        </View>
    );
}

export default memo(ListItem);


const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Colors.light.gray,
        padding: 14,
        borderRadius: 8,
    },
    label: {
        fontSize: 14,
        fontWeight: '500'
    },
    value: {
        fontSize: 14,
        color: Colors.light.textLight,
        fontWeight: '400'
    },
});