import Colors from 'constants/Colors';
import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 190,
  },
  logoContainer: {
    paddingBottom: 30,
  },

  image: {
    width: 420,
    height: 420,
    resizeMode: 'contain',
  },
  descriptionContainer: {
    alignItems: 'center',
  },
  descriptionText: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
    paddingHorizontal: 30,
    paddingVertical: 10,
    maxWidth: 400,
    letterSpacing: 0,
    lineHeight: 18,
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    paddingHorizontal: 50,
    paddingBottom: 10,
  },
  actionContainer: {
    bottom: 100,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 50,
    paddingTop: 30,
    paddingHorizontal: 20,
  },
});

export default styles;
