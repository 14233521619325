import React, { memo } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';
import { useTranslation } from 'react-i18next';

type PageTitleProps = {
    title: string;
    description?: string;
}

const PageTitle = ({ title, description }: PageTitleProps) => {
    const { t } = useTranslation();

    return(
        <View style={styles.container}>
            <Text style={styles.title}>{t(title)}</Text>
            {description && <Text style={styles.description}>{t(description)}.</Text>}
        </View>
    );
}

export default memo(PageTitle);

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        marginBottom: 32,
    },

    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    description: {
        color: Colors.light.textLight,
    }
})