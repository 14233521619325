import React, { memo } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { Text, View } from 'react-native';
import Colors from '../../constants/Colors';

type Props = {
  shopName?: string;
  shopIteam?: any;
};

const generateBackgroundColor = (str: string) => {
  const asciiSum = str
    .split('')
    .map((char) => char.charCodeAt(0))
    .reduce((acc, val) => acc + val, 0);

  const hexColor = (asciiSum & 0x00ffffff).toString(16).toUpperCase();
  return `#${'0'.repeat(6 - hexColor.length)}${hexColor}`;
};

const statusOrderItem = (status: string) => {
  function capitalizeFirstLetter(str: any) {
    return str
      .toLowerCase()
      .split(/[_-]/)
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  switch (status) {
    case 'authorized':
      return { style: styles.orderStatusAuthorized, label: 'Authorized' };
    case 'cancelled':
      return { style: styles.orderStatusCancelled, label: 'Cancelled' };
    case 'chargeback':
      return { style: styles.orderStatusChargeback, label: 'Chargeback' };
    case 'failed':
      return { style: styles.orderStatusFailed, label: 'Failed' };
    case 'in_progress':
      return { style: styles.orderStatusInProgress, label: 'In Progress' };
    case 'open':
      return { style: styles.orderStatusOpen, label: 'Open' };
    case 'paid':
      return { style: styles.orderStatusPaid, label: 'Paid' };
    case 'paid_partially':
      return { style: styles.orderStatusPaidPartially, label: 'Paid Partially' };
    case 'refunded':
      return { style: styles.orderStatusRefunded, label: 'Refunded' };
    case 'refunded_partially':
      return { style: styles.orderStatusRefundedPartially, label: 'Refunded Partially' };
    case 'reminded':
      return { style: styles.orderStatusReminded, label: 'Reminded' };
    case 'unconfirmed':
      return { style: styles.orderStatusUnconfirmed, label: 'Unconfirmed' };
    default:
      const dynamicBackgroundColor = generateBackgroundColor(status);
      return { style: { backgroundColor: dynamicBackgroundColor }, label: capitalizeFirstLetter(status) };
  }
};

const statusOrderProgressBar = (status: string, totalValue: number, itemValue: number) => {
  const percentage = ((itemValue / totalValue) * 100).toFixed(2);
  switch (status) {
    case 'authorized':
      return { ...styles.orderStatusAuthorized, width: `${percentage}%` };
    case 'cancelled':
      return { ...styles.orderStatusCancelled, width: `${percentage}%` };
    case 'chargeback':
      return { ...styles.orderStatusChargeback, width: `${percentage}%` };
    case 'failed':
      return { ...styles.orderStatusFailed, width: `${percentage}%` };
    case 'in_progress':
      return { ...styles.orderStatusInProgress, width: `${percentage}%` };
    case 'open':
      return { ...styles.orderStatusOpen, width: `${percentage}%` };
    case 'paid':
      return { ...styles.orderStatusPaid, width: `${percentage}%` };
    case 'paid_partially':
      return { ...styles.orderStatusPaidPartially, width: `${percentage}%` };
    case 'refunded':
      return { ...styles.orderStatusRefunded, width: `${percentage}%` };
    case 'refunded_partially':
      return { ...styles.orderStatusRefundedPartially, width: `${percentage}%` };
    case 'reminded':
      return { ...styles.orderStatusReminded, width: `${percentage}%` };
    case 'unconfirmed':
      return { ...styles.orderStatusUnconfirmed, width: `${percentage}%` };
    default:
      const dynamicBackgroundColor = generateBackgroundColor(status);
      return { backgroundColor: dynamicBackgroundColor, width: `${percentage}%` };
  }
};

const OrderStatus = ({ shopName, shopIteam }: Props) => {
  const renderItem = ({ item }: any) => {
    const { style, label } = statusOrderItem(item.payment_status) || { style: {}, label: '' };

    return (
      <View style={styles.orderStatusItem}>
        <View style={[styles.statusDot, style]}></View>
        <View>
          <Text style={styles.statusText}>{label}</Text>
          <Text style={styles.statusValue}>{item.totalOrders}</Text>
        </View>
      </View>
    );
  };

  const calculateTotalOrders = (paymentStatuses: any[]) => {
    return paymentStatuses.reduce((total, statusObj) => total + statusObj.totalOrders, 0);
  };

  return (
    <>
      <View style={styles.container}>
        {shopIteam && Object.keys(shopIteam).length > 1 ? (
          <Text style={styles.shopName}>{shopName}</Text>
        ) : (
          <Text style={styles.shopName}>Payment Status</Text>
        )}
        <View style={styles.progressBar}>
          {shopIteam[shopName].paymentStatuses?.map((statusObj: any, index: number) => {
            return (
              <>
                <Text
                  key={statusObj.payment_status}
                  style={statusOrderProgressBar(
                    statusObj.payment_status,
                    calculateTotalOrders(shopIteam[shopName].paymentStatuses),
                    parseInt(statusObj.totalOrders, 10)
                  )}
                ></Text>
              </>
            );
          })}
        </View>
        <FlatList
          data={shopIteam[shopName].paymentStatuses}
          renderItem={renderItem}
          keyExtractor={(item: any) => item.id}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.flatListStyle}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.white,
    borderRadius: 12,
    marginTop: 12,
  },
  progressBar: {
    marginHorizontal: 16,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 8,
    overflow: 'hidden',
    height: 8,
  },
  orderStatusItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginRight: 30,
  },
  shopName: {
    paddingHorizontal: 16,
    paddingTop: 10,
    paddingBottom: 8,
    fontSize: 10,
    fontWeight: '600',
    color: Colors.light.grayLight,

  },
  flatListStyle: {
    paddingLeft: 16,
    paddingBottom: 12,
  },
  statusDot: {
    width: 8,
    height: 8,
    borderRadius: 100,
    marginRight: 6,
  },
  statusText: {
    fontSize: 10,
    fontWeight: '600',
    color: Colors.light.hitGray,
    lineHeight: 12,
  },
  statusValue: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.light.shark,
    lineHeight: 16,
  },
  orderStatusOpen: {
    backgroundColor: Colors.payment.open,
  },
  orderStatusPaid: {
    backgroundColor: Colors.payment.paid,
  },
  orderStatusAuthorized: {
    backgroundColor: Colors.payment.authorized,
  },
  orderStatusInProgress: {
    backgroundColor: Colors.payment.inProgress,
  },
  orderStatusReminded: {
    backgroundColor: Colors.payment.reminded,
  },
  orderStatusRefunded: {
    backgroundColor: Colors.payment.refunded,
  },
  orderStatusRefundedPartially: {
    backgroundColor: Colors.payment.refundedPartially,
  },
  orderStatusCancelled: {
    backgroundColor: Colors.payment.cancelled,
  },
  orderStatusChargeback: {
    backgroundColor: Colors.payment.chargeback,
  },
  orderStatusPaidPartially: {
    backgroundColor: Colors.payment.paidPartially,
  },
  orderStatusFailed: {
    backgroundColor: Colors.payment.failed,
  },
  orderStatusUnconfirmed: {
    backgroundColor: Colors.payment.unconfirmed,
  },
});

export default memo(OrderStatus);
