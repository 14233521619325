import React, { memo } from 'react';
import { View, TouchableOpacity, StyleSheet, Text, ActivityIndicator } from 'react-native';
import { Feather } from '@expo/vector-icons';
import {
  CompanyDetails,
  InvitePeople,
  Notification,
  PeopleRoles,
  PrivacySecurity,
  Account,
  Payments,
  Help,
  About,
  Currency,
  Language,
} from '../../../assets/images/index';
import Colors from '../../../constants/Colors';
import { useTranslation } from 'react-i18next';

type MenuItemProps = {
  label: string;
  icon?: any;
  bg?: string;
  color?: 'dark' | 'red';
  disabled?: boolean;
  alert?: any;
  onPress?: () => void;
};

const MenuItem = ({ icon, label, alert, bg, color, disabled, onPress, ...props }: MenuItemProps) => {
  const { t } = useTranslation();

  const labelColor = color?.toLowerCase() === 'red' ? { color: Colors.light.red } : { color: Colors.light.text };

  const renderSvg = (icon: string) => {
    switch (icon) {
      case 'account':
        return <Account />;
      case 'invitePeople':
        return <InvitePeople />;
      case 'peopleRoles':
        return <PeopleRoles />;
      case 'companyDetails':
        return <CompanyDetails />;
      case 'notification':
        return <Notification />;
      case 'privacySecurity':
        return <PrivacySecurity />;
      case 'payments':
        return <Payments />;
      case 'help':
        return <Help />;
      case 'about':
        return <About />;
      case 'language':
        return <Language />;
      case 'currency':
        return <Currency />;
      default:
        break;
    }
  };

  return (
    <View style={styles.container} {...props}>
      <TouchableOpacity
        disabled={disabled}
        style={[styles.item, !icon && { paddingVertical: 12, paddingHorizontal: 16 }]}
        onPress={onPress}
      >
        <View style={styles.iconText}>
          {icon && !bg ? (
            <View style={styles.menuIcon}>{renderSvg(icon)}</View>
          ) : (
            bg && (
              <View style={[styles.avatar, { backgroundColor: bg }]}>
                <Text style={[styles.avatarText, labelColor]}>{label?.slice(0, 1)}</Text>
              </View>
            )
          )}

          <Text style={[styles.itemText, labelColor]}>{t(label)}</Text>
        </View>
        <View style={styles.alertNotifications}>
          {disabled && <ActivityIndicator />}
          {!!alert?.length && alert !== '0' && (
            <View
              style={[styles.alertNotification, icon === 'companyDetails' && { backgroundColor: Colors.light.red }]}
            >
              <Text style={styles.alertNotificationText}>{alert}</Text>
            </View>
          )}

          <Feather name="chevron-right" size={24} color={Colors.light.silver} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default memo(MenuItem);

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.white,
    borderRadius: 8,
  },
  item: {
    minHeight: 44,
    paddingVertical: 10,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuIcon: {
    backgroundColor: '#F0F0F5',
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginRight: 12,
  },
  alertNotifications: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertNotification: {
    marginRight: 12,
    backgroundColor: Colors.light.blueRibbon,
    paddingVertical: 5,
    paddingHorizontal: 12,
    borderRadius: 100,
  },
  alertNotificationText: {
    fontSize: 12,
    color: Colors.light.background,
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: Colors.light.riptide,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  avatarText: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
});
