import { Portal } from '@gorhom/portal';
import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Modalize } from 'react-native-modalize';
import Colors from '../../../constants/Colors';
import { TextInput } from '../../form/TextInput';
import TextInputValidation from '../../form/TextInputValidation';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import Button from '../../ui/action/Button';
import * as ImagePicker from 'expo-image-picker';
// import * as DocumentPicker from 'expo-document-picker';
// import * as FileSystem from 'expo-file-system';
import { Ionicons } from '@expo/vector-icons';
import Toast from 'react-native-toast-message';
import { Feather } from '@expo/vector-icons';

type FormValues = {
  page_id: string;
  specific_field: string;
  // screenshot: string;
  description: string;
};

interface IAddShopBottomSheetModal {
  bottomSheetRef: any;
  methods: any;
  screenshot: any;
  page: any;
  setScreenshot: any;
  onSubmit: (values: FormValues) => void;
  onOpenSelectPageModal?: () => void;
}

export const FeedbackBottomSheetModal = ({
  bottomSheetRef,
  methods,
  onSubmit,
  screenshot,
  setScreenshot,
  page,
  onOpenSelectPageModal,
}: IAddShopBottomSheetModal) => {
  const [screenshotName, setScreenshotName] = useState('');
  const { t } = useTranslation();
  const backBtn = () => {
    bottomSheetRef.current?.close();
    setScreenshot('');
    setScreenshotName('');
  };

  const pickImageAsync = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: false,
      base64: true,
      quality: 0.5,
    });

    if (!result.canceled) {
      setScreenshot(`data:image/png;base64,${result.assets[0].base64}`);
      setScreenshotName(`${result.assets[0].fileName}`);
    } else {
      setScreenshotName('');
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SCREENSHOT_NOT_SELECTED')}`,
      });
    }
  };

  const renderContent = () => (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={true}
      enableOnAndroid
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.contentContainer}>
        <FormProvider {...methods}>
          <View style={styles.viewPicker}>
            <View>
              <Text style={styles.labelStyle}>{t('AUTH.SELECT_PAGE')}*</Text>
              <TouchableOpacity
                onPress={onOpenSelectPageModal}
                style={[
                  styles.inputStyle,
                  {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                ]}
              >
                <Text>{page?.name}</Text>
                <Feather name="chevron-down" size={20} style={styles.arrowSelect} />
              </TouchableOpacity>
              {methods?.formState?.errors?.page_id && (
                <Text style={styles.inputValidation}>{methods?.formState?.errors?.page_id?.message}</Text>
              )}
            </View>
          </View>

          <TextInput
            name="page_id"
            style={[styles.inputStyle, { display: 'none', paddingBottom: 0 }]}
            required
            editable={false}
            value={page?.id}
            defaultValue={page?.id}
          />

          <TextInput
            name="specific_field"
            label={t('AUTH.SPECIFIC_FIELD')}
            placeholder={t('AUTH.SPECIFIC_FIELD_PLACEHOLDER')}
            placeholderTextColor={Colors.light.placeholder}
            style={styles.inputStyle}
            required
          />
          {methods?.formState?.errors?.specific_field && (
            <TextInputValidation>{t(methods?.formState?.errors?.specific_field?.message)}</TextInputValidation>
          )}

          <Text style={styles.labelStyle}>{t('AUTH.UPLOAD_SCREENSHOT')}</Text>
          <TouchableOpacity style={[styles.inputStyle, styles.alignUploadItems]} onPress={pickImageAsync}>
            <Ionicons
              name="cloud-upload-outline"
              size={24}
              color={Colors.light.uploadFile}
              style={{ marginRight: 12 }}
            />
            <View style={{ flex: 1 }}>
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                style={[styles.placeholder, { color: screenshotName ? Colors.light.green : undefined }]}
              >
                {screenshotName || t('AUTH.BROWSE_SCREENSHOT')}
              </Text>
            </View>
          </TouchableOpacity>

          <TextInput
            name="description"
            label={t('AUTH.DESCRIPTION')}
            placeholder={t('AUTH.DESCRIPTION_PLACEHOLDER')}
            placeholderTextColor={Colors.light.placeholder}
            style={styles.inputStyle}
            required
          />
          {methods?.formState?.errors?.description && (
            <TextInputValidation>{t(methods?.formState?.errors?.description?.message)}</TextInputValidation>
          )}

          <View style={styles.bottom}>
            <Button label={`${t('AUTH.SEND_AND_HELP')}`} onPress={methods.handleSubmit(onSubmit)} bg={'dark'} />
          </View>
        </FormProvider>
      </View>
    </KeyboardAwareScrollView>
  );

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        onOverlayPress={backBtn}
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.HELP_US_IMPROVE')}`, backBtn, `${t('AUTH.CANCEL')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  contentContainer: {
    backgroundColor: Colors.light.white,
    padding: 24,
  },
  labelPickerSelect: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  inputStyle: {
    backgroundColor: Colors.light.white,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  viewPicker: {
    marginBottom: 16,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  bottom: {
    alignItems: 'center',
    paddingTop: 24,
  },
  placeholder: {
    color: Colors.light.uploadFile,
  },
  labelStyle: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  alignUploadItems: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
});
