export const Languages = [
  {
    name: 'UK English',
    code: 'en',
  },
  {
    name: 'Deutsch',
    code: 'de',
  },
  {
    name: 'US English',
    code: 'us',
  },
];
