import React, { memo } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import Colors from '../constants/Colors';

const styles = StyleSheet.create({
  loaderContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
    opacity: 0.7,
    zIndex: 9999,
  },
});

const Loading = () => (
  <View style={[StyleSheet.absoluteFill, styles.loaderContainer]}>
    <ActivityIndicator />
  </View>
);

export default memo(Loading);
