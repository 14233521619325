import { Portal } from '@gorhom/portal';
import React, { memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';

import { FontAwesome, Feather } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';
import { useShopStore } from '../../../store/shops.state';
import { useDashboardStore } from '../../../store/dashboard.state';
import { useDashboard } from '../../../hooks/useDashboard';

// import Loading from '../../';

interface ISelectShopModal {
  bottomSheetRef: any;
}

const SelectShopBottomSheetModal = ({ bottomSheetRef }: ISelectShopModal) => {
  const { activeShops }: any = useShopStore();
  const { activeShop, setActiveShop }: any = useDashboardStore();
  const { loading, fetchDashboard }: any = useDashboard();
  const didMountRef = useRef(false);

  const { t } = useTranslation();

  const onChangeShop = async (shop: any = undefined) => {
    if (shop) {
      setActiveShop({ name: shop.name, id: shop.id, shopCurrency: shop.currency_iso_code });
     
    } else {
      setActiveShop({ name: '', id: '' , shopCurrency: ''});
    }
    bottomSheetRef.current?.close();
  };

  const backBtn = async () => {
    bottomSheetRef.current?.close();
  };

  // useEffect(() => {
  //   if (didMountRef.current) {
   
  //   } else {
  //     didMountRef.current = true;
  //   }
  // }, [activeShop]);

  const renderContent = () => {
    return (
      <> 
     
      <View style={styles.container}>
        
     
        {/* Default All Shops */}
        <TouchableOpacity style={styles.touchable} onPress={() => onChangeShop()}>
          <FontAwesome name="circle" size={20} color={'#4f41ef'} />
          <Text style={styles.name}>{t('AUTH.ALL_SHOPS')}</Text>
          <View style={styles.circle}>
            {activeShop.id === '' ? (
              <FontAwesome name="dot-circle-o" size={20} color={Colors.light.blueRibbon} />
            ) : (
              <Feather name="circle" size={20} color={Colors.light.silver} />
            )}
          </View>
        </TouchableOpacity>

        {activeShops.map((shop: any) => (
          <TouchableOpacity key={shop.id} style={styles.touchable} onPress={() => onChangeShop(shop)}>
            <View style={[styles.circle]}>
              <FontAwesome name="circle" size={20} color={shop.color} />
              {/* <Feather name="circle" size={20} color={shop.color} /> */}
            </View>
            <Text style={styles.name}>{shop.name}</Text>
            <View style={styles.circle}>
              {activeShop.id === shop.id ? (
                <FontAwesome name="dot-circle-o" size={20} color={Colors.light.blueRibbon} />
              ) : (
                <Feather name="circle" size={20} color={Colors.light.silver} />
              )}
            </View>
          </TouchableOpacity>
        ))}
      </View>
      </>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.SELECT_SHOP')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

export default memo(SelectShopBottomSheetModal);

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 40,
    paddingHorizontal: 16,
    paddingVertical: 14,
  },
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginVertical: 11,
  },
  flag: {
    width: 24,
    height: 24,
    marginEnd: 16,
  },
  name: {
    fontWeight: '500',
    flex: 1,
    marginLeft: 12,
  },
  gif: {
    width: 16,
    height: 16,
  },
  loadingText: {
    marginLeft: 5, 
    fontSize: 12
  },
  loadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: 35,
  },
  circle: {
    // backgroundColor: Colors.light.blueRibbon,
  },
});
