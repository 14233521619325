import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Dimensions, TouchableOpacity, Platform } from 'react-native';
import Colors from '../../../constants/Colors';
import hasNotch from '../../../utils/isNotch';
import { useAuthStore } from '../../../store/auth.state';
import { Feather } from '@expo/vector-icons';
import { StackScreenProps } from '@react-navigation/stack';
import Loading from '../../Loading';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../hooks/useAuthentication';
import PageTitle from '../../ui/PageTitle';
import SectionTitle from '../../ui/SectionTitle';
import CompanyInformations from './CompanyInformations';
import CompanyAddress from './CompanyAddress';
import CompanyDetails from './CompanyDetails';
import { useDashboardStore } from '../../../store/dashboard.state';

export const Company: React.FC<StackScreenProps<any>> = ({ navigation, route }) => {
  const { t } = useTranslation();
  const { userData }: any = useAuthStore();
  const { userDetails } = useAuthentication();
  const { setSelectedIndustry }: any = useDashboardStore();
  const userId = userData?.user?.id;

  useEffect(() => {
    getUserDetails(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const [values, setValues] = useState({
    loading: true,
    errors: '',
    edit: false,
    company: null,
    email: '',
    phone: '',
    country: '',
    zip: '',
    street: '',
    city: '',
    industry_id: '',
    industries: [],
    matchedObject: '',
  });

  const getUserDetails = async (id: string) => {
    if (!id) return;
    try {
      const response = await userDetails(id);
      if (response) {
        const matchedObject = response.data.company.industries.find(
          (item: any) => item.id === response.data.company.industry_id
        );

        setValues({
          ...values,
          loading: false,
          company: response?.data?.company.name,
          email: response?.data?.email,
          phone: response?.data?.company?.phone,
          country: response?.data?.company?.country,
          zip: response?.data?.company?.zip_code,
          street: response?.data?.company?.street,
          city: response?.data?.company?.city,
          industries: response?.data?.company?.industries,
          industry_id: response?.data?.company?.industry_id,
          matchedObject: matchedObject ? matchedObject.name : '',
        });

        setSelectedIndustry({ value: matchedObject.id, label: matchedObject.name });
      }
    } catch (error) {
      setValues({ ...values, loading: false });
    }
  };

  return (
    <View style={styles.container}>
      {values.loading ? (
        <Loading />
      ) : (
        <>
          <View style={styles.headerSettings}>
            <TouchableOpacity style={styles.goBack} onPress={() => navigation.goBack()}>
              <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
              <Text style={styles.goBackText}>{t('AUTH.MAIN_MENU')}</Text>
            </TouchableOpacity>
          </View>

          <PageTitle title={'AUTH.COMPANY_DETAILS'} description={'AUTH.PLEASE_ENTER_YOUR_COMPANY'} />

          <SectionTitle
            title={'AUTH.COMPANY_INFORMATIONS'}
            actionName={'AUTH.EDIT'}
            actionPage={'EditCompanyInformations'}
          />

          <CompanyInformations values={values} />

          <SectionTitle title={'AUTH.COMPANY_ADDRESS'} actionName={'AUTH.EDIT'} actionPage={'EditCompanyAddress'} />

          <CompanyAddress values={values} />

          <SectionTitle title={'AUTH.COMPANY_DETAILS'} actionName={'AUTH.EDIT'} actionPage={'EditContactDetails'} />

          <CompanyDetails values={values} />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 40,
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  main: {
    paddingTop: 30,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  borderCity: {
    borderTopWidth: 1,
    borderTopColor: Colors.light.grayDark,
    paddingTop: 20,
  },
  borderMiddle: {
    borderTopWidth: 1,
    paddingBottom: 10,
    borderTopColor: Colors.light.grayDark,
    paddingTop: 20,
  },
  description: {
    fontSize: 14,
    paddingTop: 16,
    color: Colors.light.textLight,
    lineHeight: 18,
    width: Dimensions.get('window').width / 1.2,
  },
  city: {
    width: '65%',
  },
  zip: {
    width: '32%',
    marginRight: '3%',
  },
  headerSettings: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  companyText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    alignItems: 'center',
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  informationContainer: {
    display: 'flex',
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  companyStyle: {
    color: 'black',
    fontSize: 12,
    fontWeight: '600',
  },
  editStyle: {
    color: '#2F5AF5',
    fontSize: 15,
    fontWeight: '300',
  },
  containerInformation: {
    width: '100%',
    paddingHorizontal: 10,
    borderRadius: 5,
    paddingTop: 10,
    backgroundColor: Colors.light.gray,
  },
  secondText: {
    fontSize: 14,
    color: Colors.light.textLight,
    textAlign: 'center',
    alignItems: 'center',
  },
  textSecond: {
    fontSize: 14,
    color: Colors.light.textLight,
    textAlign: 'center',
    alignItems: 'center',
  },
  textField: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  textFieldSecond: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  borderCountry: {
    borderTopWidth: 1,
    paddingBottom: 20,
    borderTopColor: Colors.light.grayDark,
    paddingTop: 20,
  },
  industryText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    alignItems: 'center',
  },
});
