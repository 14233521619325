import { create } from 'zustand';
import AsyncStorage from '@react-native-async-storage/async-storage';

// State import
import { persist } from 'zustand/middleware';
import { useSpaceStore } from './spaces.state';
import { useStatsStore } from './stats.state';
import { useShopStore } from './shops.state';

type State = {
  token: string;
  userData: Object;
  isAuthenticated: boolean;
};

const initialState: State = {
  token: '',
  userData: {},
  isAuthenticated: false,
};

export const useAuthStore = create(
  persist(
    (set) => ({
      ...initialState,
      authenticateUser: (isAuthenticated: boolean, data: Object) =>
        set({ userData: data, isAuthenticated: isAuthenticated, token: data?.token?.access_token ?? '' }),

      logoutUser: () => {
        set(initialState);
        useSpaceStore?.getState()?.resetSpaces();
        useStatsStore?.getState()?.resetStats();
        useShopStore?.getState()?.resetShops();
      },
    }),
    {
      name: 'stathubs-auth-storage', // Storage Mobile App Name
      getStorage: () => AsyncStorage,
    }
  )
);
