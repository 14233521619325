import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { contactDetails } from '../../form/Validations';
import { TextInput } from '../../form/TextInput';
import Colors from '../../../constants/Colors';
import { useAuthStore } from '../../../store/auth.state';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Feather } from '@expo/vector-icons';
import { StackScreenProps } from '@react-navigation/stack';
import api from '../../../config/api';
import * as Haptics from 'expo-haptics';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../ui/PageTitle';
import TextInputValidation from '../../form/TextInputValidation';

type FormValues = {
  email: string;
  phone: string;
};

export const EditContactDetails: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const { userData }: any = useAuthStore();
  const userId = userData?.user?.id;
  const userEmail = userData?.user?.email;
  const { t } = useTranslation();

  const { ...methods } = useForm({
    resolver: yupResolver(contactDetails),
    mode: 'all',
    defaultValues: { email: userEmail },
  });

  const isDisabled = methods?.formState?.isDirty ? false : true;

  const [values, setValues] = useState({
    loading: true,
    errors: '',
    edit: false,
    company: null,
  });

  const createCompany = async (data: any) => {
    if (!data) return;
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/company/create', 'POST', data);
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.COMPANY_WAS_CREATED_SUCCESSFULL')}`,
      });
      navigation.navigate('Company', { isRefreshing: true });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    }
  };

  const updateCompany = async (data: any) => {
    if (!data) return;
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/company/update', 'POST', data);
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.COMPANY_UPDATED_SUCCESSFULL')}`,
      });
      navigation.navigate('Company', { isRefreshing: true });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (!userId) return;
    setValues({ ...values, loading: true });
    if (!values.company) {
      await createCompany(data);
    } else {
      await updateCompany(data);
    }
    setValues({ ...values, loading: false });
  };

  const getUserDetails = async (id: string) => {
    if (!id) return;
    try {
      const response = await api('/user/' + id, 'GET', null);
      if (response) {
        methods.reset(response?.data?.company);
        setValues({ ...values, company: response?.data?.company, loading: false });
      }
    } catch (error) {
      setValues({ ...values, loading: false });
    }
  };

  useEffect(() => {
    getUserDetails(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.headerSettings}>
        <TouchableOpacity style={styles.goBack} onPress={() => navigation.goBack()}>
          <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
          <Text style={styles.goBackText}>Company details</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={methods.handleSubmit(onSubmit)} disabled={isDisabled}>
          <Text style={[styles.editText, isDisabled && styles.editTextDisabled]}>{t('AUTH.SAVE')}</Text>
        </TouchableOpacity>
      </View>
      <KeyboardAwareScrollView
        style={styles.keyboardAvoidStyleContainer}
        scrollEnabled={true}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps={'handled'}
      >

        <PageTitle title={'AUTH.CONTACT_DETAILS'} />

        <FormProvider {...methods}>
          <TextInput
            name="email"
            label={t('AUTH.EMAIL')}
            editable={false}
            placeholder={t('AUTH.EMAIL')}
            placeholderTextColor={Colors.light.placeholder}
            style={[styles.inputStyle, styles.inputStyleDisabled]}
            required
          />

          {methods.formState.errors.email && (
            <TextInputValidation>{t(methods?.formState?.errors?.email?.message)}</TextInputValidation>
          )}

          <TextInput
            name="phone"
            label={t('AUTH.PHONE')}
            placeholder={t('AUTH.PHONE')}
            placeholderTextColor={Colors.light.placeholder}
            style={[styles.inputStyle]}
            keyboardType={'numeric'}
            required
          />

          {methods.formState.errors.phone && (
            <TextInputValidation>{t(methods?.formState?.errors?.phone?.message)}</TextInputValidation>
          )}
        </FormProvider>
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 60,
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  tableBorder: {
    borderWidth: 1,
    borderColor: 'red',
  },
  main: {
    paddingTop: 30,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 14,
    paddingTop: 16,
    color: Colors.light.textLight,
    lineHeight: 18,
    width: Dimensions.get('window').width / 1.2,
  },
  pickerSelectView: {
    marginTop: 16,
  },
  labelPickerSelect: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  inputStyle: {
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputStyleDisabled: {
    backgroundColor: Colors.light.disabled,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  shortTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginTop: 24,
  },
  cityAndZip: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  city: {
    width: '65%',
  },
  zip: {
    width: '32%',
    marginRight: '3%',
  },
  headerSettings: {
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  companyText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    alignItems: 'center',
  },
  cancel: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cancelText: {
    fontSize: 16,
    color: Colors.light.red,
    fontWeight: '500',
  },
  editText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editTextDisabled: {
    color: Colors.light.editTextDisabled,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  informationContainer: {
    display: 'flex',
    paddingTop: 40,
    paddingBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  companyStyle: {
    color: 'black',
    fontSize: 18,
    fontWeight: '600',
  },
  editStyle: {
    color: 'blue',
    fontSize: 15,
    fontWeight: '300',
  },
  containerInformation: {
    width: '100%',
    paddingHorizontal: 10,
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: Colors.light.gray,
  },
  secondText: {
    fontSize: 14,
    color: Colors.light.textLight,
    textAlign: 'center',
    alignItems: 'center',
  },
  textSecond: {
    fontSize: 14,
    color: Colors.light.textLight,
    textAlign: 'center',
    alignItems: 'center',
  },
  textField: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  textFieldSecond: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  industryText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    alignItems: 'center',
  },
});

// const pickerSelectStyles = StyleSheet.create({
//   inputIOS: {
//     fontSize: 16,
//     paddingVertical: 12,
//     paddingHorizontal: 10,
//     borderWidth: 1,
//     borderColor: Colors.light.border,
//     borderRadius: 8,
//     height: 54,
//     paddingRight: 30,
//   },
//   inputAndroid: {
//     fontSize: 16,
//     paddingHorizontal: 10,
//     paddingVertical: 8,
//     borderWidth: 0.5,
//     borderColor: Colors.light.border,
//     borderRadius: 8,
//     height: 54,
//     paddingRight: 30,
//   },
// });