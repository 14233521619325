import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import Colors from '../../../constants/Colors';

type ButtonProps = {
    label: string;
    onPress: () => void;
    bg: "dark" | "light";
    disabled?: boolean;
    icon?: any;
    postfix?: boolean;
    loading?: boolean;
}

const Button = ({ label, onPress, bg, disabled, icon, postfix, loading }: ButtonProps) => {
    const { t } = useTranslation();

    const btnBgColor = (bg?.toLowerCase() === "dark") 
        ? { backgroundColor: Colors.light.shark, } 
        : { backgroundColor: Colors.light.athensGray }

    const btnTextColor = (bg?.toLowerCase() === "dark") 
        ? { color: Colors.light.white } 
        : { color: Colors.light.mirage }

    return(
        <View style={styles.container}>
            <TouchableOpacity disabled={disabled} onPress={onPress} style={[styles.btn, btnBgColor]}>
                {loading ? <ActivityIndicator /> : (
                    <>
                        {!postfix && icon && <View style={{marginRight: 8, ...btnTextColor}}>{icon}</View>}
                        <Text style={[styles.label, btnTextColor]}>{t(label)}</Text>
                        {postfix && icon && <View style={{marginLeft: 8, ...btnTextColor}}>{icon}</View>}
                    </>
                )}
            </TouchableOpacity>
        </View>
    );
}

export default memo(Button);


const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    btn: {
        height: 54,
        borderRadius: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnDisable: {
        backgroundColor: Colors.light.midGray,
    },
    label: {
        fontSize: 14,
        fontWeight: 'bold',
    }
});