const tintColorLight = '#2f95dc';

export default {
  light: {
    text: '#000',
    textLight: '#848B93',
    background: '#F2F2F7',
    white: '#FFFFFF',
    grayDark: '#292C331F',
    grayDark0: '#00000052',
    border: '#ECECEC',
    athensGray: '#F0F2F6',
    athensGrayOpacity: '#F0F2F665',
    blueRibbon: '#2F5AF5',
    blueRibbonEnable: '#2F5AF53C',
    blueLightChart: '#0f9af7',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    scienceBlue: '#0069C9',
    azureRadiance: '#0080F6',
    red: '#FF0000',
    alto: '#D9D9D9',
    hitGray: '#AAB4BD',
    lightGray: '#F6F7F9',
    green: '#00BC1D',
    harp: '#ECF5EE',
    dropdown: '#2F5AF514',
    dropdownShadow: '#163ABC10',
    iconColor: '#AAB4BD29',
    shark: '#292C33',
    alabaster: '#FAFAFA',
    gallery: '#EDEDED',
    mirage: '#1A1A2E',
    placeholder: '#0008145C',
    seaShell: '#F1F1F1',
    midGray: '#5C5F66',
    mystic: '#E7EBF2',
    boxShadow: '#292C3329',
    dodgerBlue: '#179EFB',
    trackColorFalse: '#E9E9EB',
    trackColorTrue: '#2F5AF53C',
    gray: '#F7F7F8',
    silver: '#BFBFBF',
    riptide: '#82EFB8',
    bittersweet: '#FF6969',
    date: '#0000008F',
    blueCharcoal: '#000814',
    borderBottomLine: '#00000021',
    disabled: '#292C331F',
    editTextDisabled: '#2F5AF552',
    catskillWhite: '#F9FAFC',
    grayLight: '#8E8E93',
    feedbackPurple: '#6E44FF',
    uploadFile: '#4B5869',
    orange: "#FFC400",
    grayBtn: '#E1E1E7',
    shadowColor: '#2424260B'
  },
  payment: {
    open: '#AAB4BD',
    paid: '#87DCA2',
    authorized: '#F4BE37',
    inProgress: '#5388D8',
    reminded: '#6B63B4',
    refunded: '#0D2535',
    refundedPartially: '#F4BE37',
    cancelled: '#FF6969',
    chargeback: '#2F5AF53D',
    paidPartially: '#E9ECEF',
    failed: '#eb9999d9',
    unconfirmed: '#8e9cbd',
  },
  order: {
    open: '#AAB4BD',
    inProgress: '#5388D8',
    done: '#6B63B4',
    cancelled: '#FF6969',
  },
};
