// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, isSupported, logEvent } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDlBwWNP0Az85orAaMqbVBW2p8oWCcI8lU',
  authDomain: 'stathubs-3926a.firebaseapp.com',
  projectId: 'stathubs-3926a',
  storageBucket: 'stathubs-3926a.appspot.com',
  messagingSenderId: '241423226539',
  appId: '1:241423226539:ios:06ec963566e87168e949ee',
  // measurementId: 'G-BL4B4EWCM4',
};

// Initialize Firebase
let analytics;
const app = initializeApp(firebaseConfig);
isSupported().then((supported: boolean) => {
  if (supported) {
    analytics = getAnalytics(app);
    logEvent(analytics, 'isSupported Anayltics', 'true');
  }
});
export { analytics };
