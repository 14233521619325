import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

type State = {
  client_key: string;
  client_secret: string;
  shop_url: string;
  currentLanguage: string;
};

const initialState: State = {
  client_key: '',
  client_secret: '',
  shop_url: '',
  currentLanguage: '',
};

export const useConfigStore = create(
  persist(
    (set) => ({
      ...initialState,
      initiateScannedData: (client_key: string, client_secret: string, shop_url: string) =>
        set({ client_key: client_key, client_secret: client_secret, shop_url: shop_url }),
      setCurrentLanguage: async (language: string) => {
        await AsyncStorage.setItem('language', language);
        set({ currentLanguage: language });
      },
      init: async () => {
        const storedLanguage = await AsyncStorage.getItem('language');
        if (storedLanguage) {
          set({ currentLanguage: storedLanguage });
        } else {
          set({ currentLanguage: 'en' });
        }
      },
    }),
    {
      name: 'stathubs-app-config-storage', // Storage Mobile App Name
      getStorage: () => AsyncStorage,
    }
  )
);
