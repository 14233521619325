// import { useDashboardStore } from '../../../../../store/dashboard.state';
// import { graphTypes } from '../../../../../utils/dashboard';
// import LineChartCompare from './Compare';
import LineChartMerge from './Merge';

const DashboardLineChartWeekly = () => {
  // const { graphType }: any = useDashboardStore();

  return <LineChartMerge />;
  // return graphType === graphTypes[0] ? <LineChartMerge /> : <LineChartCompare />;
};

export default DashboardLineChartWeekly;
