import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { intervals, graphTypes, graphViews } from '../utils/dashboard';

type GraphInterval = (typeof intervals)[number];
type GraphType = (typeof graphTypes)[number];
type GraphView = (typeof graphViews)[number];
type DateInterval = { startDate: any; endDate: any };
type TotalTurnover = {
  number: number | null;
  difference: number | null;
  isPozitive: boolean | null;
};
type AverageBasket = {
  number: number | null;
  difference: number | null;
  isPozitive: boolean | null;
};
type TotalVisitors = {
  number: number | null;
  difference: number | null;
  isPozitive: boolean | null;
};
type TotalOrders = {
  number: number | null;
  difference: number | null;
  isPozitive: boolean | null;
};
type SalesByShop = {
  name: string;
  totSales: number;
  totOrders: number;
  avgSales: number;
  conversion: number;
  visitors: number;
};
type TotalSales = { month: string; total: number; totalOrders?: number };
type ShopData = { name: string; monthlyData: TotalSales[] };
type IntervalData = { order_date: string; total: number };
type ActiveShop = { name: string; id: string; shopCurrency: string };
type ShopType = { value: number; name: string };
type SelectedIndustry = { value: number; label: string };
type SelectedShopInvite = { name: string; id: string };
type SelectedRoleInvite = { name: string; id: string };
type SelectedCountry = { label: string; value: string };

type State = {
  graphInterval: GraphInterval;
  graphType: GraphType;
  graphView: GraphView;
  dateInterval: DateInterval;
  turnover: TotalTurnover;
  averageBasket: AverageBasket;
  totalOrders: TotalOrders;
  cro: TotalOrders;
  totalVisitors: TotalVisitors;
  mergeSales: ShopData[];
  compareSales: ShopData[];
  salesByShop: SalesByShop[];
  noShopAddedModal: boolean;
  noShopAddedModalGeneral: boolean;
  activeShop: ActiveShop;
  shopType: ShopType;
  selectedIndustry: SelectedIndustry;
  selectedShopInvite: SelectedShopInvite;
  selectedRoleInvite: SelectedRoleInvite;
  selectedCountry: SelectedCountry;
};

export const initialState: State = {
  graphInterval: intervals[0],
  graphType: graphTypes[0], // Default value MERGE
  graphView: graphViews[0],
  dateInterval: { startDate: '', endDate: '' },
  turnover: { number: null, difference: null, isPozitive: null },
  averageBasket: { number: null, difference: null, isPozitive: null },
  cro: { number: null, difference: null, isPozitive: null },
  totalOrders: { number: null, difference: null, isPozitive: null },
  totalVisitors: { number: null, difference: null, isPozitive: null },
  mergeSales: [],
  compareSales: [],
  salesByShop: [],
  noShopAddedModal: false,
  noShopAddedModalGeneral: false,
  activeShop: { name: '', id: '', shopCurrency: '' },
  shopType: { value: 0, name: 'Shopware 6' },
  selectedIndustry: { value: 0, label: '' },
  selectedShopInvite: { name: '', id: '' },
  selectedRoleInvite: { name: '', id: '' },
  selectedCountry: { label: '', value: '' },
};

export const useDashboardStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      setGraphInterval: (graphInterval: GraphInterval) => set((state: State) => ({ ...state, graphInterval })),
      //   setGraphType: (graphType: GraphType) => set((state: State) => ({ ...state, graphType })), // NO NEED ANYMORE SINCE WE DONT HAVE MERGE AND COMPARE
      setGraphView: (graphView: GraphView) => set((state: State) => ({ ...state, graphView })),
      setDateInterval: (dateInterval: DateInterval) => set((state: State) => ({ ...state, dateInterval })),
      setTurnover: (turnover: TotalTurnover) => set((state: State) => ({ ...state, turnover })),
      setAverageBasket: (averageBasket: AverageBasket) => set((state: State) => ({ ...state, averageBasket })),
      setCro: (cro: TotalOrders) => set((state: State) => ({ ...state, cro })),
      setTotalOrders: (totalOrders: TotalOrders) => set((state: State) => ({ ...state, totalOrders })),
      setTotalVisitors: (totalVisitors: TotalVisitors) => set((state: State) => ({ ...state, totalVisitors })),
      setMergeSales: (mergeSales: ShopData[]) => set((state: State) => ({ ...state, mergeSales })),
      setCompareSales: (compareSales: ShopData[]) => set((state: State) => ({ ...state, compareSales })),
      setSalesByShop: (salesByShop: SalesByShop) => set((state: State) => ({ ...state, salesByShop })),
      setNoShopAddedModal: (noShopAddedModal: boolean) => set((state: State) => ({ ...state, noShopAddedModal })),
      setNoShopAddedModalGeneral: (noShopAddedModalGeneral: boolean) =>
        set((state: State) => ({ ...state, noShopAddedModalGeneral })),
      setActiveShop: (activeShop: ActiveShop) => set((state: State) => ({ ...state, activeShop })),
      setShopType: (shopType: ShopType) => set((state: State) => ({ ...state, shopType })),
      setSelectedIndustry: (selectedIndustry: SelectedIndustry) =>
        set((state: State) => ({ ...state, selectedIndustry })),
      setSelectedShopInvite: (selectedShopInvite: SelectedShopInvite) =>
        set((state: State) => ({ ...state, selectedShopInvite })),
      setSelectedRoleInvite: (selectedRoleInvite: SelectedRoleInvite) =>
        set((state: State) => ({ ...state, selectedRoleInvite })),
      setSelectedCountry: (selectedCountry: SelectedCountry) => set((state: State) => ({ ...state, selectedCountry })),

      setStateFromStorage: async () => {
        try {
          const storedState = await get();

          if (storedState) {
            set(() => ({
              ...initialState,
              ...storedState,
            }));
          }
        } catch (error) {}
      },
    }),
    {
      name: 'stathubs-dashboard-storage', // Storage Mobile App Name
      getStorage: () => AsyncStorage,
    }
  )
);
