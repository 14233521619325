import React, { memo } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import Colors from '../../constants/Colors';

interface ISpaceTitle {
  title: string;
  actionName?: string;
  actionPage?: string;
}

const SectionTitle = ({ title, actionName, actionPage }: ISpaceTitle) => {
  const { t } = useTranslation();

  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t(title)}</Text>
      {actionName && actionPage &&
        <TouchableOpacity onPress={() => navigation.navigate(actionPage)}>
          <Text style={styles.btn}>{t(actionName)}</Text>
        </TouchableOpacity>
      }
    </View>
  );
};

export default memo(SectionTitle);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
    marginLeft: 4
  },
  title: {
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: 0.19,
    color: Colors.light.grayLight
  },
  btn: {
    fontSize: 15,
    fontWeight: '500',
    color: Colors.light.blueRibbon,
  },
});
