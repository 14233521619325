import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import CalendarPicker from 'react-native-calendar-picker';
import Colors from '../../constants/Colors';
import { useConfigStore } from '../../store/config.state';
import { useDashboardStore } from '../../store/dashboard.state';
import { Entypo } from '@expo/vector-icons';

interface IDatePickerInterval {
  setState: any;
}

const DatePickerInterval = ({ setState }: IDatePickerInterval) => {
  const { dateInterval, setDateInterval }: any = useDashboardStore();
  const { currentLanguage }: any = useConfigStore();
  const { t } = useTranslation();

  const [isStartInputClicked, setIsStartInputClicked] = useState(false);
  const [isEndInputClicked, setIsEndInputClicked] = useState(false);

  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let dateOneYearAgo = new Date(currentDate);
  dateOneYearAgo.setFullYear(currentYear - 2);

  useEffect(() => {
    if (!dateInterval.endDate) {
      setDateInterval({ startDate: '', endDate: '' });
    }
  }, []);

  const onDateChange = (date: string, type: string) => {
    if (type === 'END_DATE') {
      setDateInterval({ startDate: dateInterval.startDate, endDate: date });
      setState((prevState: any) => ({ ...prevState, placeClicked: true }));
    } else {
      setDateInterval({ startDate: date, endDate: '' });
    }
  };

  const inputClick = (type: string) => {
    if (type === 'START_DATE') {
      setIsStartInputClicked(true);
      setDateInterval({ startDate: '', endDate: '' });
    } else {
      setIsEndInputClicked(true);
      setDateInterval({ startDate: dateInterval.startDate, endDate: '' });
    }
  };

  const renderCustomDates = () => {
    const customDatesStyles = [];

    if (dateInterval.startDate) {
      customDatesStyles.push({
        date: dateInterval.startDate,
        containerStyle: { backgroundColor: '#292C33', borderRadius: 50 },
      });
    }

    if (dateInterval.endDate) {
      customDatesStyles.push({
        date: dateInterval.endDate,
        containerStyle: { backgroundColor: '#292C33', borderRadius: 50 },
      });
    }

    return customDatesStyles;
  };

  const dataFormat = (date: any) => {
    if (date) {
      const year = new Date(date).getFullYear();
      const month = String(new Date(date).getMonth() + 1).padStart(2, '0');
      const day = String(new Date(date).getDate()).padStart(2, '0');

      switch (currentLanguage) {
        case 'de':
          return `${day}.${month}.${year}`;
        default:
          return `${day}/${month}/${year}`;
      }
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.startAndEnd}>
        <TouchableOpacity
          style={[styles.inputStyle, isStartInputClicked && styles.inputOnClick]}
          onPress={() => inputClick('START_DATE')}
          onBlur={() => setIsStartInputClicked(false)}
        >
          <TextInput
            placeholder={t('CALENDAR.START_DATE') as string}
            placeholderTextColor={Colors.light.placeholder}
            value={dateInterval.startDate ? dataFormat(dateInterval.startDate) : ''}
            editable={false}
            style={styles.inputStyleText}
          />
        </TouchableOpacity>
        <Text>-</Text>
        <TouchableOpacity
          style={[styles.inputStyle, isEndInputClicked && styles.inputOnClick]}
          onPress={() => inputClick('END_DATE')}
          onBlur={() => setIsEndInputClicked(false)}
        >
          <TextInput
            placeholder={t('CALENDAR.END_DATE') as string}
            placeholderTextColor={Colors.light.placeholder}
            value={dateInterval.endDate ? dataFormat(dateInterval.endDate) : ''}
            editable={false}
            style={styles.inputStyleText}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.datePickerContainer}>
        <CalendarPicker
          allowRangeSelection={true}
          // scrollable={true}
          width={Dimensions.get('window').width}
          minDate={dateOneYearAgo}
          maxDate={new Date()}
          // restrictMonthNavigation={true}
          selectedStartDate={dateInterval?.startDate?.toString()}
          selectedEndDate={dateInterval?.endDate?.toString()}
          weekdays={t('CALENDAR.WEEKDAYS', { returnObjects: true })}
          months={t('CALENDAR.MONTHS', { returnObjects: true })}
          previousTitle={<Entypo name="chevron-left" size={26} color="black" />}
          nextTitle={<Entypo name="chevron-right" size={26} color="black" />}
          selectMonthTitle={t('CALENDAR.SELECT_MONTH')}
          selectYearTitle={t('CALENDAR.SELECT_YEAR')}
          customDatesStyles={renderCustomDates()}
          todayBackgroundColor="#A9AAAD"
          selectedDayColor={'transparent'}
          selectedRangeStartTextStyle={{
            color: '#ffffff',
          }}
          selectedRangeEndTextStyle={{
            color: '#ffffff',
          }}
          textStyle={{
            fontWeight: '600',
          }}
          onDateChange={onDateChange}
        />
      </View>
    </View>
  );
};

export default memo(DatePickerInterval);

const styles = StyleSheet.create({
  // overlay: {
  //     position: 'absolute',
  //     width: Dimensions.get('window').width,
  //     height: Dimensions.get('window').height,
  //     backgroundColor: 'rgba(0,0,0,.5)',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  // },
  // container: {
  //     width: Dimensions.get('window').width / 1.2,
  //     backgroundColor: Colors.light.white,
  //     padding: 16,
  //     borderRadius: 8,
  // },
  container: {
    width: '100%',
  },
  startAndEnd: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  datePickerContainer: {
    marginBottom: 0,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
    width: '45%',
  },
  inputStyleText: {
    fontWeight: '600',
  },
  inputOnClick: {
    borderColor: '#000000',
    borderRadius: 8,
  },
});
