import { Lato_400Regular, useFonts } from '@expo-google-fonts/lato';
import React, { memo, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Circle, G, Line, Text } from 'react-native-svg';
import { Grid, LineChart, XAxis, YAxis } from 'react-native-svg-charts';
import Colors from '../../../constants/Colors';
import { useConfigStore } from '../../../store/config.state';
import { useDashboardStore } from '../../../store/dashboard.state';
import { formatGraphsYLabels, getMinMaxYGraphsValue } from '../../../utils/charts';
import { formatCurrency } from '../../../utils/formatCurrency';

interface ILineChart {
  data: any;
  chartConfig: any;
}

const DashboardLineChart = ({ data, chartConfig }: ILineChart) => {
  useFonts({
    Lato_400Regular,
  });

  const { currentLanguage }: any = useConfigStore();
  const scrollViewRef = useRef<ScrollView>(null);
  const { salesByShop, activeShop }: any = useDashboardStore();
  const flatData = data.data.length > 0 ? data.data?.flat().map((item: any) => item.value) : [];
  const { minYValue, maxYValue } = getMinMaxYGraphsValue(flatData);
  const [displayTurnover, setDisplayTurnover] = useState<boolean>(false);
  const [selectedDecoratorIndex, setSelectedDecoratorIndex] = useState<number | null>(null);

  const initialColor = activeShop.id ? salesByShop[0]?.color : Colors.light.blueRibbon;

  const contentInset = { top: 30, bottom: 15 };
  const xAxisHeight = 20;

  const transformedData = data.data.map((item, index) => ({
    data: item.length > 0 ? item.map((v) => v.value) : [],
    svg: {
      stroke: initialColor,
    },
  }));

  const handleLinePress = (index: number) => {
    setSelectedDecoratorIndex(index);
    setDisplayTurnover(true);
  };

  const Decorator = ({ x, y, data }) => {
    return flatData.map(
      (value, index) =>
        value === 0 || (
          <Circle
            key={index}
            cx={x(index)}
            cy={y(value)}
            r={7}
            stroke={initialColor}
            fill={initialColor}
            onPress={() => handleLinePress(index)}
          />
        )
    );
  };

  const Labels = ({ x, y, bandwidth, data }) =>
    selectedDecoratorIndex !== null &&
    flatData.map((value, index) => {
      const label = value !== 0 ? formatCurrency(value, activeShop.shopCurrency, currentLanguage) : '';

      if (index === 0 && index === selectedDecoratorIndex) {
        return (
          <G key={index}>
            <Line x1={x(index)} y1="10" x2={x(index)} y2="215" stroke={initialColor} strokeWidth="1" />
            <Text
              key={index}
              x={x(index) + 10}
              y="5"
              fontSize={10}
              fontFamily="Lato_400Regular"
              fill={initialColor}
              alignmentBaseline={'middle'}
              textAnchor={'middle'}
            >
              {label}
            </Text>
          </G>
        );
      } else if (index === flatData.length - 1 && index === selectedDecoratorIndex) {
        return (
          <G key={index}>
            <Line x1={x(index)} y1="10" x2={x(index)} y2="215" stroke={initialColor} strokeWidth="1" />
            <Text
              key={index}
              x={x(index) - 10}
              y="5"
              fontSize={10}
              fontFamily="Lato_400Regular"
              fill={initialColor}
              alignmentBaseline={'middle'}
              textAnchor={'middle'}
            >
              {label}
            </Text>
          </G>
        );
      } else if (index === selectedDecoratorIndex) {
        return (
          <G key={index}>
            <Line x1={x(index)} y1="10" x2={x(index)} y2="215" stroke={initialColor} strokeWidth="1" />
            <Text
              key={index}
              x={x(index)}
              y="5"
              fontSize={10}
              fontFamily="Lato_400Regular"
              fill={initialColor}
              alignmentBaseline={'middle'}
              textAnchor={'middle'}
            >
              {label}
            </Text>
          </G>
        );
      } else {
        return null;
      }
    });

  const scrollToRight = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  };

  useEffect(() => {
    if (data.labels && data.labels[0] !== '00') {
      scrollToRight();
    }
    setDisplayTurnover(false);
  }, [data]);

  return (
    <View style={{ flexDirection: 'row', height: 260 }}>
      <YAxis
        data={[minYValue, maxYValue]}
        style={{ marginBottom: 30 }}
        contentInset={contentInset}
        svg={{ fontSize: 10, fill: Colors.light.grayLight, fontFamily: 'Lato_400Regular' }}
        formatLabel={(value: any) => formatGraphsYLabels(value)}
        numberOfTicks={8}
      />
      <ScrollView ref={scrollViewRef} horizontal>
        <View style={{ width: chartConfig.width || 800, marginLeft: 10 }}>
          <LineChart
            style={{ flex: 1, marginLeft: 8, width: '100%' }}
            data={transformedData}
            contentInset={{ top: 30, bottom: 15, left: 10, right: 22 }}
            gridMin={0}
            // curve={shape.curveMonotoneX}
            numberOfTicks={8}
            svg={{
              strokeWidth: 2,
            }}
          >
            <Grid svg={{ stroke: '#E9ECEF', strokeWidth: 0.5 }} stroke={'#E9ECEF'} />
            <Decorator />
            {displayTurnover && <Labels />}
          </LineChart>

          <XAxis
            style={{ marginLeft: -8, marginRight: -15, marginTop: 10, height: xAxisHeight }}
            data={data?.labels ? data.labels : []}
            formatLabel={(value, index) => data.labels[index]}
            contentInset={{ left: 25, right: 30 }}
            svg={{ fontSize: 10, fill: Colors.light.grayLight, fontFamily: 'Lato_400Regular' }}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default memo(DashboardLineChart);

const styles = StyleSheet.create({
  chart: {
    marginVertical: 0,
    borderRadius: 16,
  },
});
