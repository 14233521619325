import SectionTitle from "../../ui/SectionTitle";
import { useTranslation } from 'react-i18next';
import ListItemContainer from '../../ui/ListItemContainer';
import ListItem from "../../ui/ListItem";
import ListItemDivider from '../../ui/ListItemDivider';
import { useSpaceStore } from '../../../store/spaces.state';

const SpaceInformation = () => {
    const { t } = useTranslation();

    const { defaultSpace }: any = useSpaceStore();

    return (
        <>
            <SectionTitle 
                title={`${t("AUTH.SPACE_INFORMATION")}`} 
                actionName={"Edit"}  
                actionPage={"EditSpaceInformation"}
            />

            <ListItemContainer>
                <ListItem label={`${t('AUTH.SPACE_NAME')}`} value={defaultSpace?.name} />
                <ListItemDivider />
                <ListItem label={`${t('AUTH.SPACE_CODE')}`} value={defaultSpace?.code} />
            </ListItemContainer>
        </>
    );
}

export default SpaceInformation;