import React from 'react';
import { StyleSheet } from 'react-native';
import { PortalProvider } from '@gorhom/portal';
import { StatusBar } from 'expo-status-bar';
import Loading from './components/Loading';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';


// Bootstrap
import useCachedResources from './hooks/useCachedResources';

// Navigation
import RootNavigation from './navigation';

// GestureHandler/BottomSheets
import 'react-native-gesture-handler';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

// Import toast notifications
import Toast from 'react-native-toast-message';

// Initialize Firebase
import './config/firebase';
// import AsyncStorage from '@react-native-async-storage/async-storage';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
// async function clearAsyncStorage() {
//   try {
//     await AsyncStorage.clear();
//     console.log('AsyncStorage cleared successfully!');
//   } catch (error) {
//     console.error('Error clearing AsyncStorage:', error);
//   }
// }
export default function App() {
  // clearAsyncStorage()
  const isLoadingComplete = useCachedResources();
  if (!isLoadingComplete) return <Loading />;

  return (
    <PortalProvider>
      <StatusBar style="dark" />
      <GestureHandlerRootView style={styles.container}>
        <BottomSheetModalProvider>
          <RootNavigation />
        </BottomSheetModalProvider>
      </GestureHandlerRootView>
      <Toast position="top" topOffset={50} visibilityTime={1500} />
    </PortalProvider>
  );
}
