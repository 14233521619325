import React, { useEffect, useRef } from 'react';
import { View, TouchableOpacity, Image, StyleSheet } from 'react-native';
import Images from '../../theme/Images';
import { useConfigStore } from '../../store/config.state';
import LanguageBottomSheetModalAPI from '../../components/bottom-sheet-modal/Language/LanguageBottomSheetModalAPI';
import { Modalize } from 'react-native-modalize';

const LanguageSwitcher = () => {
  const { currentLanguage, setCurrentLanguage }: any = useConfigStore();
  const languageSheetRef = useRef<Modalize>(null);

  useEffect(() => {
    setCurrentLanguage('en');
  }, []);

  return (
    <>
      <View style={styles.touchStyle}>
        <TouchableOpacity onPress={() => languageSheetRef?.current?.open()}>
          <View style={styles.imgStyle}>
            {currentLanguage === 'en' ? (
              <Image source={Images.ukFlag} style={styles.flag} />
            ) : currentLanguage === 'us' ? (
              <Image source={Images.usFlag} style={styles.flag} />
            ) : (
              <Image source={Images.deFlag} style={styles.flag} />
            )}
          </View>
        </TouchableOpacity>
      </View>

      <LanguageBottomSheetModalAPI bottomSheetRef={languageSheetRef} />
    </>
  );
};

export default LanguageSwitcher;

const styles = StyleSheet.create({
  touchStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imgStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flag: {
    width: 24,
    height: 24,
  },
  textEn: {
    marginHorizontal: 5,
    fontWeight: '700',
  },
});
