import React, { memo, useState } from 'react';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity } from 'react-native';
import Colors from '../../../constants/Colors';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { joinSpaceValidation } from '../../../components/form/Validations';
import { TextInput } from '../../../components/form/TextInput';
import { useNavigation } from '@react-navigation/native';
import api from '../../../config/api';
import { useTranslation } from 'react-i18next';
import TextInputValidation from '../../../components/form/TextInputValidation';
import Button from '../../../components/ui/action/Button';

type FormValues = {
  space_code: string;
};

const JoinSpaceScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { ...methods } = useForm({ resolver: yupResolver(joinSpaceValidation), mode: 'all' });
  const [values, setValues] = useState({
    loading: false,
    hasError: '',
  });

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    const { space_code } = data;
    if (!space_code) return;

    setValues({ ...values, loading: true, hasError: '' });

    try {
      const response = await api('/space/join', 'POST', { code: space_code });
      if (response?.success) navigation.navigate('Dashboard');
      setValues({ ...values, loading: false, hasError: '' });
    } catch (error) {
      setValues({ ...values, loading: false, hasError: error?.message ?? 'Soemthing went wrong!' });
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        {values?.hasError && <Text>{values?.hasError}</Text>}
        <View style={styles.inputWithDomain}>
          <View>
            <TextInput
              name="space_code"
              label={'AUTH.SPACE_CODE'}
              placeholder={t('AUTH.ENTER_6_DIGITS')}
              placeholderTextColor={Colors.light.placeholder}
              autoCapitalize="characters"
              style={styles.inputStyleJoin}
              required
            />
            {methods?.formState?.errors?.space_code && (
              <TextInputValidation>{t(methods?.formState?.errors?.space_code?.message)}</TextInputValidation>
            )}
          </View>
        </View>
      </FormProvider>

      <View style={styles.bottom}>
        <Button
          loading={values.loading}
          onPress={methods.handleSubmit(onSubmit)}
          label={'AUTH.JOIN_SPACE'}
          bg={'dark'}
        />
      </View>
    </>
  );
};
export default memo(JoinSpaceScreen);


const styles = StyleSheet.create({
  keyboardAvoidStyleContainer: {
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.background,
  },
  main: {
    width: '100%',
  },
  content: {
    paddingTop: 32,
    paddingBottom: 30,
  },
  title: {
    fontSize: 20,
    width: Dimensions.get('window').width / 1.4,
    fontWeight: '600',
  },
  subTitle: {
    fontSize: 14,
    paddingTop: 12,
  },
  inputWithDomain: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderTopStartRadius: 8,
    borderBottomStartRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: Dimensions.get('window').width / 1.85,
  },
  inputStyleJoin: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    width: Dimensions.get('window').width / 1.133,
    paddingHorizontal: 16,
    height: 54,
    borderRadius: 8,
  },
  inputStyleDomain: {
    backgroundColor: Colors.light.mystic,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderBottomEndRadius: 8,
    borderTopEndRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: 140,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  bottomFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 24,
  },
  bottomFooterText: {
    fontSize: 14,
  },
  labelBottom: {
    paddingHorizontal: 3,
    fontSize: 14,
    fontWeight: '600',
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingTop: 6,
  },
});