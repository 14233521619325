import React from 'react';
import SectionTitle from '../../ui/SectionTitle';
import ListItem from '../../ui/ListItem';

const AccountSecurity = () => {
  return (
    <>
      <SectionTitle title={'AUTH.SECURITY'} actionName={'AUTH.CHANGE_PASSWORD'} actionPage={'ChangePassword'} />
      <ListItem label={'AUTH.PASSWORD'} value={'********'} />
    </>
  );
};

export default AccountSecurity;
