import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import SectionTitle from "../../ui/SectionTitle";
import ListItemShop from "../../ui/ListItemShop";
import Images from '../../../theme/Images';
import api from '../../../config/api';
import { useSpaceStore } from '../../../store/spaces.state';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import Loading from '../../Loading';

type Shop = {
    id: string;
    name: string;
    status: number;
}

type StateTypes = {
    shops: Shop[];
    loading: boolean;
}

const ShopsInSpace = () => {
    const [state, setState] = useState<StateTypes>({
        shops: [],
        loading: false,
    })
    
    const { defaultSpace }: any = useSpaceStore();

    const { t } = useTranslation();

    useEffect(() => {
        const getCurrentUser = async () => {
            if (!defaultSpace?.id) return;
            setState({ ...state, loading: true })
            try {
                const response = await api('/shops/space/' + defaultSpace?.id, 'GET', null);
                setState({ ...state, shops: response.data, loading: false })
            } catch (error) {
              Toast.show({
                type: 'error',
                text1: `${t('AUTH.SPACE_DETAILS')}`,
                text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
              });
            }
          };

          getCurrentUser();
    }, [])

    return (
        <>
            {state.loading && <Loading />}
            <View style={{ marginBottom: 16 }}>
                <SectionTitle title={`AUTH.SHOPS_IN_SPACE`} />
                {state.shops?.map((shop: any) =>
                    <ListItemShop
                        key={shop?.id}
                        shopImg={"Images.shopware"}
                        label={shop?.name}
                        platformType={shop?.platform_type}
                        status={shop?.status ? "Active" : "Deactive"}
                        icon={Images.shopware}
                    />
                )}
            </View>
        </>
    );
}

export default ShopsInSpace;