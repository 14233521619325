import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import SignInScreen from '../screens/authentication/SignInScreen';
import SignUpScreen from '../screens/authentication/SignUpScreen';
import ResetPassword from '../screens/authentication/ResetPassword';
import EmailSent from '../screens/authentication/EmailSent';
import EmailSentOnRegistration from '../screens/authentication/EmailSentOnRegistration';
import UpdatePassword from '../screens/authentication/UpdatePassword';
import ReviewScreen from '../../stathubs-old/screens/authentication/register/Review.screen';

const Stack = createStackNavigator();

export default function AuthStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="SignIn" component={SignInScreen} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="SignUp" component={SignUpScreen} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="EmailSentOnRegistration" component={EmailSentOnRegistration} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="EmailSent" component={EmailSent} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="ResetPassword" component={ResetPassword} options={{ headerShown: false }} />
      <Stack.Screen name="UpdatePassword" component={UpdatePassword} options={{ headerShown: false }} />
      <Stack.Screen name="ReviewScreen" component={ReviewScreen} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
}
