export default [
  {
    label: 'EUR',
    value: '€',
  },
  {
    label: 'USD',
    value: '$',
  },
];
