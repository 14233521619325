import React, { useCallback, useRef, useState } from 'react';

// Tab Screens
import Dashboard from '../screens/dashboard/bottom-tabs/Dashboard/DashboardScreen';
// import NotificationScreen from '../screens/dashboard/bottom-tabs/NotificationScreen';
import ShopScreen from '../screens/dashboard/bottom-tabs/ShopScreen';
import SettingsScreen from '../screens/dashboard/bottom-tabs/SettingsScreen';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Feather } from '@expo/vector-icons';
import Colors from '../constants/Colors';
import { Shops } from '../assets/images/index';
import { FeedbackBottomSheetModal } from '../components/bottom-sheet-modal/helpUsImprove/FeedbackBottomSheetModal';
import { SelectPageBottomSheetModal } from '../components/bottom-sheet-modal/helpUsImprove/SelectPageBottomSheetModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { feedbackValidations } from '../components/form/Validations';
import { Modalize } from 'react-native-modalize';
import Toast from 'react-native-toast-message';
import api from '../config/api';
import { Flag } from '../assets/images';
import { useTranslation } from 'react-i18next';

export type RootTabParamList = {
  Main: undefined;
  // Notifications: undefined;
  Shops: undefined;
  Settings: undefined;
};

type FormValues = {
  page_id: string;
  specific_field: string;
  // screenshot: string;
  description: string;
  screenshot?: any;
};

type Page = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
};

const BottomTab = createBottomTabNavigator<RootTabParamList>();

const styles = StyleSheet.create({
  bottomBarNav: {
    paddingBottom: 20,
    height: 77,
    shadowColor: Colors.light.boxShadow,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 6,
  },
  bottomLine: {
    borderRadius: 2,
    overflow: 'hidden',
  },
  bottomLineStyle: {
    height: 4,
  },
  bottomLineStyleActive: {
    backgroundColor: Colors.light.blueRibbon,
    height: 4,
    width: 41,
  },
  touchableOpacityStyle: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 100,
    right: 16,
    width: 140,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    overflow: 'hidden',
    backgroundColor: Colors.light.feedbackPurple,
    // backgroundImage: 'linear-gradient(.25turn, #6E44FF, #8338EC)',
  },
  text: {
    color: 'white',
    fontSize: 12,
  },
});

export default function BottomTabsStack() {
  const [screenshot, setScreenshot] = useState('');
  const [page, setPage] = useState<Page>({ id: '', name: '', created_at: '', updated_at: '' });
  const { t } = useTranslation();
  const { ...methods } = useForm({
    resolver: yupResolver(feedbackValidations),
    mode: 'all',
  });
  const bottomSheetRef = useRef<Modalize>(null);
  const selectPageBottomSheetRef = useRef<Modalize>(null);

  const handlePresentModalPress = useCallback(() => {
    methods.reset();
    bottomSheetRef.current?.open();
  }, []);

  const handleSelectPageModalPress = useCallback(() => {
    bottomSheetRef.current?.close();
    selectPageBottomSheetRef.current?.open();
  }, []);

  const handleBackSelectPageModalPress = useCallback(() => {
    selectPageBottomSheetRef.current?.close();
    bottomSheetRef.current?.open();
  }, []);

  const onSubmit = async (data: FormValues) => {
    try {
      data.screenshot = screenshot;
      data.page_id = page.id;
      const response = await api('/user/feedback', 'POST', data);

      if (response.success) {
        bottomSheetRef?.current?.close();
        setPage({ id: '', name: '', created_at: '', updated_at: '' });
        Toast.show({
          type: 'success',
          text1: `${t('AUTH.USER_FEEDBACK')}`,
          text2: `${t('AUTH.USER_FEEDBACK_SUCCESS')}`,
        });
      }
    } catch (error: any) {
      Toast.show({
        type: 'error',
        text1: `Error`,
        text2: error.message,
      });
    }
  };

  return (
    <>
      <BottomTab.Navigator
        screenOptions={{
          tabBarStyle: styles.bottomBarNav,
        }}
      >
        <BottomTab.Screen
          name="Main"
          component={Dashboard}
          options={{
            headerShown: false,
            tabBarInactiveTintColor: Colors.light.background,
            // eslint-disable-next-line react/no-unstable-nested-components
            tabBarLabel: ({ focused }) => {
              return focused ? (
                <View style={styles.bottomLine}>
                  <Text style={styles.bottomLineStyleActive} />
                </View>
              ) : (
                <View style={styles.bottomLine}>
                  <Text style={styles.bottomLineStyle} />
                </View>
              );
            },
            // eslint-disable-next-line react/no-unstable-nested-components
            tabBarIcon: ({ focused }) => {
              return focused ? (
                <Feather name="bar-chart-2" size={24} color="blue" />
              ) : (
                <Feather name="bar-chart-2" size={24} color="black" />
              );
            },
          }}
        />
        {/* <BottomTab.Screen
        name="Notifications"
        component={NotificationScreen}
        options={{
          headerTitleAlign: 'left',
          headerTitleStyle: {
            fontSize: 28,
            marginBottom: 8,
          },
          headerShown: false,
          tabBarInactiveTintColor: Colors.light.background,
          // eslint-disable-next-line react/no-unstable-nested-components
          tabBarLabel: ({ focused }) => {
            return focused ? (
              <View style={styles.bottomLine}>
              <Text style={styles.bottomLineStyleActive} />
              </View>
            ) : (
              <View style={styles.bottomLine}>
              <Text style={styles.bottomLineStyle} />
              </View>
            );
          },
          // eslint-disable-next-line react/no-unstable-nested-components
          tabBarIcon: ({ focused }) => {
            return focused ? (
              <Feather name="bell" size={24} color="blue" />
            ) : (
              <Feather name="bell" size={24} color="black" />
            );
          },
        }}
      /> */}
        <BottomTab.Screen
          name="Shops"
          component={ShopScreen}
          options={{
            headerTitleAlign: 'left',
            headerTitleStyle: {
              fontSize: 28,
              marginBottom: 8,
            },
            headerShown: false,
            tabBarInactiveTintColor: Colors.light.background,
            // eslint-disable-next-line react/no-unstable-nested-components
            tabBarLabel: ({ focused }) => {
              return focused ? (
                <View style={styles.bottomLine}>
                  <Text style={styles.bottomLineStyleActive} />
                </View>
              ) : (
                <View style={styles.bottomLine}>
                  <Text style={styles.bottomLineStyle} />
                </View>
              );
            },
            // eslint-disable-next-line react/no-unstable-nested-components
            tabBarIcon: ({ focused }) => {
              return focused ? <Shops stroke={Colors.light.blueRibbon} /> : <Shops />;
            },
          }}
        />
        <BottomTab.Screen
          name="Settings"
          component={SettingsScreen}
          options={{
            headerTitleAlign: 'left',
            headerTitleStyle: {
              fontSize: 28,
              marginBottom: 8,
            },
            headerShown: false,
            tabBarInactiveTintColor: Colors.light.background,
            // eslint-disable-next-line react/no-unstable-nested-components
            tabBarLabel: ({ focused }) => {
              return focused ? (
                <View style={styles.bottomLine}>
                  <Text style={styles.bottomLineStyleActive} />
                </View>
              ) : (
                <View style={styles.bottomLine}>
                  <Text style={styles.bottomLineStyle} />
                </View>
              );
            },
            // eslint-disable-next-line react/no-unstable-nested-components
            tabBarIcon: ({ focused }) => {
              return focused ? (
                <Feather name="menu" size={24} color="blue" />
              ) : (
                <Feather name="menu" size={24} color="black" />
              );
            },
          }}
        />
      </BottomTab.Navigator>

      <TouchableOpacity activeOpacity={0.7} onPress={handlePresentModalPress} style={styles.touchableOpacityStyle}>
        <Flag />
        <Text style={styles.text}> Help us improve</Text>
      </TouchableOpacity>

      <FeedbackBottomSheetModal
        bottomSheetRef={bottomSheetRef}
        onSubmit={onSubmit}
        methods={methods}
        screenshot={screenshot}
        setScreenshot={setScreenshot}
        page={page}
        // loadingCreate={onInitializeShop}
        onOpenSelectPageModal={handleSelectPageModalPress}
      />

      <SelectPageBottomSheetModal
        bottomSheetRef={selectPageBottomSheetRef}
        goBackBtn={handleBackSelectPageModalPress}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
