import { Feather } from '@expo/vector-icons';
import { Portal } from '@gorhom/portal';
import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Modalize } from 'react-native-modalize';
import Colors from '../../../constants/Colors';
import Images from '../../../theme/Images';
import hasNotch from '../../../utils/isNotch';
import shops from '../../../utils/shops';
import LabelPickerSelect from '../../form/LabelPickerSelect';
import { TextInput } from '../../form/TextInput';
import TextInputValidation from '../../form/TextInputValidation';
import Divider from '../../ui/Divider';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import Button from '../../ui/action/Button';
import { useDashboardStore } from '../../../store/dashboard.state';

type FormValues = {
  name: string;
  space_id: string;
  platform_name: string;
  platform_type: number;
  client_key: string;
  secret_key: string;
  shop_url: string;
  status: string;
  currency_iso_code: string;
  color: string;
};

interface IAddShopBottomSheetModal {
  bottomSheetRef: any;
  methods: any;
  onSubmit: (values: FormValues) => void;
  onOpenScanModal?: () => void;
  onOpenShopSystemModal?: () => void;
  loadingCreate?: boolean;
  scanButton?: boolean;
}

export const AddShopBottomSheetModal = ({
  bottomSheetRef,
  methods,
  onSubmit,
  onOpenScanModal,
  onOpenShopSystemModal,
  loadingCreate,
  scanButton,
}: IAddShopBottomSheetModal) => {
  const { t } = useTranslation();
  const { shopType }: any = useDashboardStore();
  const backBtn = () => {
    bottomSheetRef.current?.close();
  };

  const renderContent = () => (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={true}
      enableOnAndroid
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.contentContainer}>
        <FormProvider {...methods}>
          <View style={styles.viewPicker}>
            {/* <LabelPickerSelect required>{t('AUTH.SELECT_SHOP_SYSTEM')}</LabelPickerSelect> */}
            <View>
              <Text style={styles.labelStyle}>{t('AUTH.SELECT_SHOP_SYSTEM')}</Text>
              <TouchableOpacity
                onPress={onOpenShopSystemModal}
                style={[
                  styles.inputStyle,
                  {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                ]}
              >
                {shopType.name ? (
                  <Text>{shopType.name}</Text>
                ) : (
                  <Text style={styles.placeholder}>{t('AUTH.SELECT_SHOP_SYSTEM')}</Text>
                )}
                <Feather name="chevron-down" size={20} style={styles.arrowSelect} />
                {methods?.formState?.errors?.platform_name && (
                  <Text style={styles.inputValidation}>{methods?.formState?.errors?.platform_name?.message}</Text>
                )}
              </TouchableOpacity>

              <TextInput
                name="platform_name"
                // label={t('AUTH.SHOP_NAME')}
                style={[styles.inputStyle, { display: 'none' }]}
                required
                editable={false}
                defaultValue={shopType.value}
              />
            </View>
          </View>

          <TextInput
            name="name"
            label={t('AUTH.SHOP_NAME')}
            placeholder={t('AUTH.ENTER_YOUR_SHOP_NAME')}
            placeholderTextColor={Colors.light.placeholder}
            style={styles.inputStyle}
            required
          />

          {methods?.formState?.errors?.name && (
            <TextInputValidation>{t(methods?.formState?.errors?.name?.message)}</TextInputValidation>
          )}

          <TextInput
            name="client_key"
            label={t('AUTH.CLIENT_KEY')}
            placeholder={t('AUTH.ENTER_YOUR_CLIENT_KEY')}
            placeholderTextColor={Colors.light.placeholder}
            style={styles.inputStyle}
            required
          />
          {methods?.formState?.errors?.client_key && (
            <TextInputValidation>{t(methods?.formState?.errors?.client_key?.message)}</TextInputValidation>
          )}

          {shopType.name === 'Shopify' ? null : (
            <View>
              <TextInput
                name="secret_key"
                label={t('AUTH.SECRET_KEY')}
                placeholder={t('AUTH.ENTER_YOUR_SECRET_KEY')}
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
                required
              />
              {methods?.formState?.errors?.secret_key && (
                <TextInputValidation>{t(methods?.formState?.errors?.secret_key?.message)}</TextInputValidation>
              )}
            </View>
          )}

          <View>
            <Text style={styles.labelPickerSelect}>{t('AUTH.SHOP_URL')}</Text>

            <TextInput
              name="shop_url"
              autoCapitalize="none"
              placeholder={t('AUTH.ENTER_YOUR_SHOP_URL')}
              placeholderTextColor={Colors.light.placeholder}
              style={[styles.inputStyle]}
            />

            {methods?.formState?.errors?.shop_url && (
              <TextInputValidation>{t(methods?.formState?.errors?.shop_url?.message)}</TextInputValidation>
            )}
          </View>

          <View style={styles.bottom}>
            <Button
              disabled={loadingCreate}
              label={!loadingCreate ? `${t('AUTH.CREATE_SHOP')}` : `${t('AUTH.SYNC_WITH_SHOP')}`}
              onPress={methods.handleSubmit(onSubmit)}
              bg={'dark'}
            />
            {scanButton && (
              <>
                <Divider label={'AUTH.OR'} />
                <Button
                  icon={<Image source={Images.scanQRCode} style={{ width: 24, height: 24 }} />}
                  label={'AUTH.SCAN_QR_CODE'}
                  onPress={onOpenScanModal}
                  bg={'light'}
                />
              </>
            )}
          </View>
        </FormProvider>
      </View>
    </KeyboardAwareScrollView>
  );

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight={false}
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.ADD_NEW_SHOP')}`, backBtn, `${t('AUTH.CLOSE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  contentContainer: {
    backgroundColor: Colors.light.white,
    padding: 24,
  },
  labelPickerSelect: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  labelStyle: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  inputStyle: {
    backgroundColor: Colors.light.white,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputStyleEnterShop: {
    borderTopStartRadius: 0,
    borderBottomStartRadius: 0,
    width: Dimensions.get('window').width / 1.47,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  inputStyleShopURL: {
    backgroundColor: Colors.light.mystic,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderBottomStartRadius: 8,
    borderTopStartRadius: 8,
    height: 54,
    width: Platform.OS === 'android' ? 100 : hasNotch() ? 80 : 80,
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewPicker: {
    marginBottom: 16,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  bottom: {
    alignItems: 'center',
    paddingTop: 24,
  },
  submitBtn: {
    backgroundColor: Colors.light.shark,
    borderRadius: 8,
    width: '100%',
  },
  submitBtnText: {
    textAlign: 'center',
    paddingVertical: 20,
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.light.background,
  },
  orText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginVertical: 16,
  },
  scanQRcode: {
    backgroundColor: Colors.light.athensGray,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  scanQRcodeText: {
    textAlign: 'center',
    paddingVertical: 20,
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.light.mirage,
    paddingLeft: 8,
  },
  placeholder: {
    color: Colors.light.hitGray,
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
});
