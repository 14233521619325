import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, RefreshControl, ScrollView, StyleSheet, Text, View } from 'react-native';
import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { Chart } from '../../../../components/dashboard/Chart';
import ChartActions from '../../../../components/dashboard/ChartActions';
import StatBlocks from '../../../../components/dashboard/StatBlocks';
import { AvgBasket } from '../../../../components/dashboard/tabs/AvgBasket';
import { TotalSales } from '../../../../components/dashboard/tabs/TotalSales';
import { useShopStore } from '../../../../store/shops.state';
import Colors from '../../../../constants/Colors';
import { useAuthentication } from '../../../../hooks/useAuthentication';
import { useSpaceStore } from '../../../../store/spaces.state';
import OrderStatus from '../../../../components/dashboard/OrderStatus';
import OrderPaymentStatus from '../../../../components/dashboard/OrderPaymentStatus';
import api from '../../../../config/api';
import { useDashboardStore } from '../../../../store/dashboard.state';
import { getDateString } from '../../../../utils/getDateString';

const Graphs = ({ fetchDashboard, route }: any) => {
  const [value, setValue] = useState<any>({});
  const [state, setState] = useState({ isRefreshing: false });
  const [index, setIndex] = useState(0);
  const { defaultSpace }: any = useSpaceStore();
  const { paymentStatus } = useAuthentication();
  const { graphInterval, dateInterval, activeShop }: any = useDashboardStore();
  const statusBody = {
    shopId: activeShop.id,
    period: graphInterval,
    from: getDateString(new Date(dateInterval?.startDate)),
    to: getDateString(new Date(dateInterval?.endDate)),
    type: 0,
  };

  const getPaymentStatus = async (spaceID: string) => {
    const response = await paymentStatus(spaceID, statusBody);
    setValue({
      orderStatuses: response?.data?.orderStatuses,
      paymentStatuses: response?.data?.paymentStatuses,
    });
  };

  const { activeShops }: any = useShopStore();
  const [colorShop, setColorShops] = useState('');
  const updateShops = async () => {
    const response = await api('/shops/space/' + defaultSpace.id, 'GET', null);
    const promises = activeShops.map(async (shop: any) => {
      const shopFound = response.data.find((item: any) => item.id === shop.id);
      return { shopName: shopFound.name, color: shopFound.color };
    });
    const colors = await Promise.all(promises);
    setColorShops(colors);
  };

  useEffect(() => {
    getPaymentStatus(defaultSpace?.id);
  }, [activeShop, graphInterval]);

  const { t } = useTranslation();

  const onRefresh = () => {
    fetchDashboard();
    getPaymentStatus(defaultSpace?.id);
  };

  const tabviewheight = (totalActiveShops: any) => {
    switch (totalActiveShops) {
      case 1:
        return 150;
      case 2:
        return 220;
      case 3:
        return 270;
      case 4:
        return 340;
      case 5:
        return 410;
      case 6:
        return 480;
      case 7:
        return 550;
      case 8:
        return 620;
      case 9:
        return 690;
      case 10:
        return 760;
    }
  };
  const heightValue = tabviewheight(activeShops.length);

  const routes = [
    { key: 'totalSales', title: `${t('AUTH.TOTAL_SALES')}` },
    { key: 'avgBasket', title: `${t('AUTH.AVG_BASKET')}` },
    // { key: 'conversion', title: `${t('AUTH.CONVERSATION')}` },
    // { key: 'visitors', title: `${t('AUTH.VISITORS')}` },
  ];

  const renderScene = SceneMap({
    totalSales: () => <TotalSales colorShop={colorShop} />,
    avgBasket: () => <AvgBasket colorShop={colorShop} />,
    // conversion: () => <Conversion />,
    // visitors: () => <Visitors />,
  });

  const renderTabBar = (props: any) => {
    return (
      <TabBar
        {...props}
        renderLabel={({ focused, route }) => {
          return <Text style={[styles.tabBarTitle, focused && styles.tabBarTitleActive]}>{route.title}</Text>;
        }}
        scrollEnabled={false}
        renderIndicator={() => {}}
        style={styles.tabBar}
        tabStyle={styles.tabBarStyle}
      />
    );
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.container}
      refreshControl={<RefreshControl refreshing={state.isRefreshing} onRefresh={onRefresh} />}
    >
      <View style={styles.blockCharts}>
        <ChartActions />

        <View style={styles.stats}>
          <Chart />
        </View>
      </View>
      <View>
        <StatBlocks />
      </View>
      {/* <View style={[styles.container, { height: heightValue }]}>
        <TabView
          lazy
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          initialLayout={styles.initialLayout}
          renderTabBar={renderTabBar}
        />
      </View> */}
      {activeShop.id && (
        <>
          <View
            style={[
              styles.container,
              value?.orderStatuses && Object.keys(value.orderStatuses).length > 1 && styles.statusBoxes,
            ]}
          >
            {value?.orderStatuses && Object.keys(value.orderStatuses).length > 1 && (
              <Text style={styles.heading}>Order Status</Text>
            )}
            {value?.orderStatuses
              ? Object.keys(value?.orderStatuses).map((shopName) => (
                  <OrderStatus key={shopName} shopName={shopName} shopIteam={value?.orderStatuses} />
                ))
              : null}
          </View>

          <View
            style={[
              styles.container,
              value?.paymentStatuses && Object.keys(value.paymentStatuses).length > 1 && styles.statusBoxes,
            ]}
          >
            {value?.paymentStatuses && Object.keys(value.paymentStatuses).length > 1 && (
              <Text style={styles.heading}>Payment Status</Text>
            )}

            {value?.paymentStatuses
              ? Object.keys(value?.paymentStatuses).map((shopName) => (
                  <OrderPaymentStatus key={shopName} shopName={shopName} shopIteam={value?.paymentStatuses} />
                ))
              : null}
          </View>
        </>
      )}
    </ScrollView>
  );
};

export default Graphs;

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    // flex: 1,
  },
  heading: {
    fontSize: 12,
    fontWeight: '600',
  
    lineHeight: 14,
    color: Colors.light.shark,
  },
  statusBoxes: {
    marginBottom: 8,
    paddingTop: 16,
  },
  initialLayout: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height,
  },
  blockCharts: {
    flex: 2,
    borderRadius: 6,
  },
  tabBar: {
    backgroundColor: Colors.light.background,
    borderBottomColor: Colors.light.background,
  },
  tabBarStyle: {
    width: 'auto',
    marginRight: 10,
  },
  tabBarTitle: {
    fontSize: 16,
    fontWeight: '600',
    color: Colors.light.hitGray,
  },
  tabBarTitleActive: {
    color: Colors.light.shark,
  },
  scanQRcode: {
    backgroundColor: Colors.light.athensGray,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  scanQRcodeText: {
    textAlign: 'center',
    paddingVertical: 20,
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.light.mirage,
    paddingLeft: 8,
  },
  stats: {
    paddingHorizontal: 5,
    backgroundColor: Colors.light.white,
    borderRadius: 12,
  },
});
