import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Colors from '../../../constants/Colors';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import { useTranslation } from 'react-i18next';
import { useDashboardStore } from '../../../store/dashboard.state';
import { Feather, FontAwesome } from '@expo/vector-icons';

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 48,
    paddingHorizontal: 16,
  },
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  name: {
    fontWeight: '500',
    flex: 1,
  },
  image: {
    marginRight: 12,
  },
  circle: {
    // backgroundColor: Colors.light.blueRibbon,
  },
});

interface IShopSystemBottomSheetModal {
  bottomSheetRef: any;
  goBackBtn: () => void;
  onOpenModal?: () => void;
  roles: any;
  methods: any;
}

export const SelectRoleBottomSheetModal = ({
  bottomSheetRef,
  goBackBtn,
  roles,
  methods,
}: IShopSystemBottomSheetModal) => {
  const { t } = useTranslation();
  const { selectedRoleInvite, setSelectedRoleInvite }: any = useDashboardStore();
  const backBtn = () => {
    goBackBtn();
  };

  const onSelectedRole = async (role: any) => {
    setSelectedRoleInvite({ name: role.name, id: role.id });
    methods.setValue('role_id', role.id);
  };

  const renderContent = () => {
    return (
      <View style={styles.container}>
        {roles.map((item: any, index) => {
          return (
            <TouchableOpacity style={styles.touchable} onPress={() => onSelectedRole(item)}>
              <Text style={styles.name}>{item.name}</Text>
              <View style={styles.circle}>
                {item.id === selectedRoleInvite.id ? (
                  <FontAwesome name="dot-circle-o" size={20} color={Colors.light.blueRibbon} />
                ) : (
                  <Feather name="circle" size={20} color={Colors.light.silver} />
                )}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.SELECT_ROLE')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
