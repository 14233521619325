import React, { ReactNode, memo } from 'react';
import { Text, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';

type LabelPickerSelectProps = {
    children: ReactNode;
    required?: boolean;
}

const LabelPickerSelect = ({ children, required }: LabelPickerSelectProps) => {
    return <Text style={styles.label}>{children}{required && <Text style={styles.asterisk}>*</Text>}</Text>;
}

export default memo(LabelPickerSelect);

const styles = StyleSheet.create({
    label: {
        paddingBottom: 6,
        fontSize: 14,
        fontWeight: '500',
    },
    asterisk: {
        color: Colors.light.hitGray,
        fontSize: 14,
        lineHeight: 16,
        fontWeight: '500',
    },
});