import { useState } from 'react';
import { useDashboardStore } from '../store/dashboard.state';
import api from '../config/api';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import { intervals, graphTypes } from '../utils/dashboard';
import { getDateString } from '../utils/getDateString';
import { useSpaceStore } from '../store/spaces.state';


export function useDashboard() {
  const [loading, setLoading] = useState<boolean>(false);
  const { defaultSpace }: any = useSpaceStore();

  const {
    graphInterval,
    dateInterval,
    setTurnover,
    setAverageBasket,
    setCro,
    setTotalVisitors,
    setTotalOrders,
    setSalesByShop,
    setMergeSales,
    setCompareSales,
    activeShop,
  }: any = useDashboardStore();

  const { t } = useTranslation();
  const fetchDashboard = async () => {
    let body: any = {
      shopId: activeShop.id,
      period: graphInterval,
      type: 0,
      from: getDateString(new Date()), // set default start date
      to: getDateString(new Date()), // set default end date
    };

    if (dateInterval?.startDate && dateInterval?.endDate) {
      body = {
        ...body,
        from: getDateString(new Date(dateInterval?.startDate)),
        to: getDateString(new Date(dateInterval?.endDate)),
      };
    }

    try {
      setLoading(true)
      const response = await api(`/dashboard/${defaultSpace?.id}`, 'POST', body);
   
      if (response?.status === false) return;
      const data = response?.data;

      setTotalOrders({
        number: data?.totalOrders || data?.totalOrders === 0 ? data?.totalOrders : null,
        difference: data?.difference || data?.difference === 0 ? data?.difference : null,
        isPozitive: Boolean(data?.isPozitive) ? data?.isPozitive : null,
      });
      setTurnover({
        number: data?.totalSales || data?.totalSales === 0 ? data?.totalSales : null,
        difference: data?.difference || null,
        isPozitive: Boolean(data?.isPozitive) ? data?.isPozitive : null,
      });
      setAverageBasket({
        number: data?.avgBasket || data?.avgBasket === 0 ? data?.avgBasket : null,
        difference: data?.difference || data?.difference === 0 ? data?.difference : null,
        isPozitive: Boolean(data?.isPozitive) ? data?.isPozitive : null,
      });
      setCro({
        number: data?.cro || data?.cro === 0 ? data?.cro : null,
        difference: data?.difference || data?.difference === 0 ? data?.difference : null,
        isPozitive: Boolean(data?.isPozitive) ? data?.isPozitive : null,
      });
      setTotalVisitors({
        number: data?.totalVisitors || data?.totalVisitors === 0 ? data?.totalVisitors : null,
        difference: data?.difference || data?.difference === 0 ? data?.difference : null,
        isPozitive: Boolean(data?.isPozitive) ? data?.isPozitive : null,
      });
      setSalesByShop(data?.salesByShop || []);

      const mergeSalesData = data?.graphMergedSales;
      const compareSalesData = data?.graphComparedSales;

      switch (graphInterval) {
        case intervals[0]:
          setMergeSales(mergeSalesData?.formattedHourlySales || []);
          setCompareSales(compareSalesData?.totalHourlySales || []);
          break;
        case intervals[1]:
          setMergeSales(mergeSalesData?.formattedDailySales || []);
          setCompareSales(compareSalesData?.totalDailySales || []);
          break;
        case intervals[2]:
          setMergeSales(mergeSalesData?.formattedWeeklySales || []);
          setCompareSales(compareSalesData?.formattedWeeklyResults || []);
          break;
        case intervals[3]:
          setMergeSales(mergeSalesData?.formattedMonthlySales || []);
          setCompareSales(compareSalesData?.formattedMonthlySales || []);
          break;
        // case intervals[4]:
        //   setMergeSales(mergeSalesData?.totalYearlySales || []);
        //   setCompareSales(compareSalesData?.totalYearlySales || []);
        //   break;
        default:
          setMergeSales([]);
          break;
      }
    } catch (error) {
   

      Toast.show({
        type: 'error',
        text1: `Statistics error`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const reloadShopData = async (id: string) => {
    
    let body: any = {
      shop_id: activeShop?.id || '', 
    };

    try {
      setLoading(true)
      await api(`/shop/orders/update/${id}`, 'post', activeShop.id && body).then((response) => {
 
        if (response?.status === false) return
      });
    
    } catch (error) { 
       
      Toast.show({
        type: 'error',
        text1: `Statistics error`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchDashboard,
    loading,
    reloadShopData,
  };
}
