import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../ui/HeaderBottomSheet';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Colors from '../../constants/Colors';
import PasswordTextInput from '../form/PasswordTextInput';
import TextInputValidation from '../form/TextInputValidation';
import api from '../../config/api';
import Toast from 'react-native-toast-message';

interface VerifyUserIdentityModalProps {
    bottomSheetRef: any;
    callback: any;
    title: string;
    subTitle: string;
    actionText: string;
    text?: string;
}

const VerifyUserIdentityModal = ({bottomSheetRef, callback, title, text, subTitle, actionText}: VerifyUserIdentityModalProps) => {
    const { t } = useTranslation();

    const { ...methods } = useForm({
        resolver: yupResolver(yup.object({
          current_password: yup.string().required('VALIDATIONS.REQUIRED_PASSWORD')
        })),
        mode: 'all',
    });

    const onSubmit = async ({current_password}: any) => {
        const data = { password: current_password }
        try{
          const response = await api('/user/verify/identity', 'POST', data);
          if(response.success) {
            callback();
          } else {
            Toast.show({
              type: 'error',
              text1: `${t('AUTH.CURRENT_PASSWORD_INCORRECT')}`,
              visibilityTime: 5000,
            });
          }
        } catch(error) {
          Toast.show({
            type: 'error',
            text1: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
            visibilityTime: 5000,
          });
        }
    }

    const renderContent = () => (
        <View style={styles.modalContent}>
          {text && (
            <View style={{ marginBottom: 30 }}>
                <Text style={styles.modalContentText}>{t(text)}</Text>
            </View>
          )}

          <View style={{ marginBottom: 30 }}>
            <Text style={styles.modalSubTitle}>{t(subTitle)}</Text>
          </View>

          <View>
            <FormProvider {...methods}>
              <PasswordTextInput
                name="current_password"
                label={'AUTH.CURRENT_PASSWORD'}
                placeholder={t('AUTH.CURRENT_PASSWORD')}
                placeholderTextColor={Colors.light.placeholder}
                style={[styles.inputStyle]}
                required
              />
  
              {methods.formState.errors.current_password && (
                <TextInputValidation>{t(methods?.formState?.errors?.current_password?.message)}</TextInputValidation>
              )}
  
              <View style={styles.buttons}>
                <TouchableOpacity onPress={methods.handleSubmit(onSubmit)} style={styles.btnLight}>
                  <Text style={[styles.btnLabel, { color: Colors.light.red}]}>{t(actionText)}</Text>
                </TouchableOpacity>
  
                <TouchableOpacity onPress={() => bottomSheetRef.current?.close()} style={styles.btnDark}>
                  <Text style={[styles.btnLabel, { color: Colors.light.white}]}>{t('AUTH.CANCEL')}</Text>
                </TouchableOpacity>
              </View>
            </FormProvider>
          </View>
        </View>
    );

    return(
        <Portal>
            <Modalize
                ref={bottomSheetRef}
                scrollViewProps={{ showsVerticalScrollIndicator: false }}
                adjustToContentHeight
                HeaderComponent={HeaderBottomSheet(`${t(title)}`, () => bottomSheetRef.current?.close() , `${t('AUTH.CANCEL')}`)}
            >
                {renderContent()}
            </Modalize>
        </Portal>
    );
}

export default VerifyUserIdentityModal;


const styles = StyleSheet.create({
  modalContent: {
    padding: 24,
  },
  modalContentText: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 22,
  },
  modalSubTitle: {
    fontWeight: '600',
    fontSize: 14,
    color: Colors.light.blueCharcoal,
  },
  buttons: { 
    flexDirection: 'row',
    marginTop: 16,
  },
  btnLight: { 
    height: 54,
    flex: 1,
    backgroundColor: Colors.light.gray,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  btnDark: { 
    height: 54,
    flex: 1,
    backgroundColor: Colors.light.shark,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnLabel: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
})