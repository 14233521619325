import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

// Screens
import BottomTabsStack from './BottomTabsStack';
import SpaceScreen from '../screens/dashboard/spaces';
import { SpaceDetails } from '../components/settings/spaces/SpaceDetails';
import { UsersRoles } from '../components/settings/users/UsersRoles';
import { Company } from '../components/settings/company/Company';
import { AccountSettings } from '../components/settings/account/AccountSettings';
import { EditAccount } from '../components/settings/account/EditAccount';
import { UserAbout } from '../components/settings/about/UserAbout';
import ChangePassword from '../components/settings/account/ChangePassword';

// Auth Store
import { useAuthStore } from '../store/auth.state';
import { useSpaceStore } from '../store/spaces.state';
import { EditCompanyInformations } from '../components/settings/company/EditCompanyInformations';
import { EditCompanyAddress } from '../components/settings/company/EditCompanyAddress';
import { EditContactDetails } from '../components/settings/company/EditContactDetails';
import PopMenu from '../components/shops/PopMenu';
import EditSpaceInformation from '../components/settings/spaces/EditSpaceInformation';

export type RootTabParamList = {
  Dashboard: undefined;
};

const Stack = createStackNavigator();

export default function UserStack() {
  const { isAuthenticated }: any = useAuthStore();
  const { spacePerUser }: any = useSpaceStore();

  const isAuthSpaces = isAuthenticated && spacePerUser?.length >= 1;

  return (
    <Stack.Navigator initialRouteName={isAuthSpaces ? 'Dashboard' : 'Spaces'}>
      <Stack.Screen name="Dashboard" component={BottomTabsStack} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="Spaces" component={SpaceScreen} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="SpaceDetails" component={SpaceDetails} options={{ headerShown: false }} />
      <Stack.Screen name="EditSpaceInformation" component={EditSpaceInformation} options={{ headerShown: false }} />
      <Stack.Screen name="UsersRole" component={UsersRoles} options={{ headerShown: false }} />
      <Stack.Screen name="Company" component={Company} options={{ headerShown: false }} />
      <Stack.Screen name="AccountSettings" component={AccountSettings} options={{ headerShown: false }} />
      <Stack.Screen name="EditAccount" component={EditAccount} options={{ headerShown: false }} />
      <Stack.Screen name="ChangePassword" component={ChangePassword} options={{ headerShown: false }} />
      <Stack.Screen name="UserAbout" component={UserAbout} options={{ headerShown: false }} />
      <Stack.Screen name="PopMenu" component={PopMenu} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="EditCompanyInformations" component={EditCompanyInformations} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="EditCompanyAddress" component={EditCompanyAddress} options={{ headerShown: false, gestureEnabled: false }} />
      <Stack.Screen name="EditContactDetails" component={EditContactDetails} options={{ headerShown: false, gestureEnabled: false }} />
    </Stack.Navigator>
  );
}
