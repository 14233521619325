import React, { memo } from 'react';
import { useDashboardStore } from '../../../../../store/dashboard.state';
import DashboardLineChart from '../../LineChart';

const LineChartMerge = () => {
  const { mergeSales }: any = useDashboardStore();

  const dataSets = mergeSales[0]?.totalHour
    ? mergeSales.map((sale: any) => {
        if (Array.isArray(sale?.totalHour)) {
          return sale.totalHour;
        }
      })
    : [];

  const data = {
    labels: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
    ],
    data: dataSets,
  };

  const chartConfig = { strokeWidth: 5 };

  return <DashboardLineChart data={data} chartConfig={chartConfig} />;
};

export default memo(LineChartMerge);
