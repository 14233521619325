type Currency = 'EUR' | 'USD';

export const formatValueToFix = (value: number, currentLanguage?: string) => {
  if (!value) return '0';

  const separator = currentLanguage === 'de' ? '.' : ',';

  if (value >= 100) {
    return value?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  } else {
    return value.toFixed(0).toString();
  }
};

export const formatCurrency = (sum: number, currency: Currency, currentLanguage?: string) => {
  const formattedValue: string = formatValueToFix(sum, currentLanguage);

  if (currency?.toLocaleUpperCase() === 'EUR') {
    return `${formattedValue} €`;
  }
  if (currency?.toLocaleUpperCase() === 'USD') {
    return `${formattedValue} $`;
  }
  //In case the response of currency is null 
  return `${formattedValue} €`;
};
