import { StackScreenProps } from '@react-navigation/stack';
import React, { memo, useEffect, useRef, useState } from 'react';
import { RefreshControl, ScrollView, StyleSheet, View, Image, Text } from 'react-native';
import Loading from '../../../../components/Loading';
import OnBoarding from '../../../../components/onBoarding/OnBoarding';
import Header from '../../../../components/ui/Header';
import api from '../../../../config/api';
import Colors from '../../../../constants/Colors';
import { useDashboard } from '../../../../hooks/useDashboard';
import { useDashboardStore } from '../../../../store/dashboard.state';
import { useShopStore } from '../../../../store/shops.state';
import { useSpaceStore } from '../../../../store/spaces.state';
import Graphs from './Graphs';
import NoShopView from './NoShopView';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import NoActiveShopView from './NoActiveShopView';

type StateProps = {
  loading: boolean;
  showOnboarding: boolean;
};

const DashboardScreen: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const [state, setState] = useState<StateProps>({
    loading: true,
    showOnboarding: false,
  });

  const { allShops, activeShops, setAllShops }: any = useShopStore();
  const { graphInterval , activeShop }: any = useDashboardStore();
  const { fetchDashboard, loading, reloadShopData }: any = useDashboard();
  const { defaultSpace }: any = useSpaceStore();
  const { t } = useTranslation();
  const didMountRef = useRef(false);

  const getAllShops = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    try {
      const response = await api('/shops/space/' + defaultSpace.id, 'GET', null);
      if (response) {
        setAllShops(response?.data || []);
        setState((prevState) => ({ ...prevState, loading: true }));
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: true }));
    }
  };

  useEffect(() => {
    getAllShops();
  }, []);


  // useFocusEffect(
  //   useCallback(() => {
  //     let isActive = true;

  //     const refreshData = async () => {
  //       console.log('here is refresh');
  //       if (!isActive) return;
  //       try {
  //         // setLoadingD(true);
  //         await fetchDashboard();
  //         if (!isActive) return;
  //       } catch (error) {
  //         console.error(error);
  //       } finally {
  //         // setLoadingD(false);
  //       }
  //     };

  //     refreshData();

  //     return () => {
  //       isActive = false;
  //     };
  //   }, [])
  // );

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setState((prevState) => ({ ...prevState, showOnboarding: true }));
  //   }, 3000);

  //   return () => clearTimeout(timeout);
  // }, []);

  const onRefresh = async () => {
    // setLoadingR(true);
    await reloadShopData(defaultSpace?.id);
    await fetchDashboard();
    // setLoadingR(false);
  };

  useFocusEffect(
    React.useCallback(() => {
      // Skip the fetchDashboard() call when the component mounts
      if (didMountRef.current) {
        fetchDashboard();
      } else {
        didMountRef.current = true;
      }
    }, [graphInterval, state, activeShop])
  );
  return (
    <>
      <View style={styles.viewContainer}>
        {/* TODO: We need to make this loader as a component */}
        {loading === true && activeShops?.length !== 0 && (
          <View style={styles.loadingContainer}>
            <Image
              source={require('../../../../assets/images/loader-stathub.gif')} // Specify the path to your GIF file
              style={styles.gif}
            />
            <View style={styles.loadingText}>
              <Text style={styles.loadingText}> {t('AUTH.LOADING_TEXT')}</Text>
            </View>
          </View>
        )}
        <Header screenName="Dashboard" onPress={() => navigation.navigate('Settings')} />

        <ScrollView
          showsVerticalScrollIndicator={false}
          refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={false} />}
        >
          {/* {loading === true && <Loading />} */}
          {activeShops?.length !== 0 && <Graphs fetchDashboard={fetchDashboard} />}
          {allShops?.length === 0 && activeShops?.length === 0 && (
            <NoShopView fetchDashboard={fetchDashboard} navigation={navigation} />
          )}
          {allShops?.length !== 0 && activeShops?.length === 0 && (
            <NoActiveShopView fetchDashboard={fetchDashboard} navigation={navigation} />
          )}
        </ScrollView>
      </View>
      {/* } */}

      {/* {state.showOnboarding && <OnBoarding />} */}
    </>
  );
};
export default memo(DashboardScreen);

const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
    paddingTop: 16,
    paddingHorizontal: 16,
    backgroundColor: Colors.light.background,
  },
  gif: {
    width: 16,
    height: 16,
  },
  loadingText: {
    marginLeft: 2, 
    fontSize: 12

  },
  loadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 35,
  },
});
