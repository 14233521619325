import React from 'react';
import ListItemContainer from '../../ui/ListItemContainer';
import ListItem from '../../ui/ListItem';
import ListItemDivider from '../../ui/ListItemDivider';

type CompanyInformationsProps = {
  values: any;
};

const CompanyInformations = ({ values }: CompanyInformationsProps) => {
  return (
    <ListItemContainer>
      <ListItem label={'AUTH.COMPANY_NAME'} value={values.company} />
      <ListItemDivider />
      <ListItem label={'AUTH.INDUSTRY'} value={values.matchedObject} />
    </ListItemContainer>
  );
};

export default CompanyInformations;
