import React, { memo } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import Colors from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import { Amazon, Ebay, Magento, Oxid, Shopify, Shopware5, Shopware6, WooCommerce } from '../../assets/images';

type ListItemProps = {
  shopImg: any;
  label: string;
  status: string;
  platformType: number;
  icon: any;
};

const renderPlatformInfo = (platformType: number) => {
  let platformLogo;

  switch (platformType) {
    case 0:
      platformLogo = <Shopware6 />;
      break;
    case 1:
      platformLogo = <Shopware5 />;
      break;
    case 2:
      platformLogo = <Shopify />;
      break;
    case 3:
      platformLogo = <WooCommerce />;
      break;
    case 4:
      platformLogo = <Magento />;
      break;
    case 5:
      platformLogo = <Oxid />;
      break;
    case 6:
      platformLogo = <Ebay />;
      break;
    case 7:
      platformLogo = <Amazon />;
      break;
    default:
      platformLogo = null;
  }

  return <View style={styles.image}>{platformLogo}</View>;
};

const ListItem = ({ shopImg, label, status, icon, platformType }: ListItemProps) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <Image source={shopImg} style={styles.imageShop} />
        <View>
          <Text style={styles.label}>{label}</Text>
          {renderPlatformInfo(platformType)}
        </View>
      </View>

      <View style={styles.status}>
        <Text style={{ color: status.toLowerCase() === 'active' ? Colors.light.green : Colors.light.grayDark0 }}>
          {status}
        </Text>
      </View>
    </View>
  );
};

export default memo(ListItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: Colors.light.gray,
    padding: 14,
    borderRadius: 8,
    marginVertical: 4,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  label: {
    fontSize: 14,
    fontWeight: '500',
  },
  status: {
    fontSize: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageShop: {
    width: 24,
    height: 24,
    marginRight: 12,
    backgroundColor: '#AAB3C6',
    borderRadius: 4,
  },
  image: {
    width: 14,
    height: 14,
    marginTop: 5,
  },
});
