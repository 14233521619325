import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';

const ListItemDivider = () => <View style={styles.container} />

export default memo(ListItemDivider);

const styles = StyleSheet.create({
    container: {
        width: '100%',
        borderColor: Colors.light.grayDark,
        borderWidth: 0.5,
    }
});