import React, { ReactNode, memo } from 'react';
import { Text, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';

type TextInputValidationProps = {
    children: ReactNode;
}

const TextInputValidation = ({ children }: TextInputValidationProps) => <Text style={styles.validation}>{children}</Text>;

export default memo(TextInputValidation);

const styles = StyleSheet.create({
    validation: {
        fontSize: 12,
        color: Colors.light.red,
        paddingBottom: 16,
        marginTop: -12,
    }
});