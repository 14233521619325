import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Colors from '../../../constants/Colors';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import { useTranslation } from 'react-i18next';
import shops from '../../../utils/shops';
import { useDashboardStore } from '../../../store/dashboard.state';
import {
  AmazonIcon,
  EbayIcon,
  MagentoIcon,
  OxidIcon,
  ShopifyIcon,
  Shopware5Icon,
  Shopware6Icon,
  WooCommerceIcon,
} from '../../../assets/images';
import { Feather, FontAwesome } from '@expo/vector-icons';

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 48,
    paddingHorizontal: 16,
  },
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  name: {
    fontWeight: '500',
    flex: 1,
  },
  image: {
    marginRight: 12,
  },
  circle: {
    // backgroundColor: Colors.light.blueRibbon,
  },
});

interface IShopSystemBottomSheetModal {
  bottomSheetRef: any;
  goBackBtn: () => void;
  onOpenModal?: () => void;
}

export const ShopSystemBottomSheetModal = ({ bottomSheetRef, goBackBtn }: IShopSystemBottomSheetModal) => {
  const { t } = useTranslation();
  const { shopType, setShopType }: any = useDashboardStore();

  const backBtn = () => {
    goBackBtn();
  };

  const renderPlatformInfo = (platformType: number) => {
    let platformLogo;

    switch (platformType) {
      case 0:
        platformLogo = <Shopware6Icon />;
        break;
      case 1:
        platformLogo = <Shopware5Icon />;
        break;
      case 2:
        platformLogo = <ShopifyIcon />;
        break;
      case 3:
        platformLogo = <WooCommerceIcon />;
        break;
      case 4:
        platformLogo = <MagentoIcon />;
        break;
      case 5:
        platformLogo = <OxidIcon />;
        break;
      case 6:
        platformLogo = <EbayIcon />;
        break;
      case 7:
        platformLogo = <AmazonIcon />;
        break;
      default:
        platformLogo = null;
    }

    return <View style={styles.image}>{platformLogo}</View>;
  };

  const onChangeShop = async (shop: any) => {
    setShopType({ value: shop.value, name: shop.label });
    goBackBtn();
  };

  const renderContent = () => {
    return (
      <View style={styles.container}>
        <Text
          style={{
            fontSize: 17,
            paddingBottom: 10,
            lineHeight: 17,
            fontWeight: 500,
          }}
        >
          {t('Select shop system')}
        </Text>
        {shops.map((item) => {
          return (
            <TouchableOpacity style={styles.touchable} onPress={() => onChangeShop(item)}>
              {renderPlatformInfo(item.value)}
              <Text style={styles.name}>{item.label}</Text>
              <View style={styles.circle}>
                {item.value === shopType.value ? (
                  <FontAwesome name="dot-circle-o" size={20} color={Colors.light.blueRibbon} />
                ) : (
                  <Feather name="circle" size={20} color={Colors.light.silver} />
                )}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('Select shop system')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
