import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import env from './constants';

const api = async (path: string, method: string, body?: any) => {
  const authStorage: string | any = await AsyncStorage.getItem('stathubs-auth-storage');
  const authtoken = JSON.parse(authStorage)?.state?.token ?? '';

  const options = {
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      ...(authtoken && { Authorization: 'Bearer ' + authtoken }),
    },
    method: method,
    url: env.API_URL + path,
    ...(body && { data: body }),
  };

  return axios(options)
    .then((response: any) => {
      return Promise.resolve(response.data);
    })
    .catch((error: any) => {
      return Promise.reject(error.response.data);
    });
};
export default api;
