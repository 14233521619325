import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';

type DividerProps = {
    label: string;
}

const Divider = ({ label }: DividerProps) => {
    const { t } = useTranslation();
    return(
        <View style={styles.divider}>
            <Text style={styles.text}>{t(label)}</Text>
        </View>
    )
}

export default memo(Divider);

const styles = StyleSheet.create({
    divider: {
        marginVertical: 16,
    },
    text: {
        color: Colors.light.hitGray
    },
});