import { useNavigation } from '@react-navigation/native';
import api from '../config/api';
import { useAuthStore } from '../store/auth.state';
import { useConfigStore } from '../store/config.state';

export function useAuthentication() {
  const { logoutUser }: any = useAuthStore();
  const navigation = useNavigation();
  const { currentLanguage }: any = useConfigStore();

  const userDetails = async (data: any) => {
    if (!data) return;
    const response = await api('/user/' + data, 'GET', null);
    return response;
  };

  const registerUser = async (data: any) => {
    const modifiedData = {
      ...data,
      language: currentLanguage,
    };

    if (!modifiedData) return;
    const response = await api('/user/register', 'POST', modifiedData);
    return response;
  };

  const forgotPassword = async (data: any) => {
    if (!data) return;
    const response = await api('/user/forgot-password', 'POST', data);
    return response;
  };

  const loginUser = async (email: string, password: string) => {
    if (!email || !password) return;
    const response = await api('/user/login', 'POST', { email, password });
    return response;
  };

  const spaceByUser = async () => {
    const response = await api('/space/user_spaces', 'GET', null);
    return response;
  };

  const logout = () => {
    logoutUser();
    navigation.reset({ routes: [{ name: 'Auth' }] });
  };

  const paymentStatus = async (spaceID: string, statusBody: any) => {
    const response = await api('/dashboard/' + spaceID, 'POST', statusBody);
    return response;
  };

  const orderStatusMethod = async (shopID: string) => {
    const response = await api('/shop/' + shopID, 'GET', null);
    return response;
  };

  const shopUpdate = async (shopID: string) => {
    const response = await api('/shop/update/' + shopID, 'POST', null);
    return response;
  };

  const updateLanguage = async (language: string) => {
    const languageApi = await api('/user/update-language', 'POST', { language });
    return languageApi;
  };

  return {
    logout,
    loginUser,
    orderStatusMethod,
    spaceByUser,
    shopUpdate,
    updateLanguage,
    registerUser,
    userDetails,
    forgotPassword,
    paymentStatus,
  };
}
