import React, { memo } from 'react';
import { StyleSheet, View, Text, Platform, Image, TouchableOpacity } from 'react-native';
import hasNotch from '../../utils/isNotch';
import { StathubsLogo } from '../../assets/images/index';
import Colors from '../../constants/Colors';
import Images from '../../theme/Images';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { useSpaceStore } from '../../store/spaces.state';
import { generateColorHsl } from '../../utils/generateRandomColors';
import { useAuthentication } from '../../hooks/useAuthentication';

interface HeaderProps {
  screenName?: any;
  values?: any;
  setValues?: any;
  onPress?: any;
}

const styles = StyleSheet.create({
  header: {
    fontSize: 26,
    fontWeight: 'bold',
    marginTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 24,
    paddingBottom: 12,
  },
  spaceHeader: {
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  headerDashboard: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 30,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  headerMainMenu: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
    paddingVertical: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,

  },
  headerSettings: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  headerSettingsDone: {
    justifyContent: 'flex-end',
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  image: {
    width: 106,
    height: 16,
  },
  headerTitle: {
    marginTop: Platform.OS === 'android' ? 22 : hasNotch() ? 22 : 44,
    fontSize: 24,
    fontWeight: 'bold',
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: Colors.light.riptide,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarText: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  logoutWrapper: { textTransform: 'uppercase', fontWeight: '500' },
});

const Header = ({ screenName, values, setValues, onPress }: HeaderProps) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { defaultSpace }: any = useSpaceStore();
  const { logout } = useAuthentication();
  const color = generateColorHsl(defaultSpace?.name || 'John Doe');

  switch (screenName) {
    case 'Auth':
      return (
        <View style={styles.header}>
          <StathubsLogo />
        </View>
      );
    
    case 'Spaces':
      return (
        <View style={styles.spaceHeader}>
          <StathubsLogo />
          {/* <TouchableOpacity onPress={logout}>
            <Text style={styles.logoutWrapper}>logout</Text>
          </TouchableOpacity> */}
        </View>
      );
    
    case 'Dashboard':
      return (
        <View style={styles.headerDashboard}>
          {/* <Image source={Images.logo} style={styles.image} />
          <TouchableOpacity onPress={onPress}>
            <View style={[styles.avatar, { backgroundColor: color }]}>
              <Text style={styles.avatarText}>{defaultSpace?.name?.slice(0, 1)}</Text>
            </View>
          </TouchableOpacity> */}
        </View>
      );

    case 'Main menu':
      return (
        <View style={styles.headerMainMenu}>
          <TouchableOpacity style={styles.goBack} onPress={() => navigation.goBack()}>
            <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
            <Text style={styles.goBackText}>{t('AUTH.MAIN_MENU')}</Text>
          </TouchableOpacity>
        </View>
      );

    case 'Settings':
      return (
        <View style={[styles.headerSettings, !!values.edit && styles.headerSettingsDone]}>
          {!values.edit ? (
            <>
              <TouchableOpacity style={styles.goBack} onPress={() => navigation.goBack()}>
                <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
                <Text style={styles.goBackText}>{t('AUTH.SETTINGS')}</Text>
              </TouchableOpacity>
              {values !== 'null' && (
                <TouchableOpacity onPress={() => setValues({ ...values, edit: true })}>
                  <Text style={styles.editText}>{t('AUTH.EDIT')}</Text>
                </TouchableOpacity>
              )}
            </>
          ) : (
            <TouchableOpacity onPress={() => setValues({ ...values, edit: false })}>
              <Text style={styles.editText}>{t('AUTH.DONE')}</Text>
            </TouchableOpacity>
          )}
        </View>
      );

    default:
      return (
        <View style={styles.header}>
          <Text style={styles.headerTitle}>{screenName}</Text>
        </View>
      );
  }
};

export default memo(Header);
