export default {
  logo: require('../assets/images/logo.png'),
  lngEN: require('../assets/images/icons/en.png'),
  lngDE: require('../assets/images/icons/de.png'),
  poweredLogo: require('../assets/images/powered-logo.png'),
  addShop: require('../assets/images/addShop.png'),
  shopify: require('../assets/images/Shopify.png'),
  magento: require('../assets/images/Magento.png'),
  shopware: require('../assets/images/Shopware.png'),
  googleIcon: require('../assets/images/GoogleIcon.png'),
  metaIcon: require('../assets/images/MetaIcon.png'),
  logoIcon: require('../assets/images/logo-icon.png'),
  shopIcon: require('../assets/images/shop.png'),
  graph: require('../assets/images/graph-1.png'),
  ukFlag: require('../assets/images/united-kingdom-flag.png'),
  deFlag: require('../assets/images/germany-flag.png'),
  usFlag: require('../assets/images/america-flag.png'),
  onboardingScreen1: require('../assets/images/onboarding-screen-1.png'),
  onboardingScreen2: require('../assets/images/onboarding-screen-2.png'),
  onboardingScreen3: require('../assets/images/onboarding-screen-3.png'),
  scanQRCode: require('../assets/images/qr-code-scan.png'),
};
