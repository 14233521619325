import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import Colors from '../../constants/Colors';
import Header from '../../components/ui/Header';
import { TextInput } from '../../components/form/TextInput';
import { signInValidation } from '../../components/form/Validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useAuthStore } from '../../store/auth.state';
import { useSpaceStore } from '../../store/spaces.state';
import { useTranslation } from 'react-i18next';
import LanguageSwitcherAPI from '../../components/ui/LanguageSwitcherAPI';
import Toast from 'react-native-toast-message';
import PasswordTextInput from '../../components/form/PasswordTextInput';
import TextInputValidation from '../../components/form/TextInputValidation';
import Button from '../../components/ui/action/Button';
import { useDashboardStore } from '../../store/dashboard.state';

type FormValues = {
  email: string;
  password: string;
};

const SignInScreen: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { authenticateUser }: any = useAuthStore();
  const { addSpaces, setDefaultSpace }: any = useSpaceStore();
  const { loginUser } = useAuthentication();
  const { setActiveShop }: any = useDashboardStore();

  const [values, setValues] = useState({
    loading: false,
    hasError: '',
  });

  const { ...methods } = useForm({
    resolver: yupResolver(signInValidation),
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
    const { email, password } = data;
    setValues({ ...values, loading: true, hasError: '' });

    try {
      const response = await loginUser(email, password);
      authenticateUser(response?.success || false, response);
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.LOGIN_SUCCESS')}`,
      });

      if (response?.message.includes('Invalid'))
        return setValues({
          ...values,
          loading: false,
          hasError:
            response.message ||
            Toast.show({
              type: 'error',
              text1: `${t('AUTH.INTERNAL_ERROR')}`,
            }),
        });

      if (response && response?.user?.spaces) {
        addSpaces(response?.user?.spaces);

        setDefaultSpace({
          id: response?.user?.spaces[0]?.id || '',
          name: response?.user?.spaces[0]?.name || '',
          code: response?.user?.spaces[0]?.code,
        });

        const firstActiveShop = response?.user?.spaces[0]?.shops.find((shop: any) => shop.status === 1);
        setActiveShop({
          name: firstActiveShop?.name || '',
          id: firstActiveShop?.id || '',
          shopCurrency: firstActiveShop?.currency_iso_code || '',
        });
      }

      navigation.reset({ routes: [{ name: 'Main' }] });
      setValues({ ...values, loading: false });
    } catch (error: any) {
      authenticateUser(false, null);
      setValues({
        ...values,
        loading: false,
        hasError:
          error?.message ||
          Toast.show({
            type: 'error',
            text1: `${t('AUTH.INTERNAL_ERROR')}`,
          }),
      });
    }
  };

  return (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={true}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.container}>
        <View style={styles.touchStyle}>
          <Header screenName="Auth" />
          <LanguageSwitcherAPI />
        </View>
        <View style={styles.main}>
          <View style={styles.content}>
            <Text style={styles.title}>{t('AUTH.WELCOME_BACK')}</Text>
            <Text style={styles.title}>
              {t('AUTH.PLEASE_SINGIN')}
              <Text style={styles.titleBold}> {t('AUTH.PLEASE_LOGIN_CONTINUE')}</Text>
              {t('AUTH.TO_CONTINUE')}
            </Text>
          </View>
          <View style={styles.boxContainer}>
            {!!values.hasError && (
              <View>
                <Text style={styles.errorText}>{values?.hasError}</Text>
              </View>
            )}
            <FormProvider {...methods}>
              <TextInput
                name="email"
                label={'AUTH.EMAIL'}
                placeholder={t('AUTH.ENTER_YOUR_EMAIL')}
                placeholderTextColor={Colors.light.placeholder}
                keyboardType="email-address"
                autoCapitalize="none"
                defaultValue=""
                style={styles.inputStyle}
                required
              />

              {methods?.formState?.errors?.email && (
                <TextInputValidation>{t(methods?.formState?.errors?.email?.message)}</TextInputValidation>
              )}

              <PasswordTextInput
                name="password"
                label={'AUTH.PASSWORD'}
                placeholder={t('AUTH.ENTER_YOUR_PASSWORD')}
                placeholderTextColor={Colors.light.placeholder}
                autoCapitalize="none"
                style={styles.inputStyle}
                required
              />
              {methods.formState.errors.password && (
                <TextInputValidation>{t(methods?.formState?.errors?.password?.message)}</TextInputValidation>
              )}

              <View style={styles.labelRight}>
                <Text style={styles.bottomFooterText}>{t('AUTH.FORGET_PASSWORD')}</Text>
                <TouchableOpacity onPress={() => navigation.navigate('ResetPassword')}>
                  <Text style={styles.labelBottom}>{t('AUTH.RESET_HERE')}</Text>
                </TouchableOpacity>
              </View>
            </FormProvider>

            <View style={styles.bottom}>
              <Button
                loading={values.loading}
                label={'Login'}
                onPress={methods.handleSubmit(onSubmit)}
                disabled={values.loading}
                bg={'dark'}
              />
            </View>

            <View style={styles.bottomFooter}>
              <Text style={styles.bottomFooterText}>{t('AUTH.NOT_MEMBER')}</Text>
              <TouchableOpacity onPress={() => navigation.navigate('SignUp')}>
                <Text style={styles.labelBottom}>{t('AUTH.REGISTER_NOW')}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

export default SignInScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.white,
  },
  imgStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textEn: {
    marginHorizontal: 5,
    fontWeight: '700',
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.white,
    flexGrow: 1,
  },
  main: {
    width: '100%',
  },
  content: {
    paddingTop: 32,
  },
  title: {
    fontSize: 24,
    width: Dimensions.get('window').width / 1.3,
  },
  titleBold: {
    fontWeight: '600',
  },
  errorText: {
    textAlign: 'center',
    color: Colors.light.red,
  },
  boxContainer: {
    paddingTop: 40,
  },
  inputStyle: {
    backgroundColor: Colors.light.white,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  touchStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  forgetPassword: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 10,
    fontWeight: 'bold',
  },
  resetPasswordText: {
    fontWeight: '600',
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  orPart: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  orLine: {
    width: '48%',
    height: 1,
    backgroundColor: Colors.light.seaShell,
    marginTop: 5,
    marginRight: 4,
  },
  orText: {
    textAlign: 'center',
    fontSize: 16,
    paddingVertical: 12,
    color: Colors.light.placeholder,
  },
  bottomFooter: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  labelRight: {
    marginTop: -8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  bottomFooterText: {
    fontSize: 14,
  },
  labelBottom: {
    paddingHorizontal: 3,
    fontSize: 14,
    fontWeight: '600',
  },
  image: {
    width: 106,
    height: 16,
  },
  flag: {
    width: 24,
    height: 24,
  },
});
