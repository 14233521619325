export const getMinMaxYGraphsValue = (data: any) => {
  const minYValue = Math.min(...data) ?? 0;
  const maxYValue = Math.max(...data) ?? 0;

  return {
    minYValue,
    maxYValue,
  };
};

export const formatGraphsYLabels = (value: string | number) => {
  const numericValue = Number(value);

  if (numericValue >= 1000) {
    const abbreviatedValue = Math.round(numericValue / 1000); // Round the abbreviated value
    return `${abbreviatedValue}k`;
  }

  return numericValue.toString();
};
