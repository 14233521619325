import React, { memo } from 'react';
// import { useDashboardStore } from '../../../../../store/dashboard.state';
// import { graphTypes } from '../../../../../utils/dashboard';
import LineChartMerge from './Merge';
// import LineChartCompare from './Compare';

const DashboardLineChartDaily = () => {
  // const { graphType }: any = useDashboardStore();

  return <LineChartMerge />;
  // return graphType === graphTypes[0] ? <LineChartMerge /> : <LineChartCompare />;
};

export default memo(DashboardLineChartDaily);
