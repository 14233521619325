import { Lato_400Regular, useFonts } from '@expo-google-fonts/lato';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { G, Rect, Text, Line } from 'react-native-svg';
import { Text as TextReact } from 'react-native';
import { BarChart, Grid, XAxis, YAxis } from 'react-native-svg-charts';
import Colors from '../../../constants/Colors';
import { useConfigStore } from '../../../store/config.state';
import { useDashboardStore } from '../../../store/dashboard.state';
import { formatGraphsYLabels, getMinMaxYGraphsValue } from '../../../utils/charts';
import { formatCurrency } from '../../../utils/formatCurrency';

interface IBarChart {
  data: any;
  chartConfig: any;
}

const DashboardStackedBarChart = ({ data, chartConfig }: IBarChart) => {
  useFonts({
    Lato_400Regular,
  });

  const { currentLanguage }: any = useConfigStore();
  const scrollViewRef = useRef<ScrollView>(null);
  const { salesByShop, activeShop }: any = useDashboardStore();
  const contentInset = { top: 24, bottom: 15 };
  const xAxisHeight = 20;
  const flatData = data.data.flatMap((item: any) => item.map((daily: { value: any }) => daily.value));
  const { minYValue, maxYValue } = getMinMaxYGraphsValue(flatData);
  const [selectedBarIndex, setSelectedBarIndex] = useState<number | null>(null);
  let transformedData;
  let initialColor = activeShop?.id ? salesByShop[0]?.color : Colors.light.blueRibbon;
  const [barColors, setBarColors] = useState<Array<string>>(flatData?.map((item: any) => initialColor));
  const [displayTurnover, setDisplayTurnover] = useState<boolean>(false);

  const handleBarPress = (index: number) => {
    setSelectedBarIndex(index);
    const newColors = flatData.map((_, i) => {
      return i === index ? initialColor : `${initialColor}90`;
    });
    setBarColors(newColors);
    setDisplayTurnover(true);
  };

  if (Platform.OS === 'web') {
    transformedData = flatData.map((item: any, index: any) => ({
      value: item,
      svg: {
        fill: barColors[index],
        onClick: () => handleBarPress(index),
      },
    }));
  } else {
    transformedData = flatData.map((item: any, index: any) => ({
      value: item,
      svg: {
        fill: barColors[index],
        onPress: () => handleBarPress(index),
      },
    }));
  }

  const Labels = ({ x, y, bandwidth, data }) =>
    flatData.map((value, index) => {
      // Check if the index matches the clicked bar
      if (index === 0 && index === selectedBarIndex) {
        return (
          <G key={index}>
            <Line
              x1={x(index) + chartConfig.linePos}
              y1="10"
              x2={x(index) + chartConfig.linePos}
              y2="214.5"
              stroke={initialColor}
              strokeWidth="1"
            />
            <Text
              key={index}
              x={x(index) + bandwidth}
              y="5"
              fontSize={10}
              fontFamily="Lato_400Regular"
              fill={initialColor}
              alignmentBaseline={'middle'}
              textAnchor={'middle'}
            >
              {formatCurrency(value, activeShop.shopCurrency, currentLanguage)}
            </Text>
          </G>
        );
      } else if (index === flatData.length - 1 && index === selectedBarIndex) {
        return (
          <G key={index}>
            <Line
              x1={x(index) + chartConfig.linePos}
              y1="10"
              x2={x(index) + chartConfig.linePos}
              y2="214.5"
              stroke={initialColor}
              strokeWidth="1"
            />
            <Text
              key={index}
              x={x(index)}
              y="5"
              fontSize={10}
              fontFamily="Lato_400Regular"
              fill={initialColor}
              alignmentBaseline={'middle'}
              textAnchor={'middle'}
            >
              {formatCurrency(value, activeShop.shopCurrency, currentLanguage)}
            </Text>
          </G>
        );
      } else if (index === selectedBarIndex) {
        return (
          <G key={index}>
            <Line
              x1={x(index) + chartConfig.linePos}
              y1="10"
              x2={x(index) + chartConfig.linePos}
              y2="214.5"
              stroke={initialColor}
              strokeWidth="1"
            />
            <Text
              key={index}
              x={x(index) + bandwidth / 2}
              y="5"
              fontSize={10}
              fontFamily="Lato_400Regular"
              fill={initialColor}
              alignmentBaseline={'middle'}
              textAnchor={'middle'}
            >
              {formatCurrency(value, activeShop.shopCurrency, currentLanguage)}
            </Text>
          </G>
        );
      }
      // If it's not the selected bar, return null to render nothing
      return null;
    });

  const scrollToRight = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  };

  useEffect(() => {
    if (data.labels && data.labels[0] !== '00') {
      scrollToRight();
    }
    initialColor = activeShop?.id ? salesByShop[0]?.color : Colors.light.blueRibbon;
    setBarColors(flatData?.map(() => initialColor));
    setDisplayTurnover(false);
  }, [data]);

  return (
    <View style={{ flexDirection: 'row', height: 260 }}>
      <YAxis
        data={[minYValue, maxYValue]}
        style={{ marginBottom: 30 }}
        contentInset={contentInset}
        svg={{ fontSize: 10, fill: Colors.light.grayLight, fontFamily: 'Lato_400Regular' }}
        formatLabel={(value: any) => formatGraphsYLabels(value)}
        numberOfTicks={8}
        spacingOuter={0.7}
      />
      {/* <View style={{ width: chartConfig.width || 800, flexDirection: 'row', height: 260 }}></View> */}
      <ScrollView ref={scrollViewRef} horizontal>
        <View style={{ width: chartConfig.width || 800, marginLeft: 10 }}>
          <BarChart
            style={{ flex: 1 }}
            data={transformedData}
            horizontal={false}
            yAccessor={({ item }) => item.value}
            contentInset={{
              top: 24,
              left: chartConfig.barLContentInset || 0,
              right: chartConfig.barRContentInset || 0,
              bottom: 15,
            }}
            spacingInner={0.7}
            showGrid={false}
            gridMin={0}
            numberOfTicks={8}
          >
            <Grid svg={{ stroke: '#E9ECEF', strokeWidth: 0.5 }} stroke={'#E9ECEF'} />
            {displayTurnover && <Labels />}
          </BarChart>

          <XAxis
            style={{
              marginLeft: chartConfig.marginLeft || 10,
              marginRight: chartConfig.marginRight || 0,
              marginTop: 10,
              height: xAxisHeight,
            }}
            data={data?.labels ? data.labels : []}
            formatLabel={(value, index) => data.labels[index]}
            contentInset={{ left: chartConfig.xContentInset || 6, right: chartConfig.rContentInset || 17 }}
            svg={{ fontSize: 10, fill: Colors.light.grayLight, fontFamily: 'Lato_400Regular' }}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default memo(DashboardStackedBarChart);

const styles = StyleSheet.create({
  chart: {
    marginVertical: 0,
    borderRadius: 16,
  },
});
