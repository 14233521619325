import React from 'react';
import ListItemContainer from '../../ui/ListItemContainer';
import ListItem from '../../ui/ListItem';
import ListItemDivider from '../../ui/ListItemDivider';

type CompanyAddressProps = {
    values: any;
}

const CompanyAddress = ({ values }: CompanyAddressProps) => {
    return(
        <ListItemContainer>
            <ListItem label={'AUTH.STREET_NUMBER'} value={values.street} />
            <ListItemDivider />
            <ListItem label={'AUTH.COUNTRY'} value={values.country} />
            <ListItemDivider />
            <ListItem label={'AUTH.CITY'} value={values.city} />
            <ListItemDivider />
            <ListItem label={'AUTH.ZIP'} value={values.zip} />
        </ListItemContainer>
    );
}

export default CompanyAddress;