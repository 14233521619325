import React, { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Colors from '../../../constants/Colors';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { CameraView, Camera } from 'expo-camera/next';
import { Feather } from '@expo/vector-icons';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    height: Dimensions.get('window').height,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 12,
    paddingHorizontal: 24,
    backgroundColor: Colors.light.background,
    paddingTop: 27,
  },
  goBackBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 18,
    top: 27,
  },
  goBackText: {
    color: Colors.light.blueRibbon,
    fontSize: 16,
  },
  bodyNoAccess: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noAccessTitle: {
    fontSize: 18,
    marginVertical: 16,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  layerTop: {
    backgroundColor: Colors.light.background,
    padding: 16,
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
  },
  layerTopText: {
    textAlign: 'center',
    width: Dimensions.get('window').width / 1.3,
    paddingTop: 75,
    paddingBottom: 50,
  },
  layerCenter: {
    flex: 3,
    flexDirection: 'row',
  },
  layerLeft: {
    flex: 2,
    backgroundColor: Colors.light.background,
  },
  focused: {
    flex: 10,
  },
  layerRight: {
    flex: 2,
    backgroundColor: Colors.light.background,
  },
  layerBottom: {
    flex: 4,
    backgroundColor: Colors.light.background,
  },
});

interface IScanBottomSheetModal {
  bottomSheetRef: any;
  goBackBtn: () => void;
  onOpenModal?: () => void;
  submitData: (clientKey: any, secretKey: any, shopUrl: any, shopType: any) => void;
}

export const ScanBottomSheetModal = ({ bottomSheetRef, submitData, goBackBtn }: IScanBottomSheetModal) => {
  const { t } = useTranslation();
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);

  const getBarCodeScannerPermissions = async () => {
    const { status } = await Camera.requestCameraPermissionsAsync();
    if (status === 'granted') {
      setHasPermission(status === 'granted');
    } else {
      Camera.requestCameraPermissionsAsync();
    }
  };

  useEffect(() => {
    getBarCodeScannerPermissions();
  }, []);

  const backBtn = () => {
    goBackBtn();
  };

  const handleBarCodeScanned = async ({ data }: any) => {
    if (!data) return;
    setScanned(true);
    // if (scanned) return;

    const splitData = data.split('|');

    const platformType = splitData.pop();

    submitData(splitData[0], splitData[1], splitData[2], platformType);
    goBackBtn();
  };

  const renderContent = () => {
    if (hasPermission === false || hasPermission === null) {
      return (
        <>
          <View style={styles.header}>
            <TouchableOpacity onPress={() => bottomSheetRef?.current?.close()} style={styles.goBackBtn}>
              <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
              <Text style={styles.goBackText}>{t('AUTH.BACK')}</Text>
            </TouchableOpacity>
            <Text style={styles.headerTitle}>{t('AUTH.SCAN_QR_CODE')}</Text>
          </View>
          <View style={styles.bodyNoAccess}>
            <Feather name="camera-off" size={50} />
            <Text style={styles.noAccessTitle}>{t('AUTH.NO_ACCESS_CAMERA')}</Text>
            <TouchableOpacity onPress={getBarCodeScannerPermissions}>
              <Text style={styles.goBackText} />
            </TouchableOpacity>
          </View>
        </>
      );
    }

    return (
      <View style={styles.container}>
        <CameraView onBarcodeScanned={handleBarCodeScanned} style={[StyleSheet.absoluteFill, styles.container]}>
          <View style={styles.layerTop}>
            <Text style={styles.layerTopText}>{t('AUTH.TO_ADD_SHOP_WITH_QR')}</Text>
          </View>
          <View style={styles.layerCenter}>
            <View style={styles.layerLeft} />
            <View style={styles.focused} />
            <View style={styles.layerRight} />
          </View>
          <View style={styles.layerBottom} />
        </CameraView>
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.SCAN_QR_CODE')}`, backBtn, `${t('AUTH.BACK')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
