import { Portal } from '@gorhom/portal';
import React, { memo } from 'react';
import { StyleSheet, Dimensions, Text, View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Colors from '../../../constants/Colors';
import { joinSpaceValidation } from '../../../components/form/Validations';
import RNPickerSelect from 'react-native-picker-select';
import currencies from '../../../utils/currencies';
import LabelPickerSelect from '../../form/LabelPickerSelect';
import { Feather } from '@expo/vector-icons';

interface ICurrency {
  bottomSheetRef: any;
}

const CurrencyBottomSheetModal = ({ bottomSheetRef }: ICurrency) => {
  const { t } = useTranslation();
  const { ...methods } = useForm({ resolver: yupResolver(joinSpaceValidation), mode: 'all' });

  const backBtn = async () => {
    bottomSheetRef.current?.close();
  };

  const renderContent = () => {
    return (
      <View style={styles.container}>
        <View style={styles.main}>
          <FormProvider {...methods}>
            <View style={{ marginBottom: 16 }}>
              <LabelPickerSelect required>{t('AUTH.SELECT_CURRENCY')}</LabelPickerSelect>
              <View>
                <Controller
                  control={methods?.control}
                  name="platform_name"
                  rules={{ required: true }}
                  render={({ field: { onChange } }) => (
                    <RNPickerSelect
                      onValueChange={onChange}
                      placeholder={{
                        label: 'EUR € - Euro',
                        value: currencies,
                      }}
                      items={currencies}
                      style={pickerSelectStyles}
                    />
                  )}
                />
                <Feather name="chevron-down" size={20} style={styles.arrowSelect} />
              </View>
            </View>
          </FormProvider>
        </View>
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.CURRENCY')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

export default memo(CurrencyBottomSheetModal);

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 48,
    paddingHorizontal: 16,
  },
  main: {
    paddingTop: 24,
    paddingHorizontal: 16,
  },
  keyboardAvoidStyleContainer: {
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderTopStartRadius: 8,
    borderBottomStartRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: Dimensions.get('window').width / 1.7,
  },
  inputWithDomain: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputStyleDomain: {
    backgroundColor: Colors.light.mystic,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderBottomEndRadius: 8,
    borderTopEndRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: 140,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    marginBottom: 60,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  root: { flex: 1, padding: 20 },
  title: { fontSize: 14, fontWeight: '500' },
  codeFieldRoot: { marginTop: 10 },
  inputStyleJoin: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    width: Dimensions.get('window').width / 1.133,
    paddingHorizontal: 16,
    height: 54,
    borderRadius: 8,
  },
  cell: {
    width: 48,
    height: 55,
    lineHeight: 20,
    fontSize: 18,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#00000030',
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
});
const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
});
