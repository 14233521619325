import React, { memo, useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import Colors from '../../constants/Colors';
import { useTranslation } from 'react-i18next';
import {  Entypo } from '@expo/vector-icons';
import { useDashboardStore } from '../../store/dashboard.state';
import { useNavigation } from '@react-navigation/native';
import { Modalize } from 'react-native-modalize';
import { useSpaceStore } from '../../store/spaces.state';
import { generateColorHsl } from '../../utils/generateRandomColors';
import SelectShopBottomSheetModal from '../../components/bottom-sheet-modal/shops/SelectShopBottomSheetModal';

const ChartActions = () => {
  const { activeShop }: any = useDashboardStore();
  const navigation = useNavigation();
  const { defaultSpace }: any = useSpaceStore();
  const color = generateColorHsl(defaultSpace?.name || 'John Doe');
  const selectShopSheetRef = useRef<Modalize>(null);
  const { t } = useTranslation();

  const onOpenSelectShopModal = async () => {
    selectShopSheetRef.current?.open();
  };

  return (
    <View style={styles.main}>
      <View style={styles.filter}>
        {/* {graphTypes?.map((item: string) => (
          <TouchableOpacity
            key={item}
            style={[styles.filterItem, graphType === item && styles.filterItemActive]}
            onPress={() => setGraphType(item)}
          >
            <Text style={[styles.filterItemText, graphType === item && styles.filterItemTextActive]}>
              {item === 'merge' ? t('AUTH.MERGE') : t('AUTH.COMPARE')}
            </Text>
          </TouchableOpacity>
        ))} */}

        <TouchableOpacity
          // key={item}
          // style={[styles.filterItem, graphType === item && styles.filterItemActive]}
          style={styles.selectShop}
          onPress={() => onOpenSelectShopModal()}
        >
          <Text style={styles.dropDownButton} numberOfLines={1} ellipsizeMode="tail">
            {activeShop?.name || t('AUTH.ALL_SHOPS')}
            {/* {item === 'merge' ? t('AUTH.MERGE') : t('AUTH.COMPARE')} */}
          </Text>
          <View style={styles.caretDown}>
            <Entypo name="chevron-small-down" size={24} color="black" />
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('Settings')} >
          <View style={[styles.avatar, { backgroundColor: color }]}>
            <Text style={styles.avatarText}>{defaultSpace?.name?.slice(0, 1)}</Text>
          </View>
        </TouchableOpacity> 
      </View>
      <SelectShopBottomSheetModal bottomSheetRef={selectShopSheetRef} />
    </View>
  );
};

export default memo(ChartActions);

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  filterItem: {
    marginRight: 14,
  },
  filterItemActive: {
    backgroundColor: Colors.light.athensGray,
    borderRadius: 6,
  },
  filterItemText: {
    fontSize: 13,
    padding: 6,
    color: Colors.light.hitGray,
    fontWeight: 'bold',
  },
  filterItemTextActive: {
    fontWeight: 'bold',
    color: Colors.light.shark,
  },
  selectShop: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 193,
    height: 32,
    backgroundColor: Colors.light.white,
    borderRadius: 8,
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: Colors.light.riptide,
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarText: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  dropDownButton: {
    flex: 1,
    marginLeft: 8,
    fontWeight: 'bold',
    overflow: 'hidden',
  },
  caretDown: {
    marginRight: 8,
  },
});
