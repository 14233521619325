import React, { useState } from 'react';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity } from 'react-native';
import Colors from '../../../constants/Colors';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { joinSpaceValidation } from '../../../components/form/Validations';
import { TextInput } from '../../../components/form/TextInput';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';

type FormValues = {
  space_code: string;
};
interface IJoinSpaceBottomSheetModal {
  bottomSheetRef: any;
  onSubmit?: (values: FormValues) => void;
}

const styles = StyleSheet.create({
  main: {
    paddingTop: 24,
    paddingHorizontal: 16,
  },
  keyboardAvoidStyleContainer: {
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderTopStartRadius: 8,
    borderBottomStartRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: Dimensions.get('window').width / 1.7,
  },
  inputWithDomain: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputStyleDomain: {
    backgroundColor: Colors.light.mystic,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderBottomEndRadius: 8,
    borderTopEndRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    width: 140,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    marginBottom: 60,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  root: { flex: 1, padding: 20 },
  title: { fontSize: 14, fontWeight: '500' },
  codeFieldRoot: { marginTop: 10 },
  inputStyleJoin: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    width: Dimensions.get('window').width / 1.133,
    paddingHorizontal: 16,
    height: 54,
    borderRadius: 8,
  },
  cell: {
    width: 48,
    height: 55,
    lineHeight: 20,
    fontSize: 18,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#00000030',
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
});

const CELL_COUNT = 6;

export const JoinSpaceBottomSheetModal = ({ bottomSheetRef, onSubmit }: IJoinSpaceBottomSheetModal) => {
  const { t } = useTranslation();
  const { ...methods } = useForm({ resolver: yupResolver(joinSpaceValidation), mode: 'all' });

  const backBtn = () => {
    bottomSheetRef.current?.close();
  };

  const renderContent = () => (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={false}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.main}>
        <FormProvider {...methods}>
          <View>
            <View style={styles.inputWithDomain}>
              <TextInput
                name="space_code"
                label={'Space code*'}
                placeholder={t('AUTH.ENTER_6_DIGITS')}
                autoCapitalize="characters"
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyleJoin}
              />
            </View>
            <View>
              {methods?.formState?.errors?.space_code && (
                <Text style={styles.inputValidation}>{t(methods?.formState?.errors?.space_code?.message)}</Text>
              )}
            </View>
          </View>
        </FormProvider>
      </View>
    </KeyboardAwareScrollView>
  );

  const renderFooter = () => (
    <View style={styles.bottom}>
      <TouchableOpacity onPress={methods.handleSubmit(onSubmit)} style={styles.button}>
        <Text style={styles.buttonText}>{t('AUTH.JOIN_SPACE')}</Text>
      </TouchableOpacity>
    </View>
  );

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.JOIN_SPACE')}`, backBtn, `${t('AUTH.CLOSE')}`)}
        FooterComponent={renderFooter()}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
