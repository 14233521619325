import React from 'react';
import { StyleSheet, View, Text, Dimensions } from 'react-native';
import Colors from '../../../constants/Colors';
import { WebView } from 'react-native-webview';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import env from '../../../config/constants';
import { useTranslation } from 'react-i18next';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: Colors.light.background,
    padding: 24,
  },
  webViewContainer: {
    flex: 1,
    width: '100%',
    padding: 24,
    // height: Dimensions.get('window').height / 1.15,
  },
  version: {
    fontSize: 14,
    color: Colors.light.hitGray,
    marginTop: 16,
    marginBottom: 60,
  }

});

interface ITermsBottomSheetModal {
  bottomSheetRef: any;
}

export const AboutBottomSheetModal = ({ bottomSheetRef }: ITermsBottomSheetModal) => {
  const { t } = useTranslation();
  const backBtn = () => {
    bottomSheetRef.current?.close();
  };
  const renderContent = () => (
    <View style={styles.webViewContainer}>
      {/* <WebView
        originWhitelist={['*']}
        source={{ uri: env.TERMS_USE }}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        startInLoadingState={true}
      /> */}
      <View>
        <Text style={{ lineHeight: 22 }}>
          Welcome to StatHubs – your one-stop destination 
          for a comprehensive overview of all your online shop statistics! With a glance, 
          track the pulse of your e-commerce empire as StatHubs gathers and presents all the vital metrics 
          from your various online shops in one unified space. 
          From sales performance to customer engagement, consider this your command center for informed decision-making. 
          Simplify your data, amplify your insights – StatHubs, where your stats unite seamlessly.
        </Text>
      </View>
      <View>
        <Text style={styles.version}>Version 1.0.0</Text>
      </View>
    </View>
  );
  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        scrollViewProps={{ showsVerticalScrollIndicator: false }}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.ABOUT_THE_APP')}`, backBtn, `${t('AUTH.CLOSE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
