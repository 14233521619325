import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import api from '../config/api';
import { useStatsStore } from '../store/stats.state';

export function useShop() {
  const { t } = useTranslation();
  const { setAllOrders }: any = useStatsStore();

  const syncShopOrders = async (shopId: string, platform_type: number) => {
    if (!shopId && !platform_type) return;
    const body = {
      shop_id: shopId,
      platform_type: platform_type,
    };
    try {
      const response = await api('/shop/orders', 'POST', body);
      if (response?.success === 'true') {
        Toast.show({
          type: 'success',
          text1: `${t('AUTH.SHOP_CONNECT')}`,
          text2: `${t('AUTH.YOU_HAVE_SUCCESS_SHOP')}`,
        });
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SHOP_CONNECT')}`,
        text2: `${t('AUTH.UNFORTUNATELY_WE_COULDNT')}`,
      });
    }
  };

  const getOrdersFromShop = async (shopId: string) => {
    if (!shopId) return;
    try {
      const response = await api('/shop/orders/' + shopId, 'GET', null);
      if (response && response.success === 'true' && response?.shop_orders.length) {
        setAllOrders(response?.shop_orders);
      }
    } catch (error) {
      setAllOrders([]);
    }
  };

  const pingShopConnection = async (
    client_key: string,
    client_secret: string,
    shop_url: string,
    platform_type: number
  ) => {
    if (!client_key && !client_secret && !shop_url) return;
    const body = {
      client_id: client_key,
      client_secret: client_secret,
      shop_url: shop_url,
      platform_type: platform_type,
    };
    try {
      const response = await api('/shop/orders/ping', 'POST', body);
      if (response.success === true) {
        return true;
      }
      return false;
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SHOP_CONNECT')}`,
        text2: `${t('AUTH.UNFORTUNATELY_WE_COULDNT')}`,
      });

      return false;
    }
  };

  const createShop = async (data: any) => {
    try {
      const response = await api('/shop/create', 'POST', data);
      return response;
    } catch (error) {
      return error;
    }
  };

  const integrateShop = async (data: any) => {
    const { client_key, secret_key, shop_url, platform_type } = data;

    const isCommunicating = await pingShopConnection(client_key, secret_key, shop_url, platform_type);
    if (isCommunicating) {
      const response = await createShop(data);
      if (response?.success) {
        const shopId = response?.shop?.id;
        await syncShopOrders(shopId, platform_type);
        await getOrdersFromShop(shopId);
      } else {
        Toast.show({
          type: 'error',
          text1: response?.errors || 'Something went wrong, please try again',
        });
      }
    }
  };

  return {
    integrateShop,
    getOrdersFromShop,
  };
}
