import React, { useCallback, useEffect, useRef, useState } from 'react';
import RNPickerSelect from 'react-native-picker-select';
import Colors from '../../../constants/Colors';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { inviteUserValidation } from '../../form/Validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from '../../form/TextInput';
import { Feather } from '@expo/vector-icons';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { useTranslation } from 'react-i18next';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import TextInputValidation from '../../form/TextInputValidation';
import LabelPickerSelect from '../../form/LabelPickerSelect';
import Button from '../../ui/action/Button';
import { SelectRoleBottomSheetModal } from './SelectRoleBottomSheetModal';
import { SelectShopInviteBottomSheetModal } from './SelectShopInviteBottomSheetModal';
import { useDashboardStore } from '../../../store/dashboard.state';
import { useIsFocused } from '@react-navigation/native';

interface IUserInviteBottomSheetModal {
  bottomSheetRef: any;
  shops: any;
  roles: any;
  loadingInvitation: boolean;
  onSubmit?: any;
}

export const UserInviteBottomSheetModal = ({
  bottomSheetRef,
  shops,
  roles,
  loadingInvitation,
  onSubmit,
}: IUserInviteBottomSheetModal) => {
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const [parentShops, setParentShops] = useState([]);
  const { setSelectedShopInvite, selectedRoleInvite, setSelectedRoleInvite }: any = useDashboardStore();

  const { ...methods } = useForm({
    resolver: yupResolver(inviteUserValidation),
    mode: 'all',
    // defaultValues: { shop_id: shops[0]?.id },
  });

  const backBtn = () => {
    bottomSheetRef.current?.close();
  };

  const shopInviteBottomSheetRef = useRef<Modalize>(null);
  const handleShopInviteModalPress = useCallback(() => {
    shopInviteBottomSheetRef.current?.open();
  }, []);
  const handleBackShopInviteModalPress = useCallback(() => {
    shopInviteBottomSheetRef.current?.close();
  }, []);

  const roleBottomSheetRef = useRef<Modalize>(null);
  const handleRoleModalPress = useCallback(() => {
    roleBottomSheetRef.current?.open();
  }, []);
  const handleBackRoleModalPress = useCallback(() => {
    roleBottomSheetRef.current?.close();
  }, []);

  const renderContent = () => (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={false}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.contentContainer}>
        <Text style={styles.description}>{t('AUTH.INVITE_YOUR_TEAM_MEMBER_TO')}</Text>
        <FormProvider {...methods}>
          <>
            <LabelPickerSelect required>{t('AUTH.SELECT_ROLE')}</LabelPickerSelect>
            <View style={{ marginBottom: 16 }}>
              <Controller
                control={methods.control}
                name="role_id"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <View style={styles.viewPicker}>
                    <TouchableOpacity onPress={handleRoleModalPress} style={[styles.inputStyle]}>
                      <Text style={styles.inputStyleText}>{selectedRoleInvite.name}</Text>
                    </TouchableOpacity>

                    <TextInput
                      name="role_id"
                      label=""
                      style={[styles.inputStyle, { display: 'none', paddingBottom: 0 }]}
                      value={selectedRoleInvite.name}
                      onChange={onChange}
                      // onFocus={handleRoleModalPress}
                      defaultValue=""
                      required
                    />
                    <Feather name="chevron-down" size={20} style={styles.arrowSelect} />

                    {methods?.formState?.errors?.role_id && (
                      <TextInputValidation>{t(methods?.formState?.errors?.role_id?.message)}</TextInputValidation>
                    )}
                  </View>
                )}
              />
            </View>
          </>

          {shops && shops.length > 0 ? (
            <>
              {selectedRoleInvite.name !== 'admin' && (
                <>
                  <LabelPickerSelect required>{t('AUTH.SELECT_SHOP')}</LabelPickerSelect>
                  <View style={{ marginBottom: 16 }}>
                    <Controller
                      control={methods.control}
                      name="shop_ids"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <View style={styles.viewPicker}>
                          <TouchableOpacity onPress={handleShopInviteModalPress} style={[styles.inputStyle]}>
                            <Text style={styles.inputStyleText}>
                              {parentShops.map((item: any) => `${item.name}, `)}
                            </Text>
                            <TextInput
                              name="shop_ids"
                              label=""
                              style={[styles.inputStyle, { display: 'none', paddingBottom: 0 }]}
                              value={parentShops.map((item: any) => item.name)}
                              onChange={onChange}
                              // onFocus={handleShopInviteModalPress}
                              defaultValue=""
                              editable={false}
                              required
                            />
                          </TouchableOpacity>
                          <Feather name="chevron-down" size={20} style={styles.arrowSelect} />

                          {methods?.formState?.errors?.shop_id && (
                            <TextInputValidation>
                              {t(methods?.formState?.errors?.shop_ids?.message)}
                            </TextInputValidation>
                          )}
                        </View>
                      )}
                    />
                  </View>
                </>
              )}
            </>
          ) : (
            <Text>
              {t('AUTH.CURRENTLY')} {shops?.length} {t('AUTH.CURRENTLY_SHOPS_AVAILABLE')}{' '}
            </Text>
          )}

          <TextInput
            name="email"
            label={t('AUTH.EMAIL')}
            placeholder={t('AUTH.ENTER_USER_EMAIL')}
            keyboardType="email-address"
            autoCapitalize="none"
            defaultValue=""
            placeholderTextColor={Colors.light.placeholder}
            style={styles.inputStyle}
            required
          />

          {methods?.formState?.errors?.email && (
            <TextInputValidation>{t(methods?.formState?.errors?.email?.message)}</TextInputValidation>
          )}
        </FormProvider>
      </View>

      <SelectShopInviteBottomSheetModal
        bottomSheetRef={shopInviteBottomSheetRef}
        goBackBtn={handleBackShopInviteModalPress}
        shops={shops}
        methods={methods}
        setParentShops={setParentShops}
      />
      <SelectRoleBottomSheetModal
        bottomSheetRef={roleBottomSheetRef}
        goBackBtn={handleBackRoleModalPress}
        roles={roles}
        methods={methods}
      />

      <View style={styles.bottom}>
        <Button
          loading={loadingInvitation}
          disabled={!shops?.length}
          label={'AUTH.INVITE'}
          onPress={methods.handleSubmit(onSubmit)}
          bg={'dark'}
        />
      </View>
    </KeyboardAwareScrollView>
  );

  useEffect(() => {
    if (isFocused) {
      methods.reset();
      setSelectedShopInvite([]);
      setSelectedRoleInvite('');
      setParentShops([]);
    }
  }, [isFocused]);

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.INVITE_USER')}`, backBtn, `${t('AUTH.CANCEL')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: Colors.light.background,
    paddingTop: 24,
    paddingHorizontal: 16,
  },

  keyboardAvoidStyleContainer: {
    marginBottom: 24,
    flexGrow: 1,
    backgroundColor: Colors.light.background,
  },
  description: {
    fontSize: 14,
    marginBottom: 24,
  },
  labelPickerSelect: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  disabledBtn: {
    backgroundColor: Colors.light.midGray,
  },
  viewPicker: {
    marginBottom: 16,
  },
  inputStyleText: {
    marginTop: 15,
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
});
