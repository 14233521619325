// import i18 initialize en and de
import i18n from 'i18next';
import { getLocales } from 'expo-localization';

// Languages
import en from '../assets/languages/en.json';
import de from '../assets/languages/de.json';
import us from '../assets/languages/us.json';

// Import initReact18
import { initReactI18next } from 'react-i18next';

// Store

export function usei18n() {
  const deviceLanguage = getLocales()[0].languageCode;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const initializei18n = async () => {
    await i18n.use(initReactI18next).init({
      compatibilityJSON: 'v3',
      lng: deviceLanguage || 'en',
      resources: {
        de: de,
        en: en,
        us: us,
      },
      interpolation: {
        escapeValue: false,
      },
    });
  };

  return {
    initializei18n,
  };
}
