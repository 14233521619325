import React, { memo, useState, useCallback, useEffect } from 'react';
import { ScrollView, Text, StyleSheet, TouchableOpacity, RefreshControl, Dimensions } from 'react-native';
import Colors from '../../../../constants/Colors';
import { FontAwesome } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useIsFocused } from '@react-navigation/native';
import { useDashboardStore } from '../../../../store/dashboard.state';

const NoShopView = ({navigation, fetchDashboard}: any) => {
    const isFocused = useIsFocused();
    const { setNoShopAddedModal }: any = useDashboardStore();
    const [state, setState] = useState({ isRefreshing: false })
    const { t } = useTranslation();

    const handlePresentModalPress = useCallback(() => {
      navigation.navigate('Shops');
        setNoShopAddedModal(true);
    }, [navigation]);

    const onRefresh = () => {
      fetchDashboard();
    };

    useEffect(() => {
      if (isFocused) {
        setNoShopAddedModal(false);
      }
    }, [isFocused]);

    return (
        <ScrollView
            contentContainerStyle={styles.main}
            showsVerticalScrollIndicator={false}
            refreshControl={<RefreshControl refreshing={state.isRefreshing} onRefresh={onRefresh} />}
          >
            <Text style={styles.title}>{t('AUTH.NO_SHOP_ADD')}</Text>
            <Text style={styles.description}>{t('AUTH.IN_ORDER_TO_SEE_STATISTIC_PLEASE')}</Text>
            <TouchableOpacity style={styles.addShopButton} onPress={handlePresentModalPress}>
                <FontAwesome name="plus-circle" size={20} color={Colors.light.background} />
                <Text style={styles.addShopButtonText}>{t('AUTH.ADD_FIRST_SHOP')}</Text>
            </TouchableOpacity>
        </ScrollView>
    )
}

export default memo(NoShopView);

const styles = StyleSheet.create({
    main: {
        flex: 1,
        alignItems: 'center',
        marginVertical: 250,
        backgroundColor: Colors.light.background,
    },
    title: {
        fontSize: 18,
        paddingBottom: 6,
        fontWeight: '600',
        color: Colors.light.blueCharcoal,
      },
      description: {
        textAlign: 'center',
        maxWidth: Dimensions.get('window').width / 1.2,
        lineHeight: 20,
        fontSize: 14,
        paddingBottom: 40,
        color: Colors.light.blueCharcoal,
      },
      addShopButton: {
        borderRadius: 8,
        backgroundColor: Colors.light.shark,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
      addShopButtonText: {
        paddingVertical: 20,
        paddingLeft: 8,
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'center',
        color: Colors.light.background,
      },
})