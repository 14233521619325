import { Feather } from '@expo/vector-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { StackScreenProps } from '@react-navigation/stack';
import * as Haptics from 'expo-haptics';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Modalize } from 'react-native-modalize';
import Toast from 'react-native-toast-message';
import api from '../../../config/api';
import Colors from '../../../constants/Colors';
import { useAuthStore } from '../../../store/auth.state';
import { useDashboardStore } from '../../../store/dashboard.state';
import countries from '../../../utils/countries';
import LabelPickerSelect from '../../form/LabelPickerSelect';
import { TextInput } from '../../form/TextInput';
import TextInputValidation from '../../form/TextInputValidation';
import { companyAddress } from '../../form/Validations';
import PageTitle from '../../ui/PageTitle';
import { SelectCountryBottomSheetModal } from './SelectCountryBottomSheetModal';

type FormValues = {
  name: string;
  street: string;
  company_name: string;
  country: string;
  city: string;
  zip_code: string;
};

export const EditCompanyAddress: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const { userData }: any = useAuthStore();
  const userId = userData?.user?.id;
  const userEmail = userData?.user?.email;
  const { t } = useTranslation();
  const { selectedCountry }: any = useDashboardStore();

  const { ...methods } = useForm({
    resolver: yupResolver(companyAddress),
    mode: 'all',
    // defaultValues: { email: userEmail },
    defaultValues: { street: undefined, country: undefined, zip_code: undefined, city: undefined },
  });

  const isDisabled = methods?.formState.isSubmitting;
  // const isDisabled = !methods?.formState.isValid || methods?.formState.isSubmitting;

  const [values, setValues] = useState({
    loading: true,
    errors: '',
    edit: false,
    company: null,
  });

  const createCompany = async (data: any) => {
    if (!data) return;
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/company/create', 'POST', data);
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.COMPANY_WAS_CREATED_SUCCESSFULL')}`,
      });
      navigation.navigate('Company', { isRefreshing: true });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    }
  };

  const updateCompany = async (data: any) => {
    if (!data) return;
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/company/update', 'POST', data);
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.COMPANY_UPDATED_SUCCESSFULL')}`,
      });
      navigation.navigate('Company', { isRefreshing: true });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.COMPANY_DETAILS')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (!userId) return;
    setValues({ ...values, loading: true });
    if (!values.company) {
      await createCompany(data);
    } else {
      await updateCompany(data);
    }
    setValues({ ...values, loading: false });
  };

  const getUserDetails = async (id: string) => {
    if (!id) return;
    try {
      const response = await api('/user/' + id, 'GET', null);
      if (response) {
        methods.reset(response?.data?.company);
        setValues({ ...values, company: response?.data?.company, loading: false });
      }
    } catch (error) {
      setValues({ ...values, loading: false });
    }
  };

  const countryBottomSheetRef = useRef<Modalize>(null);
  const handleCountryModalPress = useCallback(() => {
    countryBottomSheetRef.current?.open();
  }, []);
  const handleBackRoleModalPress = useCallback(() => {
    countryBottomSheetRef.current?.close();
  }, []);

  useEffect(() => {
    getUserDetails(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.headerSettings}>
        <TouchableOpacity style={styles.goBack} onPress={() => navigation.goBack()}>
          <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
          <Text style={styles.goBackText}>Company details</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={methods.handleSubmit(onSubmit)} disabled={isDisabled}>
          <Text style={[styles.editText, isDisabled && styles.editTextDisabled]}>{t('AUTH.SAVE')}</Text>
        </TouchableOpacity>
      </View>
      <KeyboardAwareScrollView
        style={styles.keyboardAvoidStyleContainer}
        scrollEnabled={true}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps={'handled'}
      >
        <PageTitle title={'AUTH.COMPANY_ADDRESS'} />

        <FormProvider {...methods} children>
          <View style={{ marginBottom: 16 }}>
            <TextInput
              name="street"
              label={t('AUTH.STREET_NUMBER')}
              placeholder={t('AUTH.STREET_NUMBER')}
              placeholderTextColor={Colors.light.placeholder}
              style={[styles.inputStyle]}
              required
            />

            {methods.formState.errors.street && (
              <TextInputValidation>{t(methods?.formState?.errors?.street?.message)}</TextInputValidation>
            )}
          </View>

          <View style={{ marginBottom: 16 }}>
            <LabelPickerSelect required>{t('AUTH.COUNTRY')}</LabelPickerSelect>
            <View>
              <Controller
                control={methods.control}
                name="country"
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <View>
                    <TouchableOpacity onPress={handleCountryModalPress} style={[styles.inputStyle]}>
                      <TextInput
                        name="country"
                        label=""
                        style={[styles.inputStyle, { display: 'none', paddingBottom: 0 }]}
                        value={selectedCountry.label}
                        onChange={onChange}
                        onFocus={handleCountryModalPress}
                        defaultValue=""
                        required
                        disabled
                      />
                      <Text>{selectedCountry.label}</Text>
                    </TouchableOpacity>
                  </View>
                )}
              />

              <Feather name="chevron-down" size={20} style={styles.arrowSelect} />
              {methods?.formState?.errors?.country && (
                <Text style={styles.inputValidation}>{t(methods?.formState?.errors?.country?.message)}</Text>
              )}
            </View>
          </View>

          <View style={styles.cityAndZip}>
            <View style={styles.zip}>
              <TextInput
                name="zip_code"
                label={t('AUTH.ZIP')}
                placeholder={t('AUTH.ZIP')}
                placeholderTextColor={Colors.light.placeholder}
                keyboardType={'numeric'}
                style={[styles.inputStyle]}
                required
              />

              {methods.formState.errors.zip_code && (
                <TextInputValidation>{t(methods?.formState?.errors?.zip_code?.message)}</TextInputValidation>
              )}
            </View>

            <View style={styles.city}>
              <TextInput
                name="city"
                label={t('AUTH.CITY')}
                placeholder={t('AUTH.CITY')}
                placeholderTextColor={Colors.light.placeholder}
                style={[styles.inputStyle]}
                required
              />

              {methods.formState.errors.city && (
                <TextInputValidation>{t(methods?.formState?.errors?.city?.message)}</TextInputValidation>
              )}
            </View>
          </View>
        </FormProvider>

        <SelectCountryBottomSheetModal
          bottomSheetRef={countryBottomSheetRef}
          goBackBtn={handleBackRoleModalPress}
          countries={countries}
          methods={methods}
        />
      </KeyboardAwareScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    flex: 1,
    paddingHorizontal: 20,
    paddingTop: 60,
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  pickerSelectView: {
    marginTop: 16,
  },
  labelPickerSelect: {
    paddingBottom: 6,
    fontSize: 14,
    fontWeight: '500',
  },
  inputStyle: {
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  cityAndZip: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  city: {
    width: '65%',
  },
  zip: {
    width: '32%',
    marginRight: '3%',
  },
  headerSettings: {
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  editText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editTextDisabled: {
    color: Colors.light.editTextDisabled,
  },
  arrowSelect: {
    position: 'absolute',
    right: 10,
    top: 18,
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  informationContainer: {
    display: 'flex',
    paddingTop: 40,
    paddingBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  companyStyle: {
    color: 'black',
    fontSize: 18,
    fontWeight: '600',
  },
  editStyle: {
    color: 'blue',
    fontSize: 15,
    fontWeight: '300',
  },
  containerInformation: {
    width: '100%',
    paddingHorizontal: 10,
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: Colors.light.gray,
  },
  secondText: {
    fontSize: 14,
    color: Colors.light.textLight,
    textAlign: 'center',
    alignItems: 'center',
  },
  textSecond: {
    fontSize: 14,
    color: Colors.light.textLight,
    textAlign: 'center',
    alignItems: 'center',
  },
  textField: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  textFieldSecond: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  industryText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    alignItems: 'center',
  },
});

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    fontSize: 16,
    paddingVertical: 12,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
  inputAndroid: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0.5,
    borderColor: Colors.light.border,
    borderRadius: 8,
    height: 54,
    paddingRight: 30,
  },
});
