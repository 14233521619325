import React, { memo } from 'react';
import { View, TouchableOpacity, StyleSheet, Text, ActivityIndicator } from 'react-native';
import { Feather, FontAwesome } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';
import { generateColorHsl } from '../../../utils/generateRandomColors';

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    marginBottom: 16,
  },
  logout: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logoutText: {
    fontSize: 16,
    fontWeight: '500',
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertNotifications: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertNotification: {
    marginRight: 12,
    backgroundColor: Colors.light.blueRibbon,
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 100,
  },
  avatar: {
    width: 32,
    height: 32,
    backgroundColor: Colors.light.riptide,
    borderRadius: 8,
    marginRight: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarText: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
});

type SpaceItemProps = {
  disabled?: boolean;
  title?: string;
  alert?: any;
  onPressItem?: () => void;
};

const SpaceItem = ({ title, alert, disabled = false, onPressItem, ...props }: SpaceItemProps) => {
  const color = generateColorHsl(title || 'John Doe');
  return (
    <View style={styles.container} {...props}>
      <TouchableOpacity disabled={disabled} style={styles.logout} onPress={onPressItem}>
        <View style={styles.iconText}>
          <View style={[styles.avatar, { backgroundColor: color }]}>
            <Text style={styles.avatarText}>{title?.slice(0, 1)}</Text>
          </View>

          <Text style={styles.logoutText}>{title}</Text>
        </View>
        <View style={styles.alertNotifications}>
          {disabled && <ActivityIndicator />}
          {!!alert?.length && alert !== '0' ? (
            <FontAwesome name="dot-circle-o" size={20} color={Colors.light.blueRibbon} />
          ) : (
            <Feather name="circle" size={20} color={Colors.light.silver} />
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default memo(SpaceItem);
