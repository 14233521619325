import React, { ReactNode, memo } from 'react';
import { View, StyleSheet } from 'react-native';
import Colors from '../../constants/Colors';

type ListItemContainerProps = {
    children: ReactNode;
    borderTop?: boolean;
    borderBottom?: boolean;
    lastChild?: boolean;
}

const ListItemContainer = ({ children, borderTop, borderBottom, lastChild }: ListItemContainerProps) => {
    const borderTopStyle =  borderTop 
        ? { 
            borderTopColor: Colors.light.grayDark, 
            borderTopWidth: 0.5,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        }
        : null
    
    const borderBottomStyle =  borderBottom 
        ? { 
            borderBottomColor: Colors.light.grayDark, 
            borderBottomWidth: 0.5,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }
        : null
        const marginBottomStyle = lastChild ? { marginBottom: 24 } : { marginBottom: 60 };
    return <View style={[styles.container, borderTopStyle, borderBottomStyle, marginBottomStyle]}>{ children }</View>
}

export default memo(ListItemContainer);

const styles = StyleSheet.create({
    container: { 
        backgroundColor: Colors.light.gray,
        borderRadius: 8,
    }
});