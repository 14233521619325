import { Portal } from '@gorhom/portal';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { useDashboardStore } from '../../../store/dashboard.state';
import { intervals } from '../../../utils/dashboard';
import DatePickerInterval from '../../dashboard/DatePickerInterval';
import { HeaderBottomIntervalSheet } from '../../ui/HeaderBottomSheet';

interface ILanguageModal {
  bottomSheetRef: any;
  selectedTab: any;
  setSelectedTab: any;

  // Graph filet validation states
  setDisableHour: any;
  setDisableDays: any;
  setDisableWeeks: any;
  setDisableMonths: any;
}

const IntervalTimepickerModal = ({
  bottomSheetRef,
  setSelectedTab,

  // Graph filet validation states
  setDisableHour,
  setDisableDays,
  setDisableWeeks,
  setDisableMonths,
}: ILanguageModal) => {
  const [selectedTabLocal, setSelectedTabLocal] = useState('1h');
  const [triggerEffect, setTriggerEffect] = useState(false);
  const { dateInterval, setDateInterval, setGraphInterval }: any = useDashboardStore();
  const { t } = useTranslation();
  const [state, setState] = useState<{ date: any; showPicker: boolean; placeClicked: boolean }>({
    date: new Date(),
    showPicker: false,
    placeClicked: false, // true: if clicked dates inside calendar | false: if clicked the filters
  });

  let newDateInterval = {};

  const currentDay = new Date();
  const oneWeekAgo = new Date(currentDay);
  const oneMonthAgo = new Date(currentDay);
  const firstOfYear = new Date(currentDay.getFullYear(), 0, 1);
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);

  useEffect(() => {
    const startDateValidation = new Date(dateInterval.startDate);
    const endDateValidation = new Date(dateInterval.endDate);

    const timeDiff = endDateValidation.getTime() - startDateValidation.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (daysDiff === 0) {
      setDisableHour(false);
      setDisableDays(true);
      setDisableWeeks(true);
      setDisableMonths(true);
      setGraphInterval(intervals[0]);
    } else if (daysDiff === 1 || daysDiff <= 14) {
      setDisableHour(true);
      setDisableDays(false);
      setDisableWeeks(true);
      setDisableMonths(true);
      setGraphInterval(intervals[1]);
    } else if (daysDiff <= 49) {
      setDisableHour(true);
      setDisableDays(false);
      setDisableWeeks(false);
      setDisableMonths(true);
      setGraphInterval(intervals[2]);
    } else if (daysDiff <= 91) {
      setDisableHour(true);
      setDisableDays(true);
      setDisableWeeks(false);
      setDisableMonths(false);
      setGraphInterval(intervals[3]);
    } else {
      setDisableHour(true);
      setDisableDays(true);
      setDisableWeeks(true);
      setDisableMonths(false);
      setGraphInterval(intervals[3]);
    }
  }, [dateInterval, triggerEffect]);

  const backBtn = () => {
    if (state.placeClicked) {
      if (!dateInterval.endDate) {
        setDateInterval({ startDate: currentDay, endDate: currentDay });
        setGraphInterval(intervals[0]);
      }

      // To trigger the useEffect in order to update Graph Filters with the calendar date picked manualy
      setTriggerEffect(false);
    }

    setSelectedTab(selectedTabLocal);
    bottomSheetRef.current?.close();
  };

  const handleClearDateRange = () => {
    setDateInterval({ startDate: null, endDate: null });
    setState((prevState: any) => ({ ...prevState, placeClicked: true }));
  };

  const handleSelectedTab = (tab: any) => {
    switch (tab) {
      case '1h':
        newDateInterval = { startDate: currentDay, endDate: currentDay };
        break;

      case '1d':
        newDateInterval = { startDate: oneWeekAgo, endDate: currentDay };
        break;

      case '1w':
        newDateInterval = { startDate: oneMonthAgo, endDate: currentDay };
        break;

      case '1m':
        newDateInterval = { startDate: firstOfYear, endDate: currentDay };
        break;
    }

    setDateInterval(newDateInterval);
    setSelectedTabLocal(tab);
    setState((prevState: any) => ({ ...prevState, placeClicked: false }));
  };

  const renderTab = (tab: any) => (
    <TouchableOpacity
      key={tab}
      style={[styles.tabButton, selectedTabLocal === tab && styles.selectedTab]}
      onPress={() => handleSelectedTab(tab)}
    >
      <Text style={[styles.tabsText, selectedTabLocal === tab && styles.selectedTabWeight]}>
        {t(`CALENDAR.${tab}`)}
      </Text>
    </TouchableOpacity>
  );

  const renderContent = () => {
    return (
      <View style={styles.container}>
        <View style={styles.tabButtons}>
          <Text style={styles.filterText}>{t('CALENDAR.FILTER_BY')}: </Text>
          <View style={styles.filterTabs}>{intervals.map(renderTab)}</View>
        </View>

        <View style={styles.tabContent}>
          <DatePickerInterval setState={setState} />
        </View>
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomIntervalSheet(
          `${t('CALENDAR.SELECT_DATE')}`,
          backBtn,
          `${t('CALENDAR.UPDATE')}`,
          handleClearDateRange,
          `Clear`,
          true
        )}
        onOverlayPress={() => {
          backBtn();
        }}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

export default memo(IntervalTimepickerModal);

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    paddingTop: 18,
    paddingBottom: 32,
    paddingHorizontal: 16,
  },
  tabButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterText: {
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  filterTabs: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 16,
  },
  tabButton: {
    padding: 5,
    borderRadius: 5,
    marginRight: 8,
  },
  selectedTab: {
    backgroundColor: '#F0F2F6',
  },
  selectedTabWeight: {
    color: '#292C33',
    fontWeight: 'bold',
  },
  tabsText: {
    color: '#AAB4BD',
  },
  tabContent: {
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
