import React from 'react';
import { StyleSheet, Linking, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import Colors from '../../constants/Colors';
import Header from '../../components/ui/Header';
import { EmailNotification } from '../../assets/images';
import { useTranslation } from 'react-i18next';

const EmailSent: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Header screenName="Auth" />
        <TouchableOpacity onPress={() => navigation.navigate('SignIn')}>
          <Text style={styles.labelBottom}>{t('AUTH.LOGIN')}</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.main}>
        <EmailNotification />
        
        <Text style={styles.title}>{t('AUTH.WE_HAVE_SENT')}</Text>
        <Text style={styles.description}>{t('AUTH.CHECK_YOUR_SPAM')}</Text>
        
        <View style={styles.boxContainer}>
          <View style={styles.bottom}>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL('mailto:gmail.com');
              }}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{t('AUTH.CHECK_EMAIL')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

export default EmailSent;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: Colors.light.background,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  main: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: 40,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'bold',
    width: Dimensions.get('window').width / 1.35,
  },
  description: {
    fontSize: 14,
    textAlign: 'center',
    width: Dimensions.get('window').width / 1.3,
    marginTop: 16,
    lineHeight: 20,
  },
  boxContainer: {
    paddingTop: 8,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  bottomFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 32,
  },
  bottomFooterText: {
    fontSize: 14,
  },
  labelBottom: {
    paddingHorizontal: 3,
    fontSize: 14,
    fontWeight: '600',
  },
});