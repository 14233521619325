import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import Colors from '../../constants/Colors';
import Header from '../../components/ui/Header';
import { TextInput } from '../../components/form/TextInput';
import { resetPasswordValidation } from '../../components/form/Validations';
import { yupResolver } from '@hookform/resolvers/yup';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../hooks/useAuthentication';
import Toast from 'react-native-toast-message';
import LanguageSwitcher from '../../components/ui/LanguageSwitcher';
import TextInputValidation from '../../components/form/TextInputValidation';
import Button from '../../components/ui/action/Button';

type FormValues = {
  email: string;
};

const ResetPassword: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const { t } = useTranslation();
  const { forgotPassword } = useAuthentication();
  const [values, setValues] = useState({
    loading: false,
    hasError: '',
  });
  const { ...methods } = useForm({
    resolver: yupResolver(resetPasswordValidation),
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
    if (!data) return;
    setValues({ ...values });

    try {
      const response = await forgotPassword(data);
      if (response) {
        setValues({ ...values, loading: false });
        Toast.show({
          type: 'success',
          text1: `${t('AUTH.RESTORE_PASSWORD')}`,
          text2: `${t('reset.PASSWORD_SUCCESS')}`,
          visibilityTime: 4000,
        });
        navigation.navigate('EmailSentOnRegistration', { email: data.email });
      }
    } catch (error: any) {
      setValues({ ...values, loading: false, hasError: error?.errors || 'Internal error' });
    }
  };

  return (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={true}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.container}>
        <View style={styles.touchStyle}>
          <Header screenName="Auth" />
          <LanguageSwitcher />
        </View>

        <View style={styles.main}>
          <View style={styles.content}>
            <Text style={styles.title}>{t('AUTH.RECOVER_PASSWORD')}</Text>
            <Text style={styles.description}>{t('AUTH.ENTER_EMAIL_THAT_USED')}</Text>
          </View>
          
          {!!values.hasError && (
            <View>
              <Text style={styles.errorText}>{values?.hasError}</Text>
            </View>
          )}
          
          <FormProvider {...methods}>
            <TextInput
              name="email"
              label={t('AUTH.EMAIL')}
              placeholder={t('AUTH.ENTER_YOUR_EMAIL')}
              keyboardType="email-address"
              autoCapitalize="none"
              defaultValue=""
              placeholderTextColor={Colors.light.placeholder}
              style={styles.inputStyle}
              required
            />
            {methods?.formState?.errors?.email && (
              <TextInputValidation>{t(methods?.formState?.errors?.email?.message)}</TextInputValidation>
            )}
          </FormProvider>

          <View style={styles.bottom}>
            <Button 
              disabled={values.loading}
              label={'AUTH.RESET_PASSWORD'}
              onPress={methods.handleSubmit(onSubmit)}
              bg={"dark"}
            />
          </View>

          <View style={styles.bottomFooter}>
            <Text style={styles.bottomFooterText}>{t('AUTH.REMEMBER_PASSWORD')}</Text>
            <TouchableOpacity onPress={() => navigation.navigate('SignIn')}>
              <Text style={styles.labelBottom}>{t('AUTH.LOGIN_NOW')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

export default ResetPassword;


const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.white,
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.white,
    flexGrow: 1,
  },
  main: {
    width: '100%',
  },
  content: {
    paddingVertical: 32,
  },
  touchStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    lineHeight: 28,
    fontWeight: '400',
  },
  description: {
    fontSize: 14,
    lineHeight: 18,
    fontWeight: '400',
    width: Dimensions.get('window').width / 1.3,
    marginTop: 12,
    color: Colors.light.textLight,
  },
  errorText: {
    textAlign: 'center',
    color: Colors.light.red,
  },
  inputStyle: {
    backgroundColor: Colors.light.white,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  bottomFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24,
  },
  bottomFooterText: {
    fontSize: 14,
  },
  labelBottom: {
    paddingHorizontal: 3,
    fontSize: 14,
    fontWeight: '600',
  },
});