import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { Shopware6, Shopware5, Shopify, WooCommerce, Magento, Oxid, Ebay, Amazon } from '../../assets/images';
import { StyleSheet, Text, Switch, TouchableOpacity, View, Platform } from 'react-native';
import Colors from '../../constants/Colors';
import { ShopDetailsBottomSheetModal } from '../bottom-sheet-modal/shops/ShopDetailsBottomSheetModal';
import { Modalize } from 'react-native-modalize';
import { useIsFocused } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import api from '../../config/api';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 8,
    paddingBottom: 0,
  },
  main: {
    borderWidth: 1,
    borderRadius: 12,
    backgroundColor: Colors.light.white,
    borderColor: Colors.light.athensGray,
    paddingHorizontal: 20,
    paddingVertical: Platform.OS === 'android' ? 6 : 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 1,
    shadowRadius: 4,
    shadowColor: Colors.light.shadowColor,
    elevation: 2,
    
  },
 
  leftSide: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  shopDetailsBtn: { width: '80%' },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  viewShop: {
    flexDirection: 'row',
  },
  rightSide: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

interface IShopItem {
  id: string;
  name: string;
  status?: boolean;
  platformType: number;
  getAllShops: () => void;
  onActiveDisableShop: (id: string, status: boolean) => void;
}

const renderPlatformInfo = (platformType: number) => {
  let platformLogo;

  switch (platformType) {
    case 0:
      platformLogo = <Shopware6 />;
      break;
    case 1:
      platformLogo = <Shopware5 />;
      break;
    case 2:
      platformLogo = <Shopify />;
      break;
    case 3:
      platformLogo = <WooCommerce />;
      break;
    case 4:
      platformLogo = <Magento />;
      break;
    case 5:
      platformLogo = <Oxid />;
      break;
    case 6:
      platformLogo = <Ebay />;
      break;
    case 7:
      platformLogo = <Amazon />;
      break;
    default:
      platformLogo = null;
  }

  return <View style={styles.viewShop}>{platformLogo}</View>;
};

const ShopItem = ({ id, name, status, platformType, getAllShops, onActiveDisableShop }: IShopItem) => {
  const bottomSheetRef = useRef<Modalize>(null);
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const handlePresentModalPress = useCallback(() => {
    bottomSheetRef.current?.open();
  }, []);

  const { orderStatusMethod } = useAuthentication();
  const [orderStatus, setOrderStatus] = useState({
    loading: true,
    shopDetails: [],
    orderStatusShops: '',
    paymentStatusShops: '',
  });

  const responseFun = async () => {
    try {
      const responseapi = await orderStatusMethod(id);
      setOrderStatus({
        ...orderStatus,
        shopDetails: responseapi,
        orderStatusShops: responseapi?.shop?.shop_order_statuses,
        paymentStatusShops: responseapi?.shop?.shop_order_payment_statuses,
        loading: false,
      });
    } catch (error) {
      return error;
    }
  };

  const configureShopActives = async (ids: string, status: boolean) => {
    if (!ids) return;
    const body = {
      status: status,
    };
    try {
      await api('/shop/order/status/' + ids, 'PUT', body);
      await responseFun();
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.SUCCESSFULLY_UPDATES_ORDER')}`,
        visibilityTime: 1000,
      });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
        visibilityTime: 1000,
      });
    }
  };

  const paymentStatusFunction = async (ids: string, status: boolean) => {
    if (!ids) return;
    const body = {
      status: status,
    };
    try {
      await api('/shop/order/payment/status/' + ids, 'PUT', body);
      await responseFun();
      Toast.show({
        type: 'success',
        text1: 'Successfully updated payment status',
        visibilityTime: 1000,
      });
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
        visibilityTime: 1000,
      });
    }
  };

  useEffect(() => {
    if (isFocused) {
      responseFun();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <ShopDetailsBottomSheetModal
        bottomSheetRef={bottomSheetRef}
        name={name}
        status={status}
        id={id}
        getAllShops={getAllShops}
        onActiveDisableShop={onActiveDisableShop}
        orderStatusShops={orderStatus.orderStatusShops}
        paymentStatusShops={orderStatus.paymentStatusShops}
        shopDetails={orderStatus.shopDetails}
        configureShopActives={configureShopActives}
        paymentStatusFunction={paymentStatusFunction}
      />
      <View style={styles.main}>
        <TouchableOpacity style={styles.shopDetailsBtn} onPress={handlePresentModalPress}>
          <View style={styles.leftSide}>
            <Text style={styles.title}>{name}</Text>
            {renderPlatformInfo(platformType)}
          </View>
        </TouchableOpacity>
        <View style={styles.rightSide}>
          <Switch
            trackColor={{ false: Colors.light.trackColorFalse, true: Colors.light.blueRibbonEnable }}
            thumbColor={status ? Colors.light.blueRibbon : Colors.light.trackColorFalse}
            ios_backgroundColor={Colors.light.athensGray}
            value={status}
            onValueChange={(value: any) => onActiveDisableShop(id, value)}
            style={{ transform: [{ scaleX: 1 }, { scaleY: 1 }] }}
          />
        </View>
      </View>
    </View>
  );
};

export default memo(ShopItem);
