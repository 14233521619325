import React, { useCallback, useState } from 'react';
import Colors from '../../../constants/Colors';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import SpaceItem from '../../settings/spaces/SpaceItem';
import { Modalize } from 'react-native-modalize';
import { Portal } from '@gorhom/portal';
import { useTranslation } from 'react-i18next';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import JoinSpaceScreen from '../../../screens/dashboard/spaces/JoinSpaceScreen';
import CreateSpaceScreen from '../../../screens/dashboard/spaces/CreateSpaceScreen';
import api from '../../../config/api';
import { useShopStore } from '../../../store/shops.state';
import { useDashboardStore } from '../../../store/dashboard.state';

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    paddingTop: 20,
  },
  footerBottom: {
    backgroundColor: Colors.light.background,
    marginBottom: 44,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  addNewSpace: {
    backgroundColor: 'black',
    width: 170,
    borderRadius: 8,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  joinNewSpace: {
    backgroundColor: Colors.light.grayDark,
    width: 170,
    borderRadius: 8,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  addNewSpaceText: {
    color: Colors.light.blueRibbon,
    fontSize: 14,
    fontWeight: '600',
    marginLeft: 8,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 24,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  buttonTextJoin: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});

type Space = {
  id: string;
  name: string;
  code: string;
};

interface IAddShopBottomSheetModal {
  spacePerUser: any;
  defaultSpace: any;
  bottomSheetRef: any;
  onAddSpace: () => void;
  onJoinSpace: () => void;
  setDefaultSpace: (id: string, name: string, code: string) => void;
}

export const SpacesBottomSheetModal = ({
  spacePerUser,
  defaultSpace,
  bottomSheetRef,
  onAddSpace,
  onJoinSpace,
  setDefaultSpace,
}: IAddShopBottomSheetModal) => {
  const { t } = useTranslation();
  const { setAllShops }: any = useShopStore();
  const { setActiveShop }: any = useDashboardStore();
  const backBtn = () => {
    bottomSheetRef.current?.close();
  };

  const [values, setValues] = useState({
    isJoinHere: false,
  });

  const setItemAndData = async (item: Space) => {
    setDefaultSpace(item.id, item.name, item.code);

    const response = await api('/shops/space/' + item.id, 'GET', null);
    if (response) {
      const firstActiveShop = response.data.find((shop: any) => shop.status === 1);
      setAllShops(response?.data || []);
      setActiveShop({
        name: firstActiveShop?.name,
        id: firstActiveShop?.id,
        shopCurrency: firstActiveShop?.currency_iso_code,
      });
    }
  };

  const renderItem = useCallback(
    ({ item }: any) => (
      <SpaceItem
        key={item.name}
        title={defaultSpace.id === item?.id ? defaultSpace.name : item?.name}
        alert={defaultSpace.id === item.id && 'default'}
        onPressItem={() => setItemAndData(item)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultSpace]
  );

  {
    !values?.isJoinHere ? <CreateSpaceScreen /> : <JoinSpaceScreen />;
  }
  const onSwapView = () => {
    setValues({ ...values, isJoinHere: !values.isJoinHere });
  };

  const renderFooter = () => (
    <View style={styles.footerBottom}>
      <TouchableOpacity onPress={onAddSpace} style={styles.addNewSpace}>
        <Text style={styles.buttonText}>{t('AUTH.CREATE_SPACE')}</Text>
      </TouchableOpacity>

      <TouchableOpacity onPress={onJoinSpace} style={styles.joinNewSpace}>
        <Text style={styles.buttonTextJoin}>{t('AUTH.JOIN_SPACE')}</Text>
      </TouchableOpacity>
    </View>
  );

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        childrenStyle={styles.container}
        flatListProps={{
          data: spacePerUser,
          renderItem: renderItem,
          keyExtractor: (item) => item.name,
          showsVerticalScrollIndicator: false,
        }}
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.SPACES')}`, backBtn, `${t('AUTH.CLOSE')}`)}
        FooterComponent={renderFooter()}
      />
    </Portal>
  );
};
