import React, { useState, useRef } from 'react';
import { StyleSheet, Text, View, Dimensions, TouchableOpacity } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Colors from '../../constants/Colors';
import { signUpValidation } from '../../components/form/Validations';
import { TextInput } from '../../components/form/TextInput';
import Header from '../../components/ui/Header';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useAuthentication } from '../../hooks/useAuthentication';
import { useTranslation } from 'react-i18next';
import LanguageSwitcherAPI from '../../components/ui/LanguageSwitcherAPI';
import PasswordTextInput from '../../components/form/PasswordTextInput';
import TextInputValidation from '../../components/form/TextInputValidation';
import Button from '../../components/ui/action/Button';
import { Modalize } from 'react-native-modalize';
import { useConfigStore } from '../../store/config.state';
import { TermsBottomSheetModal } from '../../components/bottom-sheet-modal/about/TermsBottomSheetModal';
import Checkbox from '../../components/ui/Checkbox';

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  password_confirmation: string;
  terms_conditions: boolean;
  language: string;
};

const SignUpScreen: React.FC<StackScreenProps<any>> = ({ navigation }) => {
  const { currentLanguage, setCurrentLanguage }: any = useConfigStore();
  const [values, setValues] = useState({
    loading: false,
    hasError: '',
  });

  const bottomSheetTermsRef = useRef<Modalize>(null);

  const { t } = useTranslation();
  const { registerUser } = useAuthentication();
  const { ...methods } = useForm({ resolver: yupResolver(signUpValidation), mode: 'all' });

  const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
    if (!data) return;
    setValues({ ...values, loading: true, hasError: '' });

    try {
      const response = await registerUser(data);
      if (response) {
        setValues({ ...values, loading: false });
        navigation.navigate('ReviewScreen')
      }
    } catch (error: any) {
      setValues({ ...values, loading: false, hasError: error?.errors || 'Internal error' });
    }
  };

  return (
    <>
      <KeyboardAwareScrollView
        style={styles.keyboardAvoidStyleContainer}
        scrollEnabled={true}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps={'handled'}
      >
        <View style={styles.container}>
          <View style={styles.touchStyle}>
            <Header screenName="Auth" />
            <LanguageSwitcherAPI />
          </View>

          <View style={styles.main}>
            <View style={styles.content}>
              <Text style={styles.title}>
                {t('AUTH.PLEASE_SIGNUP')} <Text style={styles.titleBold}>{t('AUTH.REGISTER')}</Text> {t('AUTH.OR')}{' '}
                <Text style={styles.titleBold}>{t('AUTH.LOGIN')} </Text>
                {t('AUTH.TO_CONTINUE')}{' '}
              </Text>
            </View>

            {!!values.hasError && (
              <View>
                <Text style={styles.errorText}>{values?.hasError}</Text>
              </View>
            )}
            <FormProvider {...methods}>
              <TextInput
                name="first_name"
                label={'AUTH.FIRST_NAME'}
                placeholder={t('AUTH.ENTER_YOUR_FIRST_NAME')}
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
                required
              />

              {methods.formState.errors.first_name && (
                <TextInputValidation>{t(methods?.formState?.errors?.first_name?.message)}</TextInputValidation>
              )}

              <TextInput
                name="last_name"
                label={'AUTH.LAST_NAME'}
                placeholder={t('AUTH.ENTER_YOUR_LAST_NAME')}
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
                required
              />
              {methods.formState.errors.last_name && (
                <TextInputValidation>{t(methods?.formState?.errors?.last_name?.message)}</TextInputValidation>
              )}

              <TextInput
                name="email"
                label={'AUTH.EMAIL'}
                placeholder={t('AUTH.ENTER_YOUR_EMAIL')}
                keyboardType="email-address"
                autoCapitalize="none"
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
                required
              />
              {methods.formState.errors.email && (
                <TextInputValidation>{t(methods?.formState?.errors?.email?.message)}</TextInputValidation>
              )}

              <TextInput
                name="language"
                placeholder={'language'}
                autoCapitalize="none"
                placeholderTextColor={Colors.light.placeholder}
                style={{ display: 'none' }}
                defaultValue={currentLanguage}
              />

              {methods.formState.errors.language && (
                <TextInputValidation>{t(methods?.formState?.errors?.language?.message)}</TextInputValidation>
              )}

              <PasswordTextInput
                name="password"
                label={'AUTH.PASSWORD'}
                placeholder={t('AUTH.ENTER_YOUR_PASSWORD')}
                autoCapitalize="none"
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
                required
              />
              {methods.formState.errors.password && (
                <TextInputValidation>{t(methods?.formState?.errors?.password?.message)}</TextInputValidation>
              )}

              <PasswordTextInput
                name="password_confirmation"
                label={'AUTH.PASSWORD_CONFIRMATION'}
                placeholder={t('AUTH.PASSWORD_CONFIRMATION')}
                autoCapitalize="none"
                placeholderTextColor={Colors.light.placeholder}
                style={styles.inputStyle}
                required
              />
              {methods.formState.errors.password_confirmation && (
                <TextInputValidation>
                  {t(methods?.formState?.errors?.password_confirmation?.message)}
                </TextInputValidation>
              )}

              <View style={styles.checkboxContainer}>
                <View style={styles.leftHeader}>
                  <Checkbox name="terms_conditions" control={methods?.control} />
                </View>
                <View style={styles.rightHeader}>
                  <Text style={styles.text}>
                    {t('AUTH.I_HAVE_READ')}{' '}
                    <Text onPress={() => bottomSheetTermsRef?.current?.open()} style={styles.linkText}>
                      {t('AUTH.TERMS_OF_SERVICE')}
                    </Text>
                    <Text> {t('AUTH.AND')} </Text>
                    <Text onPress={() => bottomSheetTermsRef?.current?.open()} style={styles.linkText}>
                      {t('AUTH.PRIVACY_POLIC')}
                    </Text>
                 
                  </Text>
                </View>
              </View>

              {methods.formState.errors.terms_conditions && (
                <TextInputValidation>{t(methods?.formState?.errors?.terms_conditions?.message)}</TextInputValidation>
              )}
            </FormProvider>

            <View style={styles.bottom}>
              <Button
                disabled={values.loading}
                onPress={methods.handleSubmit(onSubmit)}
                label={'AUTH.CREATE_ACCOUNT'}
                bg={'dark'}
                loading={values.loading}
              />
            </View>

            <View style={styles.bottomFooter}>
              <Text style={styles.bottomFooterText}>{t('AUTH.ALREADY_MEMBER')}</Text>
              <TouchableOpacity onPress={() => navigation.navigate('SignIn')}>
                <Text style={styles.labelBottom}>{t('AUTH.LOGIN_NOW')}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>

      <TermsBottomSheetModal bottomSheetRef={bottomSheetTermsRef} />
    </>
  );
};

export default SignUpScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.white,
  },
  main: {
    width: '100%',
  },
  content: {
    paddingVertical: 32,
  },
  touchStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    // width: Dimensions.get('window').width / 1.8,
  },
  titleBold: {
    fontWeight: '600',
  },
  errorText: {
    textAlign: 'center',
    color: Colors.light.red,
  },
  inputStyle: {
    backgroundColor: Colors.light.white,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
  },
  button: {
    backgroundColor: Colors.light.shark,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  buttonLogin: {
    backgroundColor: Colors.light.athensGray,
    width: '100%',
    borderRadius: 8,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: Colors.light.background,
    fontSize: 14,
    fontWeight: 'bold',
  },
  buttonLoginText: {
    color: Colors.light.mirage,
    fontSize: 14,
    fontWeight: 'bold',
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.white,
    flexGrow: 1,
  },
  bottomFooter: {
    marginTop: 24,
    marginBottom: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  bottomFooterText: {
    fontSize: 14,
  },
  labelBottom: {
    paddingHorizontal: 3,
    fontSize: 14,
    fontWeight: '600',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
    alignItems: 'center'
  },
  leftHeader: {
    marginRight: 0,
  },
  rightHeader: {
    flexDirection: 'row', // Display text in the same line
    alignItems: 'center',
    width:"95%"
  },
  text: {
    fontSize: 12,
    marginRight: 10,
    fontWeight: '500',
    lineHeight: 20,
  },
  checkboxLabel: {
    fontWeight: '500',
    fontSize: 18,
  },
  link: {
    flexDirection: 'row', // Display text in the same line
    alignItems: 'center', // Vertically center the text
  },
  linkText: {
    textDecorationLine: 'underline',
  },
});
