import { Alert, Modal, SafeAreaView, TouchableOpacity, Animated, Easing, StyleSheet, Text, View } from 'react-native';
import React, { useEffect, memo, useCallback, useRef, useState } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import api from '../../config/api';
import { Modalize } from 'react-native-modalize';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-message';
import ListItemDivider from '../../components/ui/ListItemDivider';
import { ShopDetailsBottomSheetModal } from '../bottom-sheet-modal/shops/ShopDetailsBottomSheetModal';
import Colors from '../../constants/Colors';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'center',
  },
  popup: {
    borderRadius: 8,
    borderColor: '#333',
    backgroundColor: '#F0F2F6',
    paddingHorizontal: 10,
    position: 'absolute',
    top: 180,
    right: 15,
    height: 90,
  },
  options: {
    flexDirection: 'column',
  },
  deleteShop: {
    fontWeight: '500',
    color: 'red',
    paddingTop: 20,
  },
  configureShop: {
    fontWeight: '500',
    textAlign: 'center',
    paddingTop: 20,
  },
});

interface IShopItem {
  id: string;
  name: string;
  status?: boolean;
  getAllShops: () => void;
  onActiveDisableShop: (id: string, status: boolean) => void;
}
const PopMenu = ({ id, name, status, onActiveDisableShop, getAllShops }: IShopItem) => {
  const bottomSheetRef = useRef<Modalize>(null);
  const { t } = useTranslation();

  const handlePresentModalPress = useCallback(() => {
    bottomSheetRef.current?.open();
  }, []);

  const [values, setValues] = useState({
    loading: true,
    shopDetails: [],
  });

  const scale = useRef(new Animated.Value(0)).current;
  const [visible, setVisible] = useState(false);

  function resizeBox(to: number) {
    to === 1 && setVisible(true);
    Animated.timing(scale, {
      toValue: to,
      useNativeDriver: true,
      duration: 200,
      easing: Easing.linear,
    }).start(() => to === 0 && setVisible(false));
  }

  const getShopDetails = async (shopId: string) => {
    try {
      const response = await api('/shop/' + shopId, 'GET', null);
      setValues({ ...values, shopDetails: response, loading: false });
    } catch (error) {
      setValues({ ...values, shopDetails: [], loading: false });
    }
  };

  const deleteShop = async (shopId: string) => {
    Alert.alert(
      `${t('AUTH.DELETE')} ${values?.shopDetails?.shop?.name}`,
      `${t('AUTH.ARE_YOU_SURE_DELETE')} ${t('USER')}`,
      [
        {
          text: 'Delete',
          onPress: async () => {
            try {
              await api('/shop/delete/' + shopId, 'DELETE');
              getAllShops();
              bottomSheetRef?.current?.close();
              Toast.show({
                type: 'success',
                text1: `${t('AUTH.SHOP_DETAILS')}`,
                text2: `${t('AUTH.YOUR_SHOP_HAS_BEEN_DELETED')}`,
              });
            } catch (error) {
              bottomSheetRef?.current?.close();
              Toast.show({
                type: 'error',
                text1: `${t('AUTH.SHOP_DETAILS')}`,
                text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
              });
            }
          },
          style: 'destructive',
        },
        {
          text: 'Cancel',
        },
      ]
    );
  };

  useEffect(() => {
    if (id) {
      getShopDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ShopDetailsBottomSheetModal
        bottomSheetRef={bottomSheetRef}
        id={id}
        name={name}
        onActiveDisableShop={onActiveDisableShop}
        status={status}
        getAllShops={getAllShops}
      />
      <TouchableOpacity onPress={() => resizeBox(1)}>
        <MaterialCommunityIcons name="dots-vertical" size={24} color="black" />
      </TouchableOpacity>
      <Modal transparent visible={visible}>
        <SafeAreaView style={{ flex: 1 }} onTouchStart={() => resizeBox(0)}>
          <Animated.View style={styles.popup}>
            <View style={styles.options}>
              <TouchableOpacity onPress={handlePresentModalPress}>
                <Text style={styles.configureShop}>Configure shop</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => deleteShop(id)}>
                <Text style={styles.deleteShop}>Delete shop</Text>
              </TouchableOpacity>
            </View>
          </Animated.View>
        </SafeAreaView>
      </Modal>
    </>
  );
};

export default PopMenu;
