import { View, Image, Text, TouchableOpacity, StyleSheet } from 'react-native';
import styles from '../auth.style';
import Svg, { LinearGradient, Rect, Stop } from 'react-native-svg';
import { StatHubsWhiteLogo } from '../../../assets/images/index';
import Button from '../../../components/ui/action/Button';
import { useTranslation } from 'react-i18next';
import { StackScreenProps } from '@react-navigation/stack';

//TODO: REMOVE ANY FROM StackScreenProps, getting to it after refactor all the Type 
const ReviewScreen: React.FC<StackScreenProps<any>> = ({navigation}) => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Svg height="100%" width="100%" style={StyleSheet.absoluteFill}>
        <LinearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
          <Stop offset="0" stopColor="#6E44FF" />
          <Stop offset="100%" stopColor="#8338EC" />
        </LinearGradient>
        <Rect x="0" y="0" width="100%" height="100%" fill="url(#grad)" />
      </Svg>
      <View style={styles.content}>
        <View style={styles.logoContainer}>
          <StatHubsWhiteLogo />
        </View>
  
          <Image source={require('../../../assets/images/reviewImg.png')} style={styles.image} />
    
        <View style={styles.actionContainer}>
          <Text style={styles.title}>{t('AUTH.REVIEW_TITTLE')}</Text>
          <View style={styles.descriptionContainer}>
            <Text style={styles.descriptionText}>
              {t('AUTH.THANK_YOU_FOR_REG')} {'\n'} {t('AUTH.REVIEW_DESC')}{' '}
              <Text style={{ fontWeight: 'bold' }}> {t('AUTH.24_HOURS')}</Text> {t('AUTH.REVIEW_DESC_2')}{' '}
              <Text style={{ fontWeight: 'bold' }}>{t('AUTH.12_MONTHS')}</Text> {t('AUTH.FREE_ACCESS')}
            </Text>
            <Text style={styles.descriptionText}>{t('AUTH.REVIEW_DESC_3')}</Text>
          </View>
          <View style={styles.buttonContainer}>
            <Button onPress={() => {navigation.navigate('SignIn') }} label={'AUTH.BACK_TO_LOGIN'} bg={'dark'} />
          </View>
        </View>
      </View>
    </View>
  );
};
export default ReviewScreen;
