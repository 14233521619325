import { useState, useRef } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Colors from '../../../constants/Colors';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSpaceStore } from '../../../store/spaces.state';
import api from '../../../config/api';
import hasNotch from '../../../utils/isNotch';
import Header from '../../ui/Header';
import SpaceInformation from './SpaceInformation';
import PageTitle from '../../ui/PageTitle';
import ShopsInSpace from './ShopsInSpace';
import MenuItem from '../../ui/action/MenuItem';
import Toast from 'react-native-toast-message';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { Modalize } from 'react-native-modalize';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import VerifyUserIdentityModal from '../../bottom-sheet-modal/VerifyUserIdentityModal';

type StateProps = {
  loading: boolean;
};

export const SpaceDetails = () => {
  const [state, setState] = useState<StateProps>({
    loading: false,
  });

  const bottomSheetRef = useRef<Modalize>(null);

  const { ...methods } = useForm({
    resolver: yupResolver(
      yup.object({
        current_password: yup.string().required('VALIDATIONS.REQUIRED_PASSWORD'),
      })
    ),
    mode: 'all',
  });

  const { spacePerUser, defaultSpace }: any = useSpaceStore();
  const { navigation }: any = useNavigation();
  const { t } = useTranslation();
  const leaveSpaceHandler = async () => {
    try {
      const response = await api('/space/delete/' + defaultSpace?.id, 'DELETE', null);

      if (!defaultSpace?.id) return;
      setState({ ...state, loading: true });
      if (response?.success) {
        bottomSheetRef.current?.close();

        Toast.show({
          type: 'success',
          text1: `${t('AUTH.SPACE_UPDATE')}`,
          text2: `${t('AUTH.SPACE_LEFT_SUCCESSFULLY')}`,
          visibilityTime: 4000,
        });
        () => navigation.goBack();
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
        visibilityTime: 5000,
      });
      setState({ ...state, loading: false });
    }
  };

  return (
    <>
      <KeyboardAwareScrollView
        style={styles.keyboardAvoidStyleContainer}
        scrollEnabled={true}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps={'handled'}
      >
        <View style={styles.container}>
          <Header screenName={`Main menu`} values="null" setValues="null" />
          <PageTitle title={'AUTH.SPACE_DETAIL'} description={'AUTH.SPACE_VIEW_DETAILS'} />
          <SpaceInformation />
          <ShopsInSpace />
          <MenuItem
            disabled={state.loading}
            label={'AUTH.LEAVE_SPACE'}
            onPress={() => bottomSheetRef.current?.open()}
            color={'red'}
          />
        </View>
      </KeyboardAwareScrollView>

      <VerifyUserIdentityModal
        bottomSheetRef={bottomSheetRef}
        callback={leaveSpaceHandler}
        title={'AUTH.LEAVE_SPACE'}
        subTitle={'AUTH.ARE_YOU_SURE_DELETE_SPACE'}
        actionText={'AUTH.LEAVE_SPACE'}
      />
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.background,
  },
  chevronButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 14,
    width: '100%',
  },
  errorText: {
    textAlign: 'center',
    color: Colors.light.red,
  },
  accountStyle: {
    paddingTop: 20,
    fontWeight: '500',
    paddingBottom: 8,
    fontSize: 18,
  },
  subTitle: {
    color: Colors.light.textLight,
  },
  informationStyle: {
    paddingTop: 40,
    fontWeight: '500',
  },
  inputStyleEdit: {
    backgroundColor: Colors.light.grayDark,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
  bottom: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 50,
  },
  main: {
    width: '100%',
  },
  onActiveButton: {
    backgroundColor: Colors.light.midGray,
  },
  boxContainer: {
    paddingTop: 30,
  },
  goForward: {
    flexDirection: 'row',
    marginBottom: 50,
    alignItems: 'center',
  },
  secondStyle: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
  inputValidation: {
    fontSize: 12,
    color: Colors.light.red,
    paddingBottom: 12,
    paddingTop: 6,
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editTextDisabled: {
    color: Colors.light.editTextDisabled,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerSettings: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
});
