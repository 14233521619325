import { Feather, FontAwesome } from '@expo/vector-icons';
import { Portal } from '@gorhom/portal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import Colors from '../../../constants/Colors';
import { useDashboardStore } from '../../../store/dashboard.state';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';

const styles = StyleSheet.create({
  container: {
    paddingBottom: 48,
    paddingHorizontal: 16,
  },
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  name: {
    fontWeight: '500',
    flex: 1,
  },
  image: {
    marginRight: 12,
  },
  circle: {
    // backgroundColor: Colors.light.blueRibbon,
  },
  searchContainer: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 1,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
    marginBottom: 16,
  },
});

interface IShopSystemBottomSheetModal {
  bottomSheetRef: any;
  goBackBtn: () => void;
  onOpenModal?: () => void;
  countries: any;
  methods: any;
}

export const SelectCountryBottomSheetModal = ({
  bottomSheetRef,
  goBackBtn,
  countries,
  methods,
}: IShopSystemBottomSheetModal) => {
  const { t } = useTranslation();
  const { selectedCountry, setSelectedCountry }: any = useDashboardStore();
  const [searchQuery, setSearchQuery] = useState('');

  const backBtn = () => {
    goBackBtn();
  };

  const onSelectedCountry = async (country: any) => {
    setSelectedCountry({ label: country.label, value: country.value });
    methods.setValue('country', country.value);
  };

  const filteredCountries = countries.filter((country) =>
    country.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderContent = () => {
    return (
      <View style={styles.container}>
        <View style={styles.searchContainer}>
          <TextInput
            style={styles.inputStyle}
            onChangeText={setSearchQuery}
            value={searchQuery}
            placeholder={t('AUTH.SEARCH')}
          />
        </View>

        {filteredCountries.map((item: any, index) => {
          return (
            <TouchableOpacity key={index} style={styles.touchable} onPress={() => onSelectedCountry(item)}>
              <Text style={styles.name}>{item.label}</Text>
              <View style={styles.circle}>
                {item.value === selectedCountry.value ? (
                  <FontAwesome name="dot-circle-o" size={20} color={Colors.light.blueRibbon} />
                ) : (
                  <Feather name="circle" size={20} color={Colors.light.silver} />
                )}
              </View>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        modalHeight={700}
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.SELECT_COUNTRY')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
