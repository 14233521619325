import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';
import React, { memo, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Alert } from 'react-native';
import api from '../../../config/api';
import Toast from 'react-native-toast-message';
import { useAuthStore } from '../../../store/auth.state';
import { useSpaceStore } from '../../../store/spaces.state';
import * as Haptics from 'expo-haptics';
import { useTranslation } from 'react-i18next';
import { EditUserInviteBottomSheetModal } from '../../bottom-sheet-modal/users/EditUserInviteBottomSheetModal';
import { Modalize } from 'react-native-modalize';

type UserItemProps = {
  id: string;
  name: string;
  lastName: string;
  email: string;
  values?: any;
  isFirstItem: boolean;
  isLastItem: boolean;
  onRefresh?: any;
};

const UserItem = ({
  id,
  name,
  lastName,
  email,
  values,
  isFirstItem,
  isLastItem,
  onRefresh,
  ...props
}: UserItemProps) => {
  const [state, setState] = useState({
    loadingShop: false,
    loadingRole: false,
    shops: [],
    roles: [],
    userShops: [],
    userRole: '',
  });

  const editBottomSheetUserRef = useRef<Modalize>(null);

  const { t } = useTranslation();

  const { userData }: any = useAuthStore();
  const { defaultSpace }: any = useSpaceStore();
  const isDisabled = userData?.user?.id !== id;

  const getRoles = async () => {
    setState((prevState) => ({ ...prevState, loadingRole: true }));
    try {
      const response = await api('/user/roles', 'GET', null);
      setState((prevState) => ({ ...prevState, roles: response?.data || [], loadingRole: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, roles: [], loadingRole: false }));
    }
  };

  const deleteUser = async () => {
    if (isDisabled) return;
    return Alert.alert(`${t('AUTH.DELETE')} ${name} ${lastName}?`, `${t('AUTH.ARE_YOU_SURE_DELETE')}`, [
      {
        text: `${t('AUTH.DELETE')}`,
        onPress: async () => {
          try {
            Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
            await api('/user/' + id, 'DELETE', null);
            Toast.show({
              type: 'success',
              text1: `${t('AUTH.USER')}`,
              text2: `${t('AUTH.USER_HAS_BEEN_DELETED')}`,
            });
            onRefresh();
          } catch (e) {}
        },
      },
      {
        text: `${t('AUTH.CANCEL')}`,
      },
    ]);
  };

  const editUserInviteHandler = async () => {
    await getRoles();
    editBottomSheetUserRef?.current?.open();
  };

  const getUserDetailsInSpace = async () => {
    try {
      const response = await api(`/space/${defaultSpace?.id}/users/${id}`, 'GET', null);
      setState((prevState) => ({
        ...prevState,
        userShops: response?.shops || [],
        userRole: response?.data?.role_name,
      }));
    } catch (error) {}
  };

  const getShopBySpace = async (id: string) => {
    if (!id) return;
    setState({ ...state, loadingShop: true });

    try {
      const response = await api('/shops/space/' + defaultSpace.id, 'GET', null);
      if (response) {
        setState({ ...state, shops: response?.data || [], loadingShop: false });
      }
    } catch (error) {
      setState({ ...state, shops: [], loadingShop: false });
    }
  };

  useEffect(() => {
    getUserDetailsInSpace();
  }, []);

  useEffect(() => {
    getShopBySpace(defaultSpace?.id);
  }, []);

  return (
    <>
      <View style={styles.userItem}>
        {values.edit && (
          <>
            <TouchableOpacity onPress={deleteUser} disabled={isDisabled}>
              <View style={styles.deleteBtn}>
                <AntDesign name="minuscircle" color={Colors.light.red} size={20} />
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={editUserInviteHandler}>
              <View style={styles.editBtn}>
                <MaterialCommunityIcons name="pencil-circle" size={24} color={Colors.light.hitGray} />
              </View>
            </TouchableOpacity>
          </>
        )}

        <View style={[styles.itemRow, values.edit]} {...props}>
          <View
            style={[
              styles.userDetails,
              isFirstItem && styles.userDetailsFirstItem,
              isLastItem && styles.userDetailsLastItem,
            ]}
          >
            <Text style={styles.name}>
              {name} {lastName}
            </Text>
            <Text style={styles.role}>{userData?.user?.id === id ? `${state.userRole}` : `${t('AUTH.VIEWER')}`}</Text>
          </View>
        </View>
      </View>

      <EditUserInviteBottomSheetModal
        bottomSheetRef={editBottomSheetUserRef}
        shops={state?.shops || []}
        roles={state?.roles}
        id={id}
        email={email}
        userShops={state?.userShops || []}
        userRole={state?.userRole}
      />
    </>
  );
};

export default memo(UserItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  userItem: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  userDetails: {
    backgroundColor: Colors.light.gray,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 14,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.light.borderBottomLine,
  },
  itemRow: {
    flex: 1,
  },
  deleteBtn: {
    width: 30,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -1,
  },
  editBtn: {
    width: 30,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -1,
    marginRight: 10,
  },
  name: {
    fontSize: 16,
    fontWeight: '500',
  },
  role: {
    fontSize: 14,
  },
  userDetailsFirstItem: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  userDetailsLastItem: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomWidth: 0,
  },
});
