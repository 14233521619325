import React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import Colors from '../../../constants/Colors';
import { WebView } from 'react-native-webview';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import env from '../../../config/constants';
import { useTranslation } from 'react-i18next';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: Colors.light.background,
    padding: 24,
  },
  webViewContainer: {
    flex: 1,
    width: '100%',
    padding: 12,
    height: Dimensions.get('window').height / 1.15,
  },
});

interface ITermsBottomSheetModal {
  bottomSheetRef: any;
}

export const TermsBottomSheetModal = ({ bottomSheetRef }: ITermsBottomSheetModal) => {
  const { t } = useTranslation();
  const backBtn = () => {
    bottomSheetRef.current?.close();
  };
  const renderContent = () => (
    <View style={styles.webViewContainer}>
      <WebView
        originWhitelist={['*']}
        source={{ uri: env.TERMS_USE }}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        startInLoadingState={true}
      />
    </View>
  );
  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        scrollViewProps={{ showsVerticalScrollIndicator: false }}
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.TERMS_OF_USE')}`, backBtn, `${t('AUTH.CLOSE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
