import React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import Colors from '../../../constants/Colors';
import WebView from 'react-native-webview';
import env from '../../../config/constants';
import { Portal } from '@gorhom/portal';
import { Modalize } from 'react-native-modalize';
import { useTranslation } from 'react-i18next';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: Colors.light.background,
    padding: 24,
  },
  webViewContainer: {
    flex: 1,
    width: '100%',
    padding: 12,
    height: Dimensions.get('window').height,
  },
});

interface IPrivacyBottomSheetModal {
  bottomSheetRef: any;
}

export const PrivacyBottomSheetModal = ({ bottomSheetRef }: IPrivacyBottomSheetModal) => {
  const { t } = useTranslation();
  const backBtn = () => {
    bottomSheetRef.current?.close();
  };
  const renderContent = () => (
    <View style={styles.webViewContainer}>
      <WebView
        originWhitelist={['*']}
        source={{ uri: env?.PRIVACY_POLICY }}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        startInLoadingState={true}
      />
    </View>
  );

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        scrollViewProps={{ showsVerticalScrollIndicator: false }}
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.PRIVACY_POLICY')}`, backBtn, `${t('AUTH.CLOSE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};
