import React, { useState } from 'react';
import { View, Text, StyleSheet, Platform, TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Colors from '../../../constants/Colors';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import hasNotch from '../../../utils/isNotch';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordValidation } from '../../form/Validations';
import api from '../../../config/api';
import * as Haptics from 'expo-haptics';
import { useAuthStore } from '../../../store/auth.state';
import Toast from 'react-native-toast-message';
import PasswordTextInput from '../../form/PasswordTextInput';
import TextInputValidation from '../../form/TextInputValidation';

type FormValues = {
  new_password: string;
};

const ChangePassword = () => {
  const [state, setState] = useState({
    loading: false,
    hasError: '',
  });

  const { t } = useTranslation();
  const navigation = useNavigation();

  const { userData }: any = useAuthStore();
  const userId = userData?.user?.id;

  const { ...methods } = useForm({
    resolver: yupResolver(updatePasswordValidation),
    mode: 'all',
  });

  const isDisabled = methods?.formState?.isDirty ? false : true;

  const onGoBack = () => {
    navigation.goBack();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: any) => {
    if (!data) return;

    setState({ ...state, loading: true });

    const body = {
      current_password: data.current_password,
      new_password: data?.new_password,
      confirm_new_password: data?.confirm_password,
    };

    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/user/update-password/' + userId, 'POST', body);
      setState({ ...state, loading: false });
      Toast.show({
        type: 'success',
        text1: `${t('AUTH.UPDATE_PASSWORD')}`,
        text2: `${t('AUTH.PASSWORD_UPDATED_SUCCESSFULY')}`,
      });
      navigation.goBack();
    } catch (error) {
      setState({ ...state, loading: false });

      Toast.show({
        type: 'error',
        text1: `${t('AUTH.UPDATE_PASSWORD')}`,
        text2: `${t('VALIDATIONS.CURRENT_PASSWORD_INCORRECT')}`,
        visibilityTime: 5000,
      });
    }
  };

  return (
    <KeyboardAwareScrollView
      style={styles.keyboardAvoidStyleContainer}
      scrollEnabled={true}
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps={'handled'}
    >
      <View style={styles.container}>
        <View style={styles.headerSettings}>
          <TouchableOpacity style={styles.goBack} onPress={onGoBack}>
            <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
            <Text style={styles.goBackText}>{t('AUTH.ACCOUNT_SETTINGS')}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={methods.handleSubmit(onSubmit)} disabled={isDisabled}>
            <Text style={[styles.editText, isDisabled && styles.editTextDisabled]}>{t('AUTH.SAVE')}</Text>
          </TouchableOpacity>
        </View>

        <View>
          <Text style={styles.pageTitle}>{t('AUTH.CHANGE_PASSWORD')}</Text>
        </View>

        <View style={styles.formContainer}>
          <FormProvider {...methods}>
            <PasswordTextInput
              name="current_password"
              label={'AUTH.CURRENT_PASSWORD'}
              placeholder={t('AUTH.CURRENT_PASSWORD')}
              placeholderTextColor={Colors.light.placeholder}
              style={[styles.inputStyle]}
              required
            />

            {methods.formState.errors.current_password && (
              <TextInputValidation>{t(methods?.formState?.errors?.current_password?.message)}</TextInputValidation>
            )}

            <PasswordTextInput
              name="new_password"
              label={'AUTH.NEW_PASSWORD'}
              placeholder={t('AUTH.NEW_PASSWORD')}
              placeholderTextColor={Colors.light.placeholder}
              style={[styles.inputStyle]}
              required
            />

            {methods.formState.errors.new_password && (
              <TextInputValidation>{t(methods?.formState?.errors?.new_password?.message)}</TextInputValidation>
            )}

            <PasswordTextInput
              name="confirm_password"
              label={'AUTH.CONFIRM_PASSWORD'}
              placeholder={t('AUTH.CONFIRM_PASSWORD')}
              placeholderTextColor={Colors.light.placeholder}
              style={[styles.inputStyle]}
              required
            />

            {methods.formState.errors.confirm_password && (
              <TextInputValidation>{t(methods?.formState?.errors?.confirm_password?.message)}</TextInputValidation>
            )}
          </FormProvider>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

export default ChangePassword;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    justifyContent: 'space-between',
    backgroundColor: Colors.light.background,
  },
  keyboardAvoidStyleContainer: {
    backgroundColor: Colors.light.background,
    flexGrow: 1,
  },
  headerSettings: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
    marginBottom: 20,
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  editTextDisabled: {
    color: Colors.light.editTextDisabled,
  },
  pageTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  formContainer: {
    paddingTop: 30,
  },
  inputStyle: {
    backgroundColor: Colors.light.background,
    borderWidth: 1,
    borderColor: Colors.light.border,
    borderRadius: 8,
    paddingHorizontal: 16,
    height: 54,
  },
});
