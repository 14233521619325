export default [
  {
    label: 'Shopware 6',
    value: 0,
  },
  {
    label: 'Shopware 5',
    value: 1,
  },
  {
    label: 'Shopify',
    value: 2,
  },
  {
    label: 'Woocommerce',
    value: 3,
  },
  {
    label: 'Magento',
    value: 4,
  },
  {
    label: 'Oxid',
    value: 5,
  },
];
