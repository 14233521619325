import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useSpaceStore } from '../../../store/spaces.state';
import { SpacesBottomSheetModal } from '../../bottom-sheet-modal/spaces/SpacesBottomSheetModal';
import { AddSpaceBottomSheetModal } from '../../bottom-sheet-modal/spaces/AddSpaceBottomSheetModal';
import { JoinSpaceBottomSheetModal } from '../../bottom-sheet-modal/spaces/JoinSpaceBottomSheetModal';
import api from '../../../config/api';
import * as Haptics from 'expo-haptics';
import Toast from 'react-native-toast-message';
import { Modalize } from 'react-native-modalize';
import { useTranslation } from 'react-i18next';
import { generateColorHsl } from '../../../utils/generateRandomColors';
import { Feather } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';

export const SwitchSpaces = () => {
  const { t } = useTranslation();
  const { spacePerUser, defaultSpace, setDefaultSpace }: any = useSpaceStore();
  const color = generateColorHsl(defaultSpace?.name || 'John Doe');
  const [values, setValues] = useState({
    loading: false,
    error: '',
    data: [],
  });

  const bottomSheetRef = useRef<Modalize>(null);
  const addSpaceBottomSheetRef = useRef<Modalize>(null);
  const joinSpaceBottomSheetRef = useRef<Modalize>(null);

  const onGetAllSpaces = async () => {
    setValues({ ...values, loading: true, error: '' });
    try {
      const response = await api('/space/mySpaces', 'GET', null);
      if (response) {
        setValues({ ...values, data: response?.data || [], loading: false, error: '' });
      }
    } catch (error) {
      setValues({ ...values, data: [], loading: false, error: `${t('AUTH.SOMETHING_WENT_WRONG')}` });
    }
  };

  const onOpenSwitchSpace = async () => {
    bottomSheetRef.current?.open();
  };

  const onSubmitSpace = async (data: any) => {
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/space/create', 'POST', { name: data?.space_name });
      addSpaceBottomSheetRef.current?.close();
      onGetAllSpaces();
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SWITCH_SPACES')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    }
  };

  const onSubmitJoin = async (data: any) => {
    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      await api('/space/join', 'POST', { code: data?.space_code });
      Toast.show({
        type: 'success',
        text1: 'Successfully joined space',
      });
      joinSpaceBottomSheetRef.current?.close();
      onGetAllSpaces();
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: `${t('AUTH.SWITCH_SPACES')}`,
        text2: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
      });
    }
  };

  const onSetDefaultSpace = (id: string, name: string, code: string) => {
    if (!id || !name || !code) return;
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    setDefaultSpace({ id: id, name: name, code: code });
    bottomSheetRef.current?.close();
  };

  const onAddModalOpen = useCallback(() => {
    bottomSheetRef.current?.close();
    addSpaceBottomSheetRef.current?.open();
  }, []);

  const onJoinSpaceOpen = useCallback(() => {
    bottomSheetRef.current?.close();
    joinSpaceBottomSheetRef.current?.open();
  }, []);

  useEffect(() => {
    if (!values.data.length) {
      onGetAllSpaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!spacePerUser || !spacePerUser.length) return null;
  return (
    <>
      <AddSpaceBottomSheetModal bottomSheetRef={addSpaceBottomSheetRef} onSubmit={onSubmitSpace} />
      <JoinSpaceBottomSheetModal bottomSheetRef={joinSpaceBottomSheetRef} onSubmit={onSubmitJoin} />
      <SpacesBottomSheetModal
        bottomSheetRef={bottomSheetRef}
        spacePerUser={values.data}
        defaultSpace={defaultSpace}
        setDefaultSpace={onSetDefaultSpace}
        onAddSpace={onAddModalOpen}
        onJoinSpace={onJoinSpaceOpen}
      />

      <TouchableOpacity onPress={onOpenSwitchSpace}>
        <View style={styles.container}>
          <View style={styles.details}>
            <View style={{ ...styles.avatar, backgroundColor: color }}>
              <Text style={styles.avatarText}>{defaultSpace?.name.toUpperCase().slice(0, 1)}</Text>
            </View>
            <View>  
              <Text style={styles.space}>{t('AUTH.SPACES')}</Text>
              <Text style={styles.spaceName}>{defaultSpace?.name}</Text>
            </View>
          </View>
          <View style={styles.alertNotifications}>
            {values.loading && <ActivityIndicator />}
            {!!alert?.length && values?.data?.length?.toString() !== '0' && (
              <View style={styles.alertNotification}>
                <Text style={styles.alertNotificationText}>{values?.data?.length?.toString()}</Text>
              </View>
            )}

            <Feather name="chevron-right" size={34} color={Colors.light.silver} />
          </View>
        </View>
      </TouchableOpacity>
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
    backgroundColor: 'white',
    borderRadius: 8,
  },
  details: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: Colors.light.riptide,
    borderRadius: 40,
    marginRight: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarText: {
    fontSize: 30,
  },
  space: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 4,
  },
  spaceName: {
    fontSize: 14,
    color: Colors.light.textLight,
    fontWeight: '400',
  },
  alertNotificationText: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  alertNotifications: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertNotification: {
    marginRight: 8,
    backgroundColor: Colors.light.orange,
    paddingVertical: 5,
    paddingHorizontal: 12,
    borderRadius: 100,
  },
});
