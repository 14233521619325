import React, { useState, useEffect } from 'react';
import SectionTitle from '../../ui/SectionTitle';
import { useAuthStore } from '../../../store//auth.state';
import ListItem from '../../ui/ListItem';
import ListItemDivider from '../../ui/ListItemDivider';
import ListItemContainer from '../../ui/ListItemContainer';
import api from '../../../config/api';
import { useIsFocused } from '@react-navigation/native';

const AccountPersonalInformation = () => {
  const { userData }: any = useAuthStore();
  const [userInfo, setUserInfo] = useState();
  const isFocused = useIsFocused();

  const getUserDetails = async (userId: string) => {
    const response = await api('/user/' + userId, 'GET', null);
    if (response) {
      setUserInfo({
        first_name: response?.data?.first_name,
        last_name: response?.data?.last_name,
        email: response?.data?.email,
      });
    }
  };

  useEffect(() => {
    if (isFocused) {
      getUserDetails(userData?.user?.id);
    }
  }, [isFocused]);

  return (
    <>
      <SectionTitle title={'AUTH.PERSONAL_INFORMATIONS'} actionName={'AUTH.EDIT'} actionPage={'EditAccount'} />

      <ListItemContainer>
        <ListItem label={'AUTH.FIRST_NAME'} value={userInfo?.first_name} />
        <ListItemDivider />
        <ListItem label={'AUTH.LAST_NAME'} value={userInfo?.last_name} />
        <ListItemDivider />
        <ListItem label={'AUTH.EMAIL'} value={userInfo?.email} />
      </ListItemContainer>
    </>
  );
};

export default AccountPersonalInformation;
