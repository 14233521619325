import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Platform, FlatList } from 'react-native';
import Colors from '../../../constants/Colors';
import { AntDesign, Feather } from '@expo/vector-icons';
import hasNotch from '../../../utils/isNotch';
import UserItem from '../../../components/settings/users/UserItem';
import { useSpaceStore } from '../../../store/spaces.state';
import api from '../../../config/api';
import { useEffect, useRef, useState } from 'react';
import { UserInviteBottomSheetModal } from '../../bottom-sheet-modal/users/UserInviteBottomSheetModal';
import Loading from '../../Loading';
import * as Haptics from 'expo-haptics';
import { Modalize } from 'react-native-modalize';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import PageTitle from '../../ui/PageTitle';
import Toast from 'react-native-toast-message';
import { useDashboardStore } from '../../../store/dashboard.state';

type FormValues = {
  shop_ids: [];
  role_id: string;
  email: string;
};

export const UsersRoles = ({}) => {
  const { t } = useTranslation();
  const { defaultSpace }: any = useSpaceStore();

  const bottomSheetUserRef = useRef<Modalize>(null);

  const navigation = useNavigation();
  const { setSelectedShopInvite, setSelectedRoleInvite }: any = useDashboardStore();

  const [values, setValues] = useState({
    errors: '',
    users: [],
    shops: [],
    roles: [],
    edit: false,
    loading: true,
    loadingInvitation: false,
    loadingShop: false,
    loadingRole: false,
    isRefreshing: false,
  });

  const getAllUsers = async () => {
    if (!defaultSpace?.id) return;
    try {
      const response = await api('/users/space/' + defaultSpace.id, 'GET', null);
      setValues((prevState) => ({ ...prevState, users: response?.data, loading: false }));
    } catch (error) {
      setValues((prevState) => ({
        ...prevState,
        errors: `${t('AUTH.SOMETHING_WENT_WRONG')}`,
        users: [],
        loading: false,
      }));
    }
  };

  const getShopBySpace = async (id: string) => {
    if (!id) return;
    setValues((prevState) => ({ ...prevState, loadingShop: true }));

    try {
      const response = await api('/shops/space/' + defaultSpace.id, 'GET', null);
      if (response) {
        setValues((prevState) => ({ ...prevState, shops: response?.data || [], loadingShop: false }));
      }
    } catch (error) {
      setValues((prevState) => ({ ...prevState, shops: [], loadingShop: false }));
    }
  };

  const getRoles = async () => {
    setValues((prevState) => ({ ...prevState, loadingRole: true }));
    try {
      const response = await api('/user/roles', 'GET', null);
      setValues((prevState) => ({ ...prevState, roles: response?.data || [], loadingRole: false }));
    } catch (error) {
      setValues((prevState) => ({ ...prevState, roles: [], loadingRole: false }));
    }
  };

  const onOpenInviteUser = async () => {
    if (!defaultSpace?.id) return;

    await getShopBySpace(defaultSpace?.id);

    await getRoles();

    bottomSheetUserRef?.current?.open();
  };

  const onRefresh = () => {
    getAllUsers();
  };

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitInvite = async (data: FormValues) => {
    const foundRole: any = values?.roles?.find((role: any) => role?.id === data?.role_id);

    const body = {
      space_name: defaultSpace?.name,
      email: data?.email,
      code: defaultSpace?.code,
      shop_ids: foundRole?.name === 'admin' ? [] : data?.shop_ids,
      role_id: data?.role_id,
    };

    setValues(() => ({ ...values, loadingInvitation: true }));

    try {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      const response = await api('/user/invite', 'POST', body);

      if (response.success) {
        setValues(() => ({ ...values, loadingInvitation: false }));
        bottomSheetUserRef?.current?.close();

        Toast.show({
          type: 'success',
          text1: `User Invitation`,
          text2: `Invitation sent to the user`,
        });
      }
    } catch (error: any) {
      setValues({ ...values, errors: `${t('AUTH.SOMETHING_WENT_WRONG')}` });

      Toast.show({
        type: 'error',
        text1: `Error`,
        text2: error.message,
      });

      setValues(() => ({ ...values, loadingInvitation: false }));
      bottomSheetUserRef?.current?.close();
    }

    // Reset bottom sheet modal values
    setSelectedShopInvite({ name: '', id: '' });
    setSelectedRoleInvite({ name: '', id: '' });
  };

  const renderItem = ({ item, index }: any) => {
    const isFirstItem = index === 0;
    const isLastItem = index === values?.users?.length - 1;

    return (
      <UserItem
        id={item?.id}
        name={item?.first_name}
        lastName={item?.last_name}
        email={item?.email}
        values={values}
        isFirstItem={isFirstItem}
        isLastItem={isLastItem}
        onRefresh={onRefresh}
      />
    );
  };

  const renderHeader = () => <PageTitle title={'AUTH.USER_ROLES'} description={'AUTH.EDIT_USER_ROLES'} />;

  const renderFooter = () =>
    values?.edit ? null : (
      <View style={styles.footer}>
        <TouchableOpacity disabled={values.loadingShop} style={styles.invitePeople} onPress={onOpenInviteUser}>
          <AntDesign name="pluscircle" size={20} color={Colors.light.blueRibbon} />
          <Text style={styles.invitePeopleText}>{t('AUTH.INVITE_PEOPLE')}</Text>
        </TouchableOpacity>
      </View>
    );

  return (
    <View style={styles.container}>
      {(values.loading || values.loadingShop) && <Loading />}
      <View style={styles.headerSettings}>
        <TouchableOpacity style={styles.goBack} onPress={() => navigation.goBack()}>
          {values.edit ? null : (
            <>
              <Feather name="chevron-left" size={24} color={Colors.light.blueRibbon} />
              <Text style={styles.goBackText}>{t('AUTH.MAIN_MENU')}</Text>
            </>
          )}
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setValues({ ...values, edit: !values.edit })}>
          <Text style={styles.editText}>{values.edit ? `${t('AUTH.DONE')}` : `${t('AUTH.EDIT')}`}</Text>
        </TouchableOpacity>
      </View>

      <UserInviteBottomSheetModal
        bottomSheetRef={bottomSheetUserRef}
        shops={values?.shops || []}
        roles={values?.roles || []}
        loadingInvitation={values?.loadingInvitation}
        onSubmit={onSubmitInvite}
      />

      <FlatList
        data={values?.users}
        keyExtractor={(item, index): any => index}
        renderItem={renderItem}
        refreshing={values.isRefreshing}
        onRefresh={onRefresh}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={renderHeader}
        ListFooterComponent={renderFooter}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.light.background,
    flex: 1,
    padding: 20,
  },
  main: {
    paddingBottom: 32,
    backgroundColor: Colors.light.background,
  },
  footer: {
    backgroundColor: Colors.light.background,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  description: {
    fontSize: 14,
    paddingTop: 16,
    lineHeight: 18,
    // width: Dimensions.get('window').width / 1.6,
    color: Colors.light.textLight,
  },
  invitePeople: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 24,
    marginBottom: 12,
    justifyContent: 'center',
  },
  invitePeopleText: {
    color: Colors.light.blueRibbon,
    fontSize: 14,
    fontWeight: '600',
    marginLeft: 8,
  },
  headerSettings: {
    paddingTop: Platform.OS === 'android' ? 12 : hasNotch() ? 12 : 50,
    paddingVertical: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.light.background,
  },
  editText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
  goBack: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -6,
  },
  goBackText: {
    fontSize: 16,
    color: Colors.light.blueRibbon,
    fontWeight: '500',
  },
});
