import React, { memo, useState, useEffect, useRef, useCallback } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ScrollView, RefreshControl } from 'react-native';
import Colors from '../../../constants/Colors';
import { StackScreenProps } from '@react-navigation/stack';
import { useAuthStore } from '../../../store/auth.state';
import MenuItem from '../../../components/ui/action/MenuItem';
import Header from '../../../components/ui/Header';
import { SwitchSpaces } from '../../../components/settings/spaces/SwitchSpaces';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../../../hooks/useAuthentication';
import { Modalize } from 'react-native-modalize';
import api from '../../../config/api';
import LanguageBottomSheetModal from '../../../components/bottom-sheet-modal/Language/LanguageBottomSheetModal';
import ListItemContainer from '../../../components/ui/ListItemContainer';
import ListItemDivider from '../../../components/ui/ListItemDivider';
import SectionTitle from '../../../components/ui/SectionTitle';
import CurrencyBottomSheetModal from '../../../components/settings/currencies/CurrencyBottomSheetModal';
import { Feather } from '@expo/vector-icons';
import { FeedbackBottomSheetModal } from '../../../components/bottom-sheet-modal/helpUsImprove/FeedbackBottomSheetModal';
import { SelectPageBottomSheetModal } from '../../../components/bottom-sheet-modal/helpUsImprove/SelectPageBottomSheetModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { feedbackValidations } from '../../../components/form/Validations';
import Toast from 'react-native-toast-message';
import { useIsFocused } from '@react-navigation/native';
import { FeedbackSettings } from '../../../assets/images';

type FormValues = {
  page_id: string;
  specific_field: string;
  description: string;
  screenshot?: any;
};

type Page = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
};

const SettingsScreen: React.FC<StackScreenProps<any>> = ({ navigation, route }) => {
  const [screenshot, setScreenshot] = useState('');
  const [page, setPage] = useState<Page>({ id: '', name: '', created_at: '', updated_at: '' });
  const [state, setState] = useState({
    isCompanyCompleted: false,
    loading: true,
  });

  const [data, setData] = useState({
    name: '',
    lastname: '',
  });

  const languageSheetRef = useRef<Modalize>(null);
  const currencySheetRef = useRef<Modalize>(null);

  const { t } = useTranslation();
  const { logout } = useAuthentication();
  const { userData }: any = useAuthStore();
  const userId = userData?.user?.id;
  const isFocused = useIsFocused();

  const getUserDetails = async (userId: string) => {
    if (!userId) return;
    try {
      const response = await api('/user/' + userId, 'GET', null);
      if (response) {
        setData({
          name: response?.data?.first_name,
          lastname: response?.data?.last_name,
        });
        const isCompanyCompleted = response?.data?.company.name && response?.data?.company.country;
        setState({ ...state, isCompanyCompleted, loading: false });
      }
    } catch (error) {
      setState({ ...state, loading: false });
    }
  };

  useEffect(() => {
    if (isFocused) {
      getUserDetails(userId);
    }
  }, [isFocused]);

  const onOpenLanguageModal = async () => {
    languageSheetRef.current?.open();
  };

  const onOpenCurrency = async () => {
    currencySheetRef.current?.open();
  };

  const { ...methods } = useForm({
    resolver: yupResolver(feedbackValidations),
    mode: 'all',
  });

  const bottomSheetRef = useRef<Modalize>(null);
  const selectPageBottomSheetRef = useRef<Modalize>(null);

  const handlePresentModalPress = useCallback(() => {
    methods.reset();
    bottomSheetRef.current?.open();
  }, []);

  const handleSelectPageModalPress = useCallback(() => {
    bottomSheetRef.current?.close();
    selectPageBottomSheetRef.current?.open();
  }, []);

  const handleBackSelectPageModalPress = useCallback(() => {
    selectPageBottomSheetRef.current?.close();
    bottomSheetRef.current?.open();
  }, []);

  const onSubmit = async (data: FormValues) => {
    try {
      data.screenshot = screenshot;
      data.page_id = page.id;
      const response = await api('/user/feedback', 'POST', data);

      if (response.success) {
        bottomSheetRef?.current?.close();
        setPage({ id: '', name: '', created_at: '', updated_at: '' });
        Toast.show({
          type: 'success',
          text1: `${t('AUTH.USER_FEEDBACK')}`,
          text2: `${t('AUTH.USER_FEEDBACK_SUCCESS')}`,
        });
      }
    } catch (error: any) {
      Toast.show({
        type: 'error',
        text1: `Error`,
        text2: error.message,
      });
    }
  };

  return (
    <View style={styles.container}>
      <Header screenName={t('AUTH.MY_PROFILE')} />
      <ScrollView
        refreshControl={<RefreshControl refreshing={state.loading} onRefresh={() => getUserDetails(userId)} />}
      >
        <View>
          <TouchableOpacity onPress={handlePresentModalPress}>
            <View style={styles.helpUsImprove}>
              <View style={styles.details}>
                <View style={styles.helpUsImproveLogo}>
                  <FeedbackSettings />
                </View>
                <View>
                  <Text style={styles.helpUsImproveTitle}>{t('AUTH.HELP_US_IMPROVE_LONG')}</Text>
                </View>
              </View>
              <View style={styles.arrowSelect}>
                <Feather name="chevron-right" size={24} color={Colors.light.white} />
              </View>
            </View>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => navigation.navigate('AccountSettings')}>
            <View style={styles.menuHeader}>
              <View style={styles.details}>
                <View style={styles.avatar}>
                  <Text style={styles.avatarText}>
                    {userData?.user?.first_name?.toUpperCase().slice(0, 1)}
                    {userData?.user?.last_name?.toUpperCase().slice(0, 1)}
                  </Text>
                </View>
                <View>
                  <Text style={styles.userName}>
                    {data.name} {data.lastname}
                  </Text>
                  <Text style={styles.userEmail}>{userData?.user?.email}</Text>
                </View>
              </View>
              <Feather name="chevron-right" size={34} color={Colors.light.silver} />
            </View>
          </TouchableOpacity>
        </View>

        <SwitchSpaces />

        <SectionTitle title={`AUTH.SPACE_SETTINGS`} />
        <ListItemContainer>
          {/* <MenuItem label="Invite people" icon="invitePeople" /> */}
          <MenuItem label={'AUTH.USER_ROLES'} icon="peopleRoles" onPress={() => navigation.navigate('UsersRole')} />
          <ListItemDivider />
          <MenuItem label={'AUTH.SPACE_DETAILS'} icon="help" onPress={() => navigation.navigate('SpaceDetails')} />
          <ListItemDivider />
          <MenuItem
            label={'AUTH.COMPANY_DETAILS'}
            icon="companyDetails"
            alert={state.loading ? null : state.isCompanyCompleted ? null : t('AUTH.INCOMPLETE')}
            onPress={() => navigation.navigate('Company')}
          />
        </ListItemContainer>
        
        <SectionTitle title={`AUTH.ACCOUNT_SETTINGS`} />
        <ListItemContainer borderTop={false} lastChild={true}>
          {/* <MenuItem label={t('AUTH.ACCOUNT')} icon="account" onPressItem={() => navigation.navigate('UserAccount')} /> */}
          <MenuItem label={'AUTH.LANGUAGE'} icon="language" onPress={onOpenLanguageModal} />
          <ListItemDivider />
          <MenuItem label={'AUTH.ABOUT'} icon="about" onPress={() => navigation.navigate('UserAbout')} />
          {/* <MenuItem label="Notifications" icon="notification" /> */}
          {/* <MenuItem label="Privacy & Security" icon="privacySecurity" /> */}
          {/* <MenuItem label="Payments" icon="payments" /> */}
          {/* <MenuItem label="Help" icon="help" /> */}
        </ListItemContainer>

        <MenuItem label={'AUTH.LOG_OUT'} onPress={logout} color={'red'} />
      </ScrollView>

      <LanguageBottomSheetModal bottomSheetRef={languageSheetRef} />
      <CurrencyBottomSheetModal bottomSheetRef={currencySheetRef} />

      <FeedbackBottomSheetModal
        bottomSheetRef={bottomSheetRef}
        onSubmit={onSubmit}
        methods={methods}
        screenshot={screenshot}
        setScreenshot={setScreenshot}
        page={page}
        // loadingCreate={onInitializeShop}
        onOpenSelectPageModal={handleSelectPageModalPress}
      />

      <SelectPageBottomSheetModal
        bottomSheetRef={selectPageBottomSheetRef}
        goBackBtn={handleBackSelectPageModalPress}
        page={page}
        setPage={setPage}
      />
    </View>
  );
};
export default memo(SettingsScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: Colors.light.background,
    borderRadius: 8,
    paddingBottom: 8
  },
  helpUsImprove: {
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
    borderRadius: 12,
    color: 'white',
    overflow: 'hidden',
    backgroundColor: Colors.light.feedbackPurple,
    // backgroundImage: 'linear-gradient(.25turn, #6E44FF, #8338EC)',
  },
  helpUsImproveLogo: {
    width: 60,
    height: 60,
    margin: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  helpUsImproveTitle: {
    width: 175,
    // height: 42,
    textAlign: 'left',
    color: 'white',
    fontSize: 18,
    fontWeight: '600',
    letterSpacing: 0,
  },
  arrowSelect: {
    position: 'absolute',
    right: 12,
  },
  menuHeader: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
    backgroundColor: Colors.light.white,
    borderRadius:8
  },
  details: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userName: {
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 4,
  },
  userEmail: {
    fontSize: 14,
    color: Colors.light.textLight,
    fontWeight: '400',
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: Colors.light.riptide,
    borderRadius: 40,
    marginRight: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarText: {
    fontSize: 30,
  },
  settingsSection: {
    marginBottom: 24,
  }
});
