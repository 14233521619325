import { Portal } from '@gorhom/portal';
import React, { memo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Modalize } from 'react-native-modalize';
import LanguageItem from '../../settings/language/LanguageItem';
import { HeaderBottomSheet } from '../../ui/HeaderBottomSheet';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../../constants/Languages';
import { useConfigStore } from '../../../store/config.state';

interface ILanguageModal {
  bottomSheetRef: any;
}

const LanguageBottomSheetModal = ({ bottomSheetRef }: ILanguageModal) => {
  const { currentLanguage, setCurrentLanguage }: any = useConfigStore();

  const { t, i18n } = useTranslation();

  const onChangeLanguage = async (lng: string) => {
    if (!lng) return;
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const backBtn = async () => {
    bottomSheetRef.current?.close();
  };

  const renderContent = () => {
    return (
      <View style={styles.container}>
        {Languages.map((language) => {
          return (
            <LanguageItem
              name={language.name}
              code={language.code}
              currentLanguage={currentLanguage}
              onChangeLanguage={onChangeLanguage}
            />
          );
        })}
      </View>
    );
  };

  return (
    <Portal>
      <Modalize
        ref={bottomSheetRef}
        adjustToContentHeight
        HeaderComponent={HeaderBottomSheet(`${t('AUTH.LANGUAGE')}`, backBtn, `${t('AUTH.DONE')}`)}
      >
        {renderContent()}
      </Modalize>
    </Portal>
  );
};

export default memo(LanguageBottomSheetModal);

const styles = StyleSheet.create({
  container: {
    paddingTop: 18,
    paddingBottom: 48,
    paddingHorizontal: 16,
  },
});
