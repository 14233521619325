import React, { memo } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity } from 'react-native';
import Images from '../../../theme/Images';
import { Feather, FontAwesome } from '@expo/vector-icons';
import Colors from '../../../constants/Colors';

interface ILanguageItem {
  name: string;
  code: string;
  currentLanguage: string;
  onChangeLanguage: any;
}

const LanguageItem = ({ name, code, currentLanguage, onChangeLanguage }: ILanguageItem) => {
  const getLanguageFlag = () => {
    switch (code) {
      case 'en':
        return Images.ukFlag;
      case 'de':
        return Images.deFlag;
      case 'us':
        return Images.usFlag;
      default:
        return Images.ukFlag;
    }
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.touchable} onPress={() => onChangeLanguage(code)}>
        <Image source={getLanguageFlag()} style={styles.flag} />
        <Text style={styles.name}>{name}</Text>
        <View style={styles.circle}>
          {currentLanguage.toLowerCase() === code ? (
            <FontAwesome name="dot-circle-o" size={20} color={Colors.light.blueRibbon} />
          ) : (
            <Feather name="circle" size={20} color={Colors.light.silver} />
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default memo(LanguageItem);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 14,
  },
  touchable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flag: {
    width: 24,
    height: 24,
    marginEnd: 16,
  },
  name: {
    fontWeight: '500',
    flex: 1,
  },
  circle: {
    // backgroundColor: Colors.light.blueRibbon,
  },
});
