import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions } from 'react-native';
import { useDashboardStore } from '../../../../../store/dashboard.state';
import DashboardStackedBarChart from '../../StackedBarChart';

const BarChartMerge = () => {
  const { t } = useTranslation();
  const { mergeSales }: any = useDashboardStore();

  const dataSets = mergeSales[0]?.totalDay
    ? mergeSales.map((sale: any) => {
        if (Array.isArray(sale?.totalDay)) {
          return sale.totalDay;
        }
      })
    : [];

  const data = {
    labels: mergeSales.length > 0 ? mergeSales[0].totalDayLabel : [t('CALENDAR.LOADING')],
    data: dataSets,
  };

  const chartConfig = {
    width:
      data.labels?.length >= 10
        ? Dimensions.get('window').width * (data.labels?.length / 10)
        : Dimensions.get('window').width / 1.15,
    marginLeft: 3,
    marginRight: -7,
    barLContentInset: 7.5,
    barRContentInset: 5,
    xContentInset: 13,
    rContentInset: 21,
    linePos: 7,
  };

  return <DashboardStackedBarChart data={data} chartConfig={chartConfig} />;
};

export default memo(BarChartMerge);
