import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TextInput as RNTextInput, TextInputProps as RNTextInputProps } from 'react-native';

import { useFormContext, UseControllerProps } from 'react-hook-form';
import { ControlledInput } from './ControlledInput';

interface TextInputProps extends RNTextInputProps, UseControllerProps {
  label: string;
  defaultValue?: string;
  required?: boolean;
}

export const TextInput = (props: TextInputProps) => {
  const { name } = props;

  const formContext = useFormContext();

  if (!formContext || !name) {
    const msg = !formContext ? 'TextInput must be wrapped by the FormProvider' : 'Name must be defined';
    return msg;
  }

  return <ControlledInput {...props} />;
};
